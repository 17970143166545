/**
 * Horizontal drag scrolling
 * @namespace DragSrcoll
 */

const dragScroll = {
    mounted(el, params) {
        /* Prevent if not desktop */
        console.log('elel', [el], params)

        // const value = params.value

        // Make sure expression provided is a function
        let mouseDown = false;
        let startX, scrollLeft;
        

        const mouseMoveHandler =  (e) => {

            if(!mouseDown) { return; }


            let   x      = e.pageX - el.offsetLeft;

            console.log(e, 'mouse move', x)


            // let clientX = e.clientX
            // let clientY = e.clientY

            if(e.type.includes('touch')) {
                // clientX = e.touches[0].clientX
                // clientY = e.touches[0].clientY
                x      = e.touches[0].pageX - el.offsetLeft;
            }

            const scroll = x - startX;
            el.scrollLeft = scrollLeft - scroll;

            
            // console.log([ele], 'post client x and y', clientX, clientY)
            // // How far the mouse has been moved
            // const dx = pos.x - clientX;
            // // const dy = pos.y - clientY;

            
            // // Scroll the element
            // // ele.scrollTop  =  dy - pos.top;
            // ele.scrollLeft =  dx - pos.left;

            // // ele.style.transform = `translate(${dx}px, ${dy}px)`;

            // // if(value.callback) {
            // //     value.callback({
            // //         x : pos.left - dx,
            // //         y :  pos.top - dy
            // //     })
            // // }

        };

        

        const mouseDownHandler =  (e) => {
            // Change the cursor and prevent user from selecting the text
            el.style.cursor         = 'grabbing';
            el.style.userSelect     = 'none';
            // el.style.scrollSnapType = 'x mandatory'
            mouseDown               = true;

            console.log([el], 'element position', e)

            if(e.type.includes('touch')) {
                

                // pos = {
                //     // The current scroll
                //     left: ele.scrollLeft,
                //     top : ele.scrollTop,
    
                //     // Get the current mouse position
                //     x: e.touches[0].clientX,
                //     y: e.touches[0].clientY,
                // };

                startX     = e.touches[0].pageX - el.offsetLeft;
                scrollLeft = el.scrollLeft;
                

            } else {

                // pos = {
                //     // The current scroll
                //     left: ele.scrollLeft,
                //     top : ele.scrollTop,
    
                //     // Get the current mouse position
                //     x: e.pageX - ele.offsetLeft,
                //     y: e.clientY,
                // };

                startX     = e.pageX - el.offsetLeft;
                scrollLeft = el.scrollLeft;

            }
            

            // console.log(pos, 'pos touch down', e)
            

            
            /* Desktop */
            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
            
            /* Touch device */
            // document.addEventListener('touchmove', mouseMoveHandler);
            // document.addEventListener('touchend', mouseUpHandler);
            
        };

        const mouseUpHandler = function () {
            // el.style.scrollSnapType = 'none'
            /* Desktop */
            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);

            /* Touch device */
            // document.removeEventListener('touchmove', mouseMoveHandler);
            // document.removeEventListener('touchend', mouseUpHandler);

            mouseDown = false;
        
            el.style.cursor = 'grab';
            el.style.removeProperty('user-select');
        };


        // Attach the handler
        el.addEventListener('mousedown', mouseDownHandler);
        // el.addEventListener('touchstart', mouseDownHandler);

    }
}

export default dragScroll;