/* Plugins */
import axios from 'axios'

export const settingsMixins = {
    computed : {
        /* View as admin */
        // viewAsAdmin : {
        //     get() {
        //         return this.$store.state.viewAsAdmin;
        //     },
        //     set(state) {
        //         this.$store.commit('updateViewAsAdmin', state)
        //     }
        // },
        /* Logged in user */
        user : {
            get() {
                return this.$store.state.user
            },
            set(user) {
                this.$store.commit('updateUser', user)
            }
        },
         /* Settings */
        settings : {
            get() {
                return this.$store.state.settings
            },
            set(settings) {
                this.$store.commit('updateSettings', settings)
            }
        },
    },
    methods : {
        /* Change theme top bar color */
        setTopBarColor({isLight = false, color = null} = {}) {
            console.log('isLight = false, color = null', isLight, color)
            // let isDarkMode = isLight ? false :  this.settings.viewMode == 'dark' ? true : false;
            // const defaultColor = isDarkMode ? '#262628' : '#fafafa';

            // color = color ? color : defaultColor;

            // console.log('color', color)

            // if(this.$route.name == 'login') {
            //     color = '#f64a3a';
            // }
            

            // let appleThemeColor = document.querySelector("meta[name=theme-color]");
            // appleThemeColor.setAttribute("content", color);
        },
        /* Get view as */
        getViewAs() {
            let   viewAsAdmin               = sessionStorage.getItem('qpIsAdmin');
            const isAdmin                   = viewAsAdmin && viewAsAdmin === 'true' ? true : false;
                console.log('isAdminisAdmin', isAdmin)

                  this.settings.viewAsAdmin = isAdmin;
            return isAdmin
        },
        /* Get settings */
        getSettings(){ 
            const settings = localStorage.getItem('settings')
            /* Prevent if none */
            if(!settings) return

            /* parse if present */
            this.settings = {...this.settings, ...JSON.parse(settings)}

            /* check theme mode */
            if(this.settings?.viewMode == 'dark') {
                document.documentElement.classList.toggle('dark', true)
            } else {
                document.documentElement.classList.toggle('dark', false)
            }
            
        },
        /* Update settings */
        async updateSettings({params, callback = () => {}} = {}) {
            const url = `api/user/${params.user_id}`
            const res = await axios.patch(url, params).catch(err => console.log(err))

            if(res && res.data) {
                callback(res.data)
            }
        }
    }
}