/* Plugins */
// import axios from "axios";

/* Mixins */
import { cacheMixins } from "./cacheMixins";

export const favoriteMixins = {
    mixins  : [cacheMixins],
    computed: {
        /* Logged in user */
        user () {
            return this.$store.state.user;
        }
    },
    methods : {
         /**
         * Get all favorited quote and stories
         */
         async getAllFavQuoteStories({callback = () => {}} = {}) {
            const url = `/api/story/favoritestoryquotes`;

            const res = await this.cacheReturn({
                url,
                method : "GET",
                callback : res => {
                    if(res) {
                        callback(res);
                    }
                }
            })

            if(res) {
                callback(res);
            }
        },
        /* Get favorited meta's */
        async getFavoritedMeta({callback = () => {}} = {}) {
            const url = `/api/quote/allfavorite`
            // const res = await axios.get(url).catch(err => console.log(err));

            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback :  res => {
                    if(res) {
                        callback(res);
                    }
                }
            })


            if(res) {
                callback(res);
            }
        },
        /* Toggle Fav */
        toggleFav({item, callback =() => {}} = {}) {
            
            /* Story favorite */
            if(item.story) {
                /* Add / remove story to favorite */

                this.removeFavoriteStory({
                    params : {
                        story_id : item.id,
                    },
                    callback,
                    errorCallback : () => {
                        this.addFavoriteStory({
                            params : {
                                story_id : item.id,
                            },
                            callback
                        })
                    }
                })
                // if(item.favorited_by && item.isFav) {
                    
                //     this.removeFavoriteStory({
                //         params : {
                //             story_id : item.id,
                //         },
                //         callback
                //     })
                // } else {

                //     this.addFavoriteStory({
                //         params : {
                //             story_id : item.id,
                //         },
                //         callback
                //     })
                // }
             
                return;
            }

            /* Quote favorite */
            /* add and remove to favorite */
            this.removeFavorite({
                params: {
                    quote_id: item.id
                },
                callback,
                errorCallback : () => {
                    this.addFavorite({
                        params: {
                            quote_id: item.id
                        },
                        callback
                    })
                }
            })

            // if (item.favorited_by && item.isFav) {
            //   this.removeFavorite({
            //       params: {
            //           quote_id: item.id
            //       },
            //       callback
            //   })
            // } else {
            // /* Add quote as favorite */
            //   this.addFavorite({
            //         params: {
            //             quote_id: item.id
            //         },
            //         callback
            //     })
            // }

            return;
        }
    }
}