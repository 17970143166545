<template>
    <div class="relative inline-block text-left">
        <div class="fixed w-screen h-screen left-0 top-0 z-10" @click="() => showMenu = false" v-if="showMenu">
        </div>

        <div class="z-20 relative">
            <button ref="dropdown" @click="showMenu = !showMenu" type="button" :class="[buttonClass]"  class="flex items-center capitalize text-sm hover:text-orange dark:text-white " id="menu-button" aria-expanded="true" aria-haspopup="true">
                <template v-if="!buttonLabel">
                    <i >{{placeholder ?? 'Select'}}</i>
                </template>
                <template v-else>
                    {{buttonLabel}}
                </template>
                <quote-icon class="!w-4 !h-4 ml-1" icon="chevron-down" />
            </button>
        </div>

        <!--
            Dropdown menu, show/hide based on menu state.

            Entering: "transition ease-out duration-100"
            From: "transform opacity-0 scale-95"
            To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
            From: "transform opacity-100 scale-100"
            To: "transform opacity-0 scale-95"
        -->
        <div ref="dropdownMenu" :class="[{'left-0' : showPosition == 'left'}, {'max-h-[63rem]' : showMenu}, {'invisible' : !showMenu}]" class="absolute  max-h-[0px] overflow-clip transition-all duration-200 right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-gray-2  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
            <div class="py-1" role="none">
                <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
               <button v-for="(option, i) in options" :key="i" @click="() => {$emit('selected', option.value); showMenu = false}" class="text-gray-700 block px-4 py-2 text-sm hover:bg-orange hover:text-white w-full text-left dark:text-white">{{option.label}}</button>
            </div>
        </div>

    </div>

</template>

<script>
export default {
    props : {
        showPosition : {
            type : String,
            default : () => 'right'
        },
        buttonLabel : {
            type : String,
            default : () => null
        },
        options : {
            type : Array,
            default : () => []
        },
        buttonClass : {
            type   : String,
            default: ''
        }
    },
    data() {
        return {
            showMenu   : false,
            popPosition: 'bottom-0'
        }
    },
    methods : {
        /**
         * Check if show top or bottom
         */
        getPositionPop() {
            
            /* Get popout */
            const pop = this.$refs.dropdown

            /* Prevent if not found */
            if(!pop) return

            /* get y position */
            const y = pop.offsetTop + pop.offsetHeight

            /* Get popout */
            const popMenu = this.$refs.dropdownMenu;

            /* Prevent if not found */
            if(!popMenu) return;

            console.log('popMenu.clientHeight', [popMenu], window.innerHeight)

            this.popPosition = popMenu.clientHeight + y > window.innerHeight ? 'top-0' : 'bottom-0'
        }
    },
    mounted() {
        /* Get dropdown pop position */
        this.getPositionPop();
    }
}
</script>