<template>
    <div class="w-full h-full ">
        <div id="dropZone" class="min-h-[30dvh] flex items-center justify-center">Drag & Drop Files Here</div>
        <input type="file" id="DZfileInput" style="display: none;" multiple>

    </div>
</template>

<script setup>
/* Utils */
import { onMounted, defineEmits } from "vue";

/* Emits */
const emit = defineEmits('files')

/* Methods */

/**
 * Drop Zone instance
 */
const dropZoneIntance = () => {
        const dropZone = document.getElementById('dropZone');
    const fileInput = document.getElementById('DZfileInput');

    // Prevent default behaviors for drag events
    dropZone.addEventListener('dragover', function(event) {
        event.preventDefault();
        event.stopPropagation();
        dropZone.classList.add('dragover');
    });

    dropZone.addEventListener('dragleave', function(event) {
        event.preventDefault();
        event.stopPropagation();
        dropZone.classList.remove('dragover');
    });

    dropZone.addEventListener('drop', function(event) {
        event.preventDefault();
        event.stopPropagation();
        dropZone.classList.remove('dragover');

        const files = event.dataTransfer.files;
        handleFiles(files);
    });

    // Optionally, open file dialog on drop zone click
    dropZone.addEventListener('click', function() {
        fileInput.click();
    });

    fileInput.addEventListener('change', function() {
        const files = fileInput.files;
        handleFiles(files);
    });

    function handleFiles(files) {
       emit('files', files)
    }
}

/* Onload */
onMounted(() => {
    /* Init dropzone */
    dropZoneIntance()
})
</script>


<style lang="scss">
@import "@/scss/_colors.scss";


 #dropZone {
    border       : 2px dashed #ccc;
    border-radius: 4px;
    padding      : 20px;
    text-align   : center;
    color        : #666;
}


#dropZone.dragover {
    background-color: #f0f0f0;
}
</style>