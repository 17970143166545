// plugins
// import _ from 'lodash';

/**
 * Notifies Vue about internal value change
 * @see https://github.com/vuejs/Discussion/issues/157#issuecomment-273301588
 *
 * @param {HTMLInputElement} el
 * @param {string}           type
 */
const trigger = (el, type) => {
    const e = document.createEvent('HTMLEvents');
    e.initEvent(type, true, true);
    el.dispatchEvent(e);
};


/**
 * Input listener 
 * process value and match patters
 */
// const inputListener = _.throttle(function ({el, binding, pattern = []} = {}) {
//     console.log(el.value, 'masking listen', el, binding, pattern)
    
//     /* Get all numbers from value */
//     let value   = el.value.replace(/^\D+/g, '').split('') ?? []; // Replace all leading non-digits with nothing
    
//     value = [...value.filter(v => !isNaN(v))]

//     /* Replce # with number */
//     let currentIndex = 0

//     console.log(el, 'pattern map', pattern);

//     pattern = [...pattern.map(p => {
//         if(p == '#') {
//             const newValue = value[currentIndex];
//             p = newValue
//             // console.log('replacing pattern masking', p)
//             currentIndex++;
//         }

//         return p;
//     })]

//     const updatedValue = pattern.filter(p => p).join('');

//     console.log('masking patter', el, value, pattern, updatedValue)

//     el.value = updatedValue;

//     trigger(el, 'input')

// }, 50);
const inputListener = function ({el, binding, pattern = []} = {}) {
    console.log(el.value, 'masking listen', el, binding, pattern)
    
    /* Get all numbers from value */
    let value   = el.value.replace(/^\D+/g, '').split('') ?? []; // Replace all leading non-digits with nothing
    
    value = [...value.filter(v => !isNaN(v))]

    /* Replce # with number */
    let currentIndex = 0

    console.log(el, 'pattern map', pattern);

    pattern = [...pattern.map(p => {
        if(p == '#') {
            const newValue = value[currentIndex];
            p = newValue
            // console.log('replacing pattern masking', p)
            currentIndex++;
        }

        return p;
    })]

    const updatedValue = pattern.filter(p => p).join('');

    console.log('masking patter', el, value, pattern, updatedValue)

    el.value = updatedValue;

    trigger(el, 'input')

};


const Masking = {
    updated(el, binding) {
        console.log(el, el.value, 'updated masking', binding.value);
        let pattern = [];

        if(!binding.value) return;

        pattern = binding.value.split('')

        console.log('updated masking pattern', pattern)

        inputListener({el, binding, pattern})
    },
    mounted(el, binding) {
        console.log(el, 'mounted', binding)
       
        /* Input listner */
        // el.addEventListener('input', (event) => inputListener({event, el, binding}));

        /* Keydown lister */
        el.addEventListener('keydown', (e) => {
            /* Prevent if not number */
            console.log("key down", e);
            if(isNaN(e.key) && e.key !== 'Delete' && e.key !== 'Backspace' && !e.key.includes('Arrow') && !e.metaKey) e.preventDefault();
        })
    }
}

export default Masking;