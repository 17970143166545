/* Declerations */
/* Helpers */
import Helper from '@/helpers/helpers'

export const sessionJob = {
    methods : {
        /* Check active session  */
            checkActiveSession() {
                console.log('checking session')
            /* Prevent checking if login page */
            if(this.$route.name == 'login') return
            const token = Helper.getCookie('quoteapptoken');

            if(!token && !this.$route.meta.roles.includes('public')) {
                this.$router.push({name : 'login'})
            }
        }
    }
}