const isVisible = {
    mounted(el, params) {

        
        const checkVisible = (elm) => {
            const rect       = elm.getBoundingClientRect();
            const viewWidth = Math.max(document.documentElement.clientWidth, window.innerWidth);
            return !(rect.bottom < 0 || rect.top - viewWidth >= 0);
        }
          

        const isVisible = checkVisible(el)

        if(isVisible) {
            if(params && params.callback) {
                params.callback(params.id)
            }
        }

    }
}

export default isVisible;