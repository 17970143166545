/* Plugins */
import axios from 'axios';
import { cacheMixins } from './cacheMixins';

/* Utils */
import StoryQuoteMixins from './quoteStoryMixins';

export const storyMixins = {
    mixins  : [cacheMixins],
    computed: {
         /* Is story */
         isStory() {
            return this.$route.query.story ? this.$route.query.story != 'false' ? true : false : false
        },
    },
    methods : {
        /**
         * Delete story tags
         * @param {Number} id
         * @param {Function} callback
         */
        async deleteStoryTags({id = null, callback = () => {}} = {}) {
            const url = `/api/story/tag/removetags/${id}`;
            await axios.delete(url).catch(err => err);
            callback()
        },
        /**
         * Add multiple story tags
         * @param {Object} params
         * @param {Function} callback
         */
        async addStoryTags({params = {}, callback = () => {}} = {}) {
             const url = `/api/story/tag/addtags`;
             const res = await axios.post(url, params).catch(err => err);

             if(res?.data) {
                callback(res.data);
             }
        },
       /**
         * Get combined api calls 
         * @param {Object} params
         */
        async getStoriesCombined({params={}, callback = () => {}} = {}) {
            const url = `/api/story/combined`
            const res = await axios.get(url, {params}).catch(err => console.log(err));

            if(res?.data) {
                callback(res.data);
            }
        },
         /**
         * Count authors stories
         * @param {Number} id author_id
         * @param {Object} options Options (created_by, user_level, publish_status) 
         * @param {Callback} callback
         */
         async authorTotalStories({id, options={}, callback = () => {}} = {}) {
            const url = `/api/story/totalstorybyauthor`;
            const res = await axios.get(url, {params : {...{author_id : id}, ...options}}).catch(err => console.log(err));

            if(res?.data) {
                callback(res.data);
            }
        },
        /* Total for approval stories */
        async getTotalApprovalStories({callback = () => {} } = {}) {
            const url = `/api/story/totalapproval`
            // const res = await axios.get(url).catch(() => null);

            // if(res && res.data) {
            //     callback(res.data);
            // }

            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback : res => {
                    if(res) {
                        callback(res);
                    }
                }
            })

            if(res) {
                callback(res);
            }
        },
        /* Remove story cache */
        async removeStoriesCache({includeUrls = [], includesOnly = false} = {}) {
            let urls = [...includeUrls,
                '/api/story/forapproval',
                '/api/story/published',
                '/api/story',
            ]

            if(includesOnly) urls = [...includeUrls];

            await this.deleteCache(urls);

        },
        /* Approval list */
        async getApprovalStoryList({params ={}, callback = () => {}} = {}) {
            const url = `/api/story/forapproval?${new URLSearchParams(params)}`;
            // const res = await axios.get(url, {params}).catch(err => console.log(err));

            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback : res => {
                    if(res) {
                        callback(res);
                    }
                }
            })

            if(res) {
                callback(res);
            }
        },
        /** Search story 
         * @param {String} params search string base check api docs for all parameters docs#story-management-POSTapi-story-search
         * @param {Function} callback return function that contain the array of stories 
         *
        */
        async searchStory({params, callback = () => {}} = {}) {
            const url = `/api/story/search`
            const res = await axios.post(url, params).catch(err => console.log(err));

            if(res && res.data) {
                callback(res.data);
            }
        },
        /* Get all published stories */
        async getAllPublishedStories({params, callback = () => {}} = {}) {
            const url = `/api/story/published?${new URLSearchParams(params)}`
            // const res = await axios.get(url, {params}).catch(err => console.log(err))
            const networkFirst = this.isNetworkFirst('storyList');
            
            const res = await this.cacheReturn({
                url, 
                networkFirst,
                method: "GET",
                callback : res => {
                    if(res) {
                        callback(res)
                    }
                }
            })

            if(res) {
                callback(res)
            }

            /* Remove from network first */
            if(networkFirst) {
                this.removeFromNetworkFirst('storyList');
            }
        },
        /* update story accessibility */
        async updateStoryAccess({params, callback = () => {}} = {}) {
            const url = `/api/story/admin/visibility/${params.id}`
            const res = await axios.patch(url, params).catch(err => console.log(err))

            if(res && res.data) {
                callback(res.data)
            }

            /* Add to network first */
           this.removeStoriesCache();
        },
         /* Get Story  attachement */
         async getStoryAttachement({params = {}, callback = () => {}} = {}) {
            const url = `/api/story/file`
            const res = await axios.get(url, {params}).catch(err => console.log(err))

            if(res && res.data) {
                callback(res.data)
            }
        },
        /* Add Story Attachement */
        async addStoryAttachement({params = {}, callback =() => {}} = {}) {
            const url = `/api/story/file`
            const res = await axios.post(url, params).catch(err => console.log(err))

            if(res && res.data) {
                callback(res.data)
            }
        },
        /* Create story  */
        async createStory ({params = {}, callback = () => {}} = {}) {
            const url = `/api/story`

            const res = await axios.post(url, params).catch(err => console.log(err))
            

            if(res && res.data) {
                callback(res.data)
            }
        },
        /* Get stories */
        async getStories({params={}, continueNetworkFirst =  false, callback = () => {}} = {}) {
            const url = `/api/story?${new URLSearchParams(params)}`

            // const res = await axios.get(url,  {params}).catch(err => console.log(err))
            const networkFirst = this.isNetworkFirst('storyList');

            const res = await this.cacheReturn({
                url, 
                networkFirst,
                method: "GET",
                callback : (res) => {
                    if(res) {
                        callback(res)
                    }
                }
            })

            if(res) {
                callback(res)
            }

             /* Remove from network first */
             if(networkFirst && !continueNetworkFirst) {
                this.removeFromNetworkFirst('storyList');
            }
        },
        /* Get story */
        async getStory({params = {},  callback = () => {}} = {}) {
            const url = `/api/story/${params.id}`

            // const res = await axios.get(url).catch(err => console.log(err))
            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback : res => {
                    if(res) {
                        callback(res)
                    }
                },
                errorCallback : () => {
                    /* Remove caches */
                    this.removeStoriesCache({includeUrls : [url]})
    
                    /* Show message popout */
                    this.$bvModal.show('deletedQuote');
                }
            })

            if(res) {
                callback(res)
            }
        },
        /* Update story */
        async updateStory({params = {}, callback = () => {}} = {}) {
             const url = `/api/story/${params.id}`

             const res = await axios.patch(url, params).catch(err => console.log(err))

             if(res && res.data) {
                StoryQuoteMixins.updateStoryCollection(res.data.data)
                callback(res.data)
             }

            /* Add to network first */
           this.removeStoriesCache({includeUrls : [url]});
        },
        /* Publish story */
        async publishStory({params = {}, callback = () => {}} = {}) {
            const url = `/api/story/publish/${params.id}`
            await axios.patch(url).catch(err => console.log(err));
            
            /* Add to network first */
           this.removeStoriesCache();

            callback(params);

        },
        /* Unpublish story */
        async unpublishStory({params = {}} = {}) {
            const url = `/api/story/unpublish/${params.id}`

            await axios.patch(url).catch(err => console.log(err));

            /* Add to network first */
           this.removeStoriesCache();
            
        },
        /* Delete story */
        async deleteStory({params = {}, callback = () => {}} = {}) {
            console.log('params', params)

            const url = `/api/story/${params.id}`
            const res = await axios.delete(url).catch(err => console.log(err));

            /* Add to network first */
           this.removeStoriesCache();

            if(res && res.data) {
                callback(res.data)
            }

            /* Add to network first */
            this.removeStoriesCache({includeUrls : [url]});
        },
        /* Add tag to story */
        async addStoryTag({params = {}, callback = () => {}} = {}) {
            const url = `/api/story/tag/${params.id}`
            const res = await axios.post(url, params).catch(err => console.log(err))

            if(res && res.data) {
                callback(res.data)
            }

            /* Add to network first */
           this.removeStoriesCache();
        },
        /* Remove story tag */
        async removeStoryTag({params = {}, callback = () => {}} = {}) {
            const url = `/api/story/tag/${params.id}`
            const res = await axios.delete(url, {params}).catch(err => console.log(err))

            if(res && res.data) {
                callback(res.data)
            }

            /* Add to network first */
           this.removeStoriesCache();
        },
        /* Add story to favorite */
        async addFavoriteStory({params= {}, callback = () => {}, errorCallback = () => {}} = {}) {
            const url = `/api/story/favorite/${params.story_id}`

            const res = await axios.post(url, params).catch(err => errorCallback(err))

            if(res && res.data) {
                callback(res.data)
            }
        },
        /* Remove story to favorite */
        async removeFavoriteStory({params= {}, callback = () => {}, errorCallback = () => {}} = {}) {
            const url = `/api/story/favorite/${params.story_id}`

            const res = await axios.delete(url, params).catch(err => errorCallback(err))

            if(res && res.data) {
                callback(res.data)
            }
        },
        /* Get favorite stories */
        async getFavoriteStories({params = {}, callback = () =>{}} = {}) {
            const url = `/api/story/favorite?${new URLSearchParams(params)}`

            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback :  res => {
                    if(res) {
                        callback(res);
                    }
                }
            })


            if(res) {
                callback(res);
            }

            // const res = await axios.get(url, {params}).catch(err => console.log(err))

            // if(res && res.data) {
            //     callback(res.data)
            // }
        }
    }
}