/* Declerations */

/* Filter registration */
import FormatDate from './partials/formatdate';
import relativedate from './partials/relativedate';
import getQuoteStoryPage from './partials/quoteStoryPage';
import numberToLetter from './partials/numberToLetter';
import CompilationName from './partials/compilationName';


export default {
    formatDate:FormatDate,
    relativedate,
    getQuoteStoryPage,
    numberToLetter,
    compilationName:CompilationName
};
