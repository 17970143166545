<template>
    <span :class="['quoteicon justify-center', icon]">
        <!-- <svg
            v-if="icon == 'book'"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            preserveAspectRatio="xMidYMax meet"
            viewBox="0 0 14.241 13.15"
        >
            <g id="Story" transform="translate(-3.382 -4.5)">
                <path
                    id="Path_45"
                    data-name="Path 45"
                    d="M6.735,4.507c1.853,0,3.425.956,3.425,2.808v.548h0v9.723a.067.067,0,0,1-.13.024v0a3.22,3.22,0,0,0-2.819-2.415,4.347,4.347,0,0,0-3.189,1.271.369.369,0,0,1-.226.1H3.642c-.127,0-.26-.092-.26-.209V6.428C3.379,5.329,4.882,4.507,6.735,4.507Z"
                    transform="translate(0 -0.004)"
                    fill="currentColor"
                />
                <path
                    id="Path_46"
                    data-name="Path 46"
                    d="M22.128,4.5c-1.853,0-3.425.956-3.425,2.808v.548h0V17.58a.067.067,0,0,0,.13.024v0a3.254,3.254,0,0,1,2.819-2.415,4.34,4.34,0,0,1,3.189,1.271.39.39,0,0,0,.226.1h.154c.127,0,.26-.092.26-.209V6.418C25.484,5.322,23.981,4.5,22.128,4.5Z"
                    transform="translate(-7.858)"
                    fill="currentColor"
                />
            </g>
        </svg> -->

        <svg
            v-if="icon == 'book'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 235.69 178.45"
        >
            <g
                id="Icon_material-round-menu-book"
                data-name="Icon material-round-menu-book"
            >
                <path
                    id="Path_50"
                    data-name="Path 50"
                    fill="currentColor"
                    d="M176.82.1c-20.85-.95-41.46,4.68-58.94,16.07C100.4,4.78,79.78-.84,58.94.1,43.24-.21,27.63,2.67,13.08,8.57,5.18,12.03.06,19.81,0,28.43v120.84c-.03,11.81,9.52,21.4,21.33,21.43,1.77,0,3.53-.21,5.24-.64,10.59-2.62,21.46-3.93,32.36-3.88,16.83-.39,33.53,2.98,48.89,9.86,6.29,3.21,13.75,3.21,20.04,0,15.35-6.93,32.06-10.3,48.89-9.86,10.91-.04,21.78,1.26,32.36,3.88,11.38,2.92,22.98-3.93,25.9-15.32.46-1.79.68-3.63.67-5.47V28.43c-.06-8.63-5.2-16.41-13.12-19.86C208.05,2.66,192.47-.23,176.79.1h.03ZM214.33,136.52c-.07,5.97-4.97,10.76-10.94,10.69-.64,0-1.29-.07-1.92-.19-8.13-1.47-16.38-2.18-24.65-2.14-20.65.56-40.86,6.08-58.94,16.07V37.61c18.08-10,38.29-15.51,58.94-16.07,9.72-.04,19.42.97,28.93,3,4.94,1.08,8.48,5.44,8.54,10.5v101.48h.04Z"
                />
                <path
                    id="Path_51"
                    data-name="Path 51"
                    fill="currentColor"
                    d="M139.09,69.87c-4.43-.02-8-3.64-7.98-8.07.02-3.45,2.24-6.5,5.51-7.58,18.62-5.5,38.16-7.14,57.43-4.82,4.41.5,7.59,4.48,7.09,8.9s-4.48,7.59-8.9,7.09h0c-17-2.05-34.25-.62-50.68,4.18-.82.15-1.64.26-2.47.32v-.02ZM139.09,98.38c-4.43-.02-8-3.64-7.98-8.07.02-3.45,2.24-6.5,5.51-7.58,18.61-5.5,38.16-7.14,57.43-4.82,4.41.5,7.57,4.49,7.07,8.89s-4.49,7.57-8.89,7.07c-17-2.05-34.25-.62-50.68,4.18-.81.2-1.64.31-2.47.32h.02ZM139.09,126.88c-4.43-.02-8-3.64-7.98-8.07.02-3.45,2.24-6.5,5.51-7.58,18.61-5.5,38.16-7.14,57.43-4.82,4.41.5,7.57,4.49,7.07,8.89s-4.49,7.57-8.89,7.07c-17-2.05-34.25-.62-50.68,4.18-.81.2-1.64.31-2.47.32h.02Z"
                />
            </g>
        </svg>

        <svg
            v-if="icon == 'quote'"
            preserveAspectRatio="xMidYMax meet"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path
                fill="currentColor"
                d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z"
            />
        </svg>

        <svg
            v-if="icon == 'book-up'"
            fill="currentColor"
            preserveAspectRatio="xMidYMax meet"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 27.997 21.776"
        >
            <path
                id="Source"
                d="M26.356,2.252c-2.664.151-7.958.7-11.226,2.7a.747.747,0,0,0-.353.64V23.281a.769.769,0,0,0,1.132.656c3.363-1.692,8.226-2.154,10.63-2.281A1.516,1.516,0,0,0,28,20.166V3.745a1.524,1.524,0,0,0-1.641-1.492Zm-13.488,2.7c-3.268-2-8.562-2.55-11.226-2.7A1.524,1.524,0,0,0,0,3.745V20.166a1.515,1.515,0,0,0,1.459,1.49c2.406.126,7.271.589,10.634,2.282a.767.767,0,0,0,1.128-.654V5.586A.732.732,0,0,0,12.868,4.954Z"
                transform="translate(0 -2.25)"
            />
        </svg>

        <svg
            v-if="icon == 'trash-outline'"
            fill="currentColor"
            preserveAspectRatio="xMidYMax meet"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16.787 18.43"
        >
            <g id="Trash" transform="translate(1 1)">
                <path
                    id="Path_25"
                    data-name="Path 25"
                    d="M4.5,9H19.287"
                    transform="translate(-4.5 -5.714)"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                />
                <path
                    id="Path_26"
                    data-name="Path 26"
                    d="M19,6.286v11.5a1.643,1.643,0,0,1-1.643,1.643H9.143A1.643,1.643,0,0,1,7.5,17.787V6.286m2.465,0V4.643A1.643,1.643,0,0,1,11.608,3h3.286a1.643,1.643,0,0,1,1.643,1.643V6.286"
                    transform="translate(-5.857 -3)"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                />
            </g>
        </svg>

        <svg
            v-if="icon == 'gear'"
            fill="currentColor"
            preserveAspectRatio="xMidYMax meet"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20.5 20.5"
        >
            <g id="Settings" transform="translate(-0.75 -0.75)">
                <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M20.019,16.759A3.259,3.259,0,1,1,16.759,13.5,3.259,3.259,0,0,1,20.019,16.759Z"
                    transform="translate(-5.759 -5.759)"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
                <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M17.391,13.591a1.425,1.425,0,0,0,.285,1.572l.052.052a1.728,1.728,0,1,1-2.444,2.444l-.052-.052A1.437,1.437,0,0,0,12.8,18.626v.147a1.727,1.727,0,0,1-3.455,0v-.078a1.425,1.425,0,0,0-.933-1.3,1.425,1.425,0,0,0-1.572.285l-.052.052a1.728,1.728,0,1,1-2.444-2.444l.052-.052A1.437,1.437,0,0,0,3.374,12.8H3.227a1.727,1.727,0,1,1,0-3.455h.078a1.425,1.425,0,0,0,1.3-.933,1.425,1.425,0,0,0-.285-1.572l-.052-.052A1.728,1.728,0,1,1,6.716,4.341l.052.052a1.425,1.425,0,0,0,1.572.285h.069a1.425,1.425,0,0,0,.864-1.3V3.227a1.727,1.727,0,0,1,3.455,0v.078a1.437,1.437,0,0,0,2.435,1.019l.052-.052a1.728,1.728,0,1,1,2.444,2.444l-.052.052a1.425,1.425,0,0,0-.285,1.572v.069a1.425,1.425,0,0,0,1.3.864h.147a1.727,1.727,0,0,1,0,3.455h-.078a1.425,1.425,0,0,0-1.3.864Z"
                    transform="translate(0 0)"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
            </g>
        </svg>

        <svg
            v-if="icon == 'plus'"
            fill="currentColor"
            preserveAspectRatio="xMidYMax meet"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18.696 18.696"
        >
            <path
                id="Icon_ionic-md-add"
                data-name="Icon ionic-md-add"
                d="M25.446,17.344h-8.1v8.1H14.852v-8.1H6.75V14.852h8.1V6.75h2.493v8.1h8.1Z"
                transform="translate(-6.75 -6.75)"
                fill="currentColor"
            />
        </svg>

        <svg
            v-if="icon == 'search'"
            fill="currentColor"
            preserveAspectRatio="xMidYMax meet"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18.696 18.696"
        >
            <path
                id="Icon_ionic-md-search"
                data-name="Icon ionic-md-search"
                d="M17.816,16.252h-.852l-.32-.267a7.071,7.071,0,0,0,1.652-4.54,6.9,6.9,0,1,0-6.871,6.944,7.139,7.139,0,0,0,4.527-1.656l.319.267v.855L21.6,23.2l1.6-1.6Zm-6.392,0a4.807,4.807,0,1,1,4.794-4.807A4.781,4.781,0,0,1,11.424,16.252Z"
                transform="translate(-4.5 -4.5)"
                fill="currentColor"
            />
        </svg>

        <svg
            v-if="icon == 'filter'"
            fill="currentColor"
            preserveAspectRatio="xMidYMax meet"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15 15"
        >
            <path
                id="Icon_awesome-filter"
                data-name="Icon awesome-filter"
                d="M14.3,0H.7a.7.7,0,0,0-.5,1.2L5.625,6.619v6.037a.7.7,0,0,0,.3.576l2.344,1.64A.7.7,0,0,0,9.375,14.3V6.619L14.793,1.2A.7.7,0,0,0,14.3,0Z"
                fill="currentColor"
            />
        </svg>

        <svg
            v-if="icon == 'note'"
            fill="currentColor"
            preserveAspectRatio="xMidYMax meet"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16.897 18.925"
        >
            <g id="Notes" transform="translate(0.449 0.449)">
                <g
                    id="Group_31"
                    data-name="Group 31"
                    transform="translate(0 0)"
                >
                    <path
                        id="Path_34"
                        data-name="Path 34"
                        d="M11.694,19.225H4.726A2.926,2.926,0,0,1,1.8,16.3V4.725A2.926,2.926,0,0,1,4.726,1.8h9.546A2.925,2.925,0,0,1,17.2,4.725v9A5.5,5.5,0,0,1,11.694,19.225Z"
                        transform="translate(-1.498 -1.498)"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="1.5"
                    />
                    <path
                        id="Path_35"
                        data-name="Path 35"
                        d="M49.132,71.444h1.1a1.991,1.991,0,0,0,1.99-1.99V68.9a2.388,2.388,0,0,1,2.388-2.388,1.99,1.99,0,0,0,1.99-1.99"
                        transform="translate(-40.902 -53.717)"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="1.5"
                    />
                    <line
                        id="Line_1"
                        data-name="Line 1"
                        x2="7.244"
                        transform="translate(4.184 5.506)"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-width="1.5"
                    />
                    <line
                        id="Line_2"
                        data-name="Line 2"
                        x2="7.244"
                        transform="translate(4.184 8.443)"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-width="1.5"
                    />
                    <line
                        id="Line_3"
                        data-name="Line 3"
                        x2="5.328"
                        transform="translate(4.184 11.379)"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-width="1.5"
                    />
                </g>
            </g>
        </svg>

        <svg
            v-if="icon == 'image'"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
            />
        </svg>

        <svg
            v-if="icon == 'person-fill'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
            <path
                fill-rule="evenodd"
                d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                clip-rule="evenodd"
            />
        </svg>

        <svg
            v-if="icon == 'person-circle'"
            fill="currentColor"
            preserveAspectRatio="xMidYMax meet"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
        >
            <g id="Group_3" data-name="Group 3" transform="translate(-35 -335)">
                <g
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    transform="translate(35 335)"
                    fill="#fff"
                    stroke="currentColor"
                    stroke-width="1"
                >
                    <circle cx="10" cy="10" r="10" stroke="none" />
                    <circle cx="10" cy="10" r="9.5" fill="none" />
                </g>
                <path
                    id="Icon_ionic-ios-person"
                    data-name="Icon ionic-ios-person"
                    d="M14.568,14.319c-.189-.834-1.267-1.241-1.639-1.372a12.224,12.224,0,0,0-1.367-.262,1.389,1.389,0,0,1-.632-.291,5.525,5.525,0,0,1-.042-1.293,3.891,3.891,0,0,0,.3-.569,7.342,7.342,0,0,0,.22-.994s.215,0,.291-.378a3.717,3.717,0,0,0,.194-.879c-.016-.3-.181-.294-.181-.294a4.6,4.6,0,0,0,.178-1.346A2.161,2.161,0,0,0,9.54,4.5,2.152,2.152,0,0,0,7.187,6.641a4.8,4.8,0,0,0,.176,1.346s-.165-.008-.181.294a3.717,3.717,0,0,0,.194.879c.073.378.291.378.291.378a7.342,7.342,0,0,0,.22.994,3.891,3.891,0,0,0,.3.569,5.525,5.525,0,0,1-.042,1.293,1.389,1.389,0,0,1-.632.291,12.224,12.224,0,0,0-1.367.262c-.372.131-1.451.538-1.639,1.372a.21.21,0,0,0,.207.254h9.651A.209.209,0,0,0,14.568,14.319Z"
                    transform="translate(35.463 335.463)"
                    fill="currentColor"
                />
            </g>
        </svg>

        <svg
            v-if="icon == 'person'"
            fill="currentColor"
            preserveAspectRatio="xMidYMax meet"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path
                id="Author"
                d="M16.694,16.694a6.1,6.1,0,1,0-6.1-6.1A6.115,6.115,0,0,0,16.694,16.694Zm0,3.049C12.655,19.743,4.5,21.8,4.5,25.84v3.049H28.888V25.84C28.888,21.8,20.733,19.743,16.694,19.743Z"
                transform="translate(-4.5 -4.5)"
                fill="currentColor"
            />
        </svg>

        <svg
            v-if="icon == 'pencil'"
            fill="currentColor"
            preserveAspectRatio="xMidYMax meet"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 17.875 17.875"
        >
            <path
                id="Edit"
                d="M15.009,3.921a2.265,2.265,0,0,1,3.2,3.2L7.4,17.932,3,19.133l1.2-4.4Z"
                transform="translate(-2 -2.257)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
            />
        </svg>

        <svg
            v-if="icon == 'tag'"
            fill="currentColor"
            preserveAspectRatio="xMidYMax meet"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24.388 24.388"
        >
            <path
                id="Tag"
                d="M26.394,1.928H17.066a3.244,3.244,0,0,0-1.99.824L3.512,14.317a1.169,1.169,0,0,0,0,1.649l10.01,10.01a1.169,1.169,0,0,0,1.649,0L26.735,14.411a3.244,3.244,0,0,0,.824-1.99V3.094a1.169,1.169,0,0,0-1.166-1.166Zm-5.829,9.327A2.332,2.332,0,1,1,22.9,8.923,2.332,2.332,0,0,1,20.564,11.255Z"
                transform="translate(-3.171 -1.928)"
                fill="currentColor"
            />
        </svg>

        <svg
            v-if="icon == 'stack'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
            <path
                d="M11.644 1.59a.75.75 0 0 1 .712 0l9.75 5.25a.75.75 0 0 1 0 1.32l-9.75 5.25a.75.75 0 0 1-.712 0l-9.75-5.25a.75.75 0 0 1 0-1.32l9.75-5.25Z"
            />
            <path
                d="m3.265 10.602 7.668 4.129a2.25 2.25 0 0 0 2.134 0l7.668-4.13 1.37.739a.75.75 0 0 1 0 1.32l-9.75 5.25a.75.75 0 0 1-.71 0l-9.75-5.25a.75.75 0 0 1 0-1.32l1.37-.738Z"
            />
            <path
                d="m10.933 19.231-7.668-4.13-1.37.739a.75.75 0 0 0 0 1.32l9.75 5.25c.221.12.489.12.71 0l9.75-5.25a.75.75 0 0 0 0-1.32l-1.37-.738-7.668 4.13a2.25 2.25 0 0 1-2.134-.001Z"
            />
        </svg>

        <svg
            v-if="icon == 'star'"
            fill="currentColor"
            preserveAspectRatio="xMidYMax meet"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 31.5 29.25"
        >
            <path
                id="Favorite"
                d="M32.555,13.5H22.212L19.069,4.12a1.139,1.139,0,0,0-2.138,0L13.788,13.5H3.375A1.128,1.128,0,0,0,2.25,14.625a.827.827,0,0,0,.021.19,1.081,1.081,0,0,0,.471.795l8.5,5.991L7.98,31.085a1.128,1.128,0,0,0,.387,1.266A1.088,1.088,0,0,0,9,32.625a1.378,1.378,0,0,0,.7-.253L18,26.459l8.3,5.913a1.318,1.318,0,0,0,.7.253,1.01,1.01,0,0,0,.626-.274,1.114,1.114,0,0,0,.387-1.266L24.75,21.6l8.43-6.047.2-.176a1.18,1.18,0,0,0,.366-.752A1.191,1.191,0,0,0,32.555,13.5Z"
                transform="translate(-2.25 -3.375)"
                fill="currentColor"
            />
        </svg>

        <svg
            v-if="icon == 'users'"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            preserveAspectRatio="xMidYMax meet"
        >
            <g data-v-41be6633="">
                <path
                    d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                ></path>
                <path
                    fill-rule="evenodd"
                    d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                ></path>
                <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"></path>
            </g>
        </svg>

        <svg
            v-if="icon == 'x-circle'"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            preserveAspectRatio="xMidYMax meet"
        >
            <g data-v-41be6633="">
                <path
                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                ></path>
                <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                ></path>
            </g>
        </svg>

        <svg
            v-if="icon == 'check'"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            preserveAspectRatio="xMidYMax meet"
        >
            <g data-v-41be6633="">
                <path
                    d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
                ></path>
            </g>
        </svg>

        <svg
            v-if="icon == 'library'"
            preserveAspectRatio="xMidYMax meet"
            viewBox="0 0 39 49"
            fill="cru"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5 0C2.23858 0 0 2.23858 0 5V44C0 46.7614 2.23858 49 5 49H34C36.7614 49 39 46.7614 39 44V5C39 2.23858 36.7614 0 34 0H5ZM17 6H5V25L11 22L17 25V6Z"
                fill="currentColor"
            />
        </svg>

        <svg
            v-if="icon == 'eye-slash'"
            preserveAspectRatio="xMidYMax meet"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
        >
            <g data-v-41be6633="">
                <path
                    d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"
                ></path>
                <path
                    d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"
                ></path>
            </g>
        </svg>

        <svg
            v-if="icon == 'eye'"
            preserveAspectRatio="xMidYMax meet"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
        >
            <g data-v-41be6633="">
                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"></path>
                <path
                    d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                ></path>
            </g>
        </svg>

        <svg
            v-if="icon == 'heart'"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
            />
        </svg>

        <svg
            v-if="icon == 'heart-fill'"
            preserveAspectRatio="xMidYMax meet"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
        >
            <g>
                <path
                    fill-rule="evenodd"
                    d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
                ></path>
            </g>
        </svg>

        <svg
            v-if="icon == 'play-file'"
            preserveAspectRatio="xMidYMax meet"
            viewBox="0 0 16 16"
            role="img"
            aria-label="file earmark play"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
        >
            <g data-v-41be6633="">
                <path
                    d="M6 6.883v4.234a.5.5 0 0 0 .757.429l3.528-2.117a.5.5 0 0 0 0-.858L6.757 6.454a.5.5 0 0 0-.757.43z"
                ></path>
                <path
                    d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"
                ></path>
            </g>
        </svg>

        <svg
            v-if="icon == 'subtract'"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
        >
            <g data-v-41be6633="">
                <path
                    d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2z"
                ></path>
            </g>
        </svg>

        <svg
            v-if="icon == 'check-circle'"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
        </svg>

        <svg
            v-if="icon == 'check-list'"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
        >
            <g data-v-41be6633="">
                <path
                    fill-rule="evenodd"
                    d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"
                ></path>
            </g>
        </svg>

        <svg
            v-if="icon == 'check-list-ui'"
            preserveAspectRatio="xMidYMax meet"
            data-v-41be6633=""
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
        >
            <g data-v-41be6633="">
                <path
                    d="M7 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zM2 1a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm0 8a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2H2zm.854-3.646a.5.5 0 0 1-.708 0l-1-1a.5.5 0 1 1 .708-.708l.646.647 1.646-1.647a.5.5 0 1 1 .708.708l-2 2zm0 8a.5.5 0 0 1-.708 0l-1-1a.5.5 0 0 1 .708-.708l.646.647 1.646-1.647a.5.5 0 0 1 .708.708l-2 2zM7 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm0-5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"
                ></path>
            </g>
        </svg>

        <svg
            v-if="icon == 'link'"
            preserveAspectRatio="xMidYMax meet"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
        >
            <g data-v-41be6633="">
                <path
                    d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"
                ></path>
                <path
                    d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"
                ></path>
            </g>
        </svg>

        <svg
            v-if="icon == 'envelope'"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
            />
        </svg>

        <svg
            v-if="icon == 'envelope-fill'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
            <path
                d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z"
            />
            <path
                d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z"
            />
        </svg>

        <svg
            v-if="icon == 'lock-fill'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
            <path
                fill-rule="evenodd"
                d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z"
                clip-rule="evenodd"
            />
        </svg>

        <svg
            v-if="icon == 'gear-fill'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
            <path
                fill-rule="evenodd"
                d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 0 0-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 0 0-2.282.819l-.922 1.597a1.875 1.875 0 0 0 .432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 0 0 0 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 0 0-.432 2.385l.922 1.597a1.875 1.875 0 0 0 2.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 0 0 2.28-.819l.923-1.597a1.875 1.875 0 0 0-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 0 0 0-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 0 0-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 0 0-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 0 0-1.85-1.567h-1.843ZM12 15.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z"
                clip-rule="evenodd"
            />
        </svg>

        <svg
            v-if="icon == 'chevron-left'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
            <path
                fill-rule="evenodd"
                d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
                clip-rule="evenodd"
            />
        </svg>

        <svg
            v-if="icon == 'chevron-down'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
            <path
                fill-rule="evenodd"
                d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
                clip-rule="evenodd"
            />
        </svg>

        <svg
            v-if="icon == 'x-mark'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
            <path
                fill-rule="evenodd"
                d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                clip-rule="evenodd"
            />
        </svg>

        <svg
            v-if="icon == 'plus-circle-fill'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
            <path
                fill-rule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z"
                clip-rule="evenodd"
            />
        </svg>

        <svg
            v-if="icon == 'poem'"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 112.96 183.03"
        >
            <!-- <defs>
                <style>
                    .cls-1 {
                        clip-path: url(#clippath);
                    }

                    .cls-2 {
                        fill: none;
                    }

                    .cls-2,
                    .cls-3 {
                        stroke-width: 0px;
                    }

                    .cls-3 {
                        fill: #b6b6b6;
                    }
                </style>
                <clipPath id="clippath">
                    <rect class="cls-2" width="112.96" height="183.03" />
                </clipPath>
            </defs> -->
            <g id="Group_50" data-name="Group 50">
                <g class="cls-1">
                    <g id="Group_49" data-name="Group 49">
                        <path
                            id="Path_54"
                            data-name="Path 54"
                            fill="currentColor"
                            d="M23.81,94.16c-.77-3.71-1.27-7.48-1.49-11.27-.23-5.29.29-10.58,1.55-15.72,2.24-7.28,5.94-14.02,10.86-19.83,1.79-2.45,3.73-4.8,5.75-7.07.29-.33.54-.78,1.07-.6s.49.67.54,1.1c.1,2.33.45,4.65,1.03,6.91.27-2.49.66-4.97,1.19-7.43,1.02-4.43,3.56-8.37,7.17-11.13,5.61-4.76,11.62-9.03,17.97-12.74.41-.23.78-.69,1.33-.34s.39.86.3,1.35c-.36,2.72-.57,5.46-.62,8.2.99-2.23,1.68-4.12,2.6-5.91,3.16-5.98,8.34-10.63,14.62-13.13,7.58-3.26,15.57-5.45,23.75-6.52,1.37-.19,1.67.29,1.49,1.55-1.85,15.4-6.34,30.36-13.27,44.24-4.23,7.88-9.86,14.93-16.62,20.78-4.66,4.12-9.8,7.68-14.45,11.82-.57.51-1.14,1.05-1.69,1.55.23.36.41.21.56.12,4.33-2.52,9.09-4.24,14.03-5.07.85-.16,1.93-.61,2.49.17s-.52,1.44-.98,2.05c-3.35,4.36-6.27,9.04-8.72,13.97-1.34,2.76-3.51,5.02-6.21,6.47-4.11,2.3-7.88,5.18-11.19,8.54-.27.28-.53.58-.78.88-.09.19-.15.39-.19.6l4.54-2.33c2.29-1.12,4.7-1.99,7.18-2.61,1.27-.33,1.68.08,1.26,1.35-1.49,4.47-2.55,9.06-3.76,13.6-.89,3.71-3.58,6.73-7.17,8.03-8.62,3.27-17.29,6.4-25.94,9.58-.51.19-1.13.65-1.64.09s-.08-1.12.07-1.67c4.83-17.04,11.06-33.65,18.63-49.67,4.98-10.71,10.62-21.11,16.88-31.13,1.85-2.98,3.84-5.87,5.67-8.89-.74.6-1.36,1.32-1.85,2.14-6.28,7.93-11.96,16.31-17.02,25.07-4.73,8.09-9.04,16.39-13.04,24.83-5.38,11.4-10.17,23.04-14.63,34.84-1.88,4.98-3.73,9.96-5.56,14.95-1.94,5.32-5.98-2.8-4.51-6.98.58-1.67.6-3.48.07-5.16-1.74-6.21-3.55-12.35-5.09-18.58-2.29-9.58-1.29-19.64,2.84-28.58.98-2.25,2.02-4.47,3.04-6.69.22-.47.36-1.16,1.05-1.04s.61.78.7,1.33c.94,5.07,3.04,9.85,6.14,13.97"
                        />
                        <path
                            id="Path_55"
                            data-name="Path 55"
                            fill="currentColor"
                            d="M9.62,156.59c-1.07-.07-2,.75-2.06,1.83-.02.32.04.64.18.94.28.57.28,1.24,0,1.82-2.25,4.6-4.49,9.2-6.72,13.81-.18.36-.36.97-.78.84s-.16-.72-.09-1.09c1.25-5.81,2.03-11.72,2.33-17.66.08-1.75-.08-3.49-.14-5.25-.25-2.74,1.11-5.37,3.48-6.76,3.74-2.48,7.02-5.57,9.71-9.16.49-.61.88-.85,1.67-.42,2.27,1.23,4.58,2.33,6.86,3.55,1.16.61,1.16.72.78,1.95-1.15,3.77-1.76,7.69-1.79,11.64,0,.78.12,1.51.08,2.28-.11,2.47-1.5,4.7-3.67,5.89-4.27,2.77-8.24,5.98-11.85,9.57-1.88,1.81-3.73,3.64-5.49,5.57-.21.23-.43.73-.78.45s0-.66.15-.95c1.66-3.08,3.33-6.15,5.01-9.22.78-1.4,1.55-2.77,2.27-4.19.31-.7.9-1.23,1.63-1.46,1.07-.37,1.64-1.53,1.28-2.6,0,0,0-.02-.01-.03-.28-.89-1.15-1.44-2.07-1.33"
                        />
                    </g>
                </g>
            </g>
        </svg>

        <svg
            v-if="icon == 'song'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 103.85 182.9"
        >
            <path
                id="Icon_ion-ios-musical-note"
                data-name="Icon ion-ios-musical-note"
                fill="currentColor"
                d="M99.54.1c-2.06.4-51.9,10.6-53.74,10.95-1.89.15-3.39,1.63-3.56,3.52v112.2c.03,1.77-.33,3.53-1.06,5.15-1.52,2.73-4.07,4.74-7.08,5.59-1.45.48-3.43.92-5.76,1.45C17.75,141.33.02,145.37.02,161.74c-.44,9.69,6,18.34,15.4,20.71,2.01.33,4.04.48,6.07.44,7.84-.21,15.54-2.2,22.51-5.8,7.01-4.63,11.05-12.63,10.6-21.02V55.04c0-1.67,1.18-3.11,2.82-3.43l40.81-8.36c3.27-.68,5.62-3.56,5.63-6.91V3.45c-.02-1.92-1.59-3.46-3.51-3.45-.27,0-.54.04-.8.1Z"
            />
        </svg>

        <svg
            v-if="icon == 'scripture'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 147.87 169"
        >
            <path
                id="Icon_fa-solid-book"
                data-name="Icon fa-solid-book"
                fill="currentColor"
                d="M31.69,0C14.19,0,0,14.19,0,31.69v105.62c0,17.5,14.19,31.68,31.69,31.69h105.62c5.83,0,10.56-4.73,10.56-10.56s-4.73-10.56-10.56-10.56v-21.12c5.83,0,10.56-4.72,10.56-10.55V10.56C147.88,4.73,143.15,0,137.32,0c0,0-.01,0-.02,0H31.69ZM31.69,126.75h84.5v21.12H31.69c-5.83,0-10.56-4.73-10.56-10.56s4.73-10.56,10.56-10.56ZM42.25,47.53c0-2.91,2.37-5.27,5.28-5.28h63.37c2.92,0,5.28,2.36,5.28,5.28s-2.36,5.28-5.28,5.28h-63.37c-2.91,0-5.27-2.37-5.28-5.28ZM47.53,63.37h63.37c2.92,0,5.28,2.36,5.28,5.28s-2.36,5.28-5.28,5.28h-63.37c-2.92,0-5.28-2.36-5.28-5.28s2.36-5.28,5.28-5.28Z"
            />
        </svg>

        <svg
            v-if="icon == 'expand'"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
            />
        </svg>
    </span>
</template>

<script>
export default {
    name: "quote-icon",
    props: {
        icon: {
            type: String,
            default: () => "",
        },
    },
};
</script>

<style lang="scss">
.quoteicon {
    display: flex;
    height: 100%;
    // width: 24px;

    svg {
        color: inherit;
        font-size: inherit;
    }
}
</style>
