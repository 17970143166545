// plugins
import _ from 'lodash';

/* Add default properties */
const computedWidth = (el) => {
    const adjustmentMultifly = el.value.length > 6 ? 9 : 11.5;
    console.log(el.value.length * adjustmentMultifly, 'el.value.length',el.value.length);
    return (el.value.length * adjustmentMultifly) + 'px'
};

const placeholderWidth = (el) => {
    const adjustmentMultifly = el.placeholder.length > 7 ? 8 : 9;
    console.log(el.placeholder, 'placeholder', adjustmentMultifly)
return el.value.length == 0 ?   (el.placeholder.length *  adjustmentMultifly) + 'px' : '30px';
};

const autowidth = {
    updated(el, binding) {
       // Make sure expression provided is a function
       console.log('autowidth',[el], binding, _)
     

       el.style.width    = computedWidth(el);
       el.style.flex     = `0 0 ${computedWidth(el)}`;
       el.style.minWidth = binding.value?.minWidth ?? placeholderWidth(el);

       /* Listner for live adjustment */
       const inputListener = _.throttle(function()  {

           const updateWidth = _.throttle(function() {

               el.style.width = computedWidth(el);
               el.style.flex  = `0 0 ${computedWidth(el)}`;

               if(el.value == '') {
                   el.style.minWidth = binding.value?.minWidth ?? placeholderWidth(el);
               }
   
               
           }, 100 );

           if(binding.value?.callback) {
               binding.value.callback(el.value);
           }

           updateWidth();


       }, 100)

       inputListener();
    },
    mounted(el, binding) {
        console.log('mounted',el,  binding)
    //    el.addEventListener('input', inputListener);
        

    }
}

export default autowidth;