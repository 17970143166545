/**
 * Tag list apis
 * @namespace TagApis
 */

/* Plugins */
import axios from 'axios';

/* Utils */
import {getCollections, addUpdateCollection, deleteCollection} from '@/manie-utils/idb/localbase'

/* Models */
import TagModel from '../models/tagModel';

/* Resource */
import TagResource from '../resources/tagResource';

export default class TagApis {

    /**
     * Delete tag
     * @param {Number} tag_id
     */
    static async deleteTag({tag_id, callback = () => {}} = {}) {
        await deleteCollection({name : 'tags', filter : {id : parseInt(tag_id)}});
        callback();
    }

    /**
     * Sync deleted tags
     * 
     */
    static async syncDeletedTags() {
        const url = '/api/tags/listarchived';
        const res = await axios.get(url).catch(err => err);

        if(res?.data) {
            const deletedTags = res.data.data;
            deletedTags.forEach( async t => await deleteCollection({name  : "tags", filter: {id : t.id}}))
        }
    }

    /**
     * Populat tags
     * @param {Number} xtimes
     * @param {Function} callback
     */
    static async getPopulatTags({xtimes = 2, callback = () => {}} = {})  {
        const callbackres = async () => {
            const tagsList = await getCollections({name : 'tags'});
            
            let tags = [];
            for (let index = 0; index < tagsList.length; index++) {
                const tag = tagsList[index];

                tags = [...tags, await TagResource.getTag({tag_id : tag.id})];
                
            }

            tags.sort((a, b) => b.rating - a.rating);


            const pubTags = [...tags.filter(t => t.admin_lock)];
            callback(pubTags.slice(0, 20));

        }

        callbackres();

        const url = `/api/tags/popular/onsnippets`;

        const res = await axios.get(url, {params : {xtimes}}).catch(err => err);

        if(res?.data) {
            res.data.forEach(t => addUpdateCollection({name : 'tags', data : TagModel.schema(t)}));

            callbackres();
        }
    }

    /**
     * Update tag
     * @param {Object} params
     * @param {Function} callback
     */
    static async updateTag({params = {}, callback = () => {}} = {}) {
        addUpdateCollection({name : 'tags', data : TagModel.schema(params)});

        callback();
    }

    /**
     * Get tag
     * @param {Number} tag_id
     * @param {Function} callback
     */
    static async getTag({tag_id, callback = () => {}} = {}) {
        const callbackRes = async () => {
            const tag = await TagResource.getTag({tag_id : parseInt(tag_id)});

            callback(tag);

        }

        callbackRes();


        const url = `/api/tags/${tag_id}`;
        const res = await axios.get(url).catch(err => err);

        if(res?.data) {
            addUpdateCollection({name : 'tags', data : TagModel.schema(res?.data?.data)});

            callbackRes()
        }
    }

    

    /**
     * Get tag list
     * @param {Function} callback
     * @param {String} sorting
     * @param {Number} user_id
     */
    static async getTagList({sorting = 'alpha', user_id, callback = () => {}} = {}) {

        const callbackRes = async () => {
            // let currentCount = 0;
            // let batchedTags  = [];
            // let fetching     = false;

            // const FetchingResource = async () => {
            //     if(fetching) return;
                
            //           fetching = true;
            //     const tagList  = [...batchedTags[currentCount]];
            //     currentCount++

            //     let tags = [];

            //     for (let index = 0; index < tagList.length; index++) {
            //         const tag = tagList[index];
            //         tags = [...tags, await TagResource.getTag({tag_id :tag.id, user_id})]
            //     }

            //     console.log(currentCount, sorting, 'tags local', tags)

               
            //     callback(tags);
            //     fetching = false;
                
            //     /* Return next batch */
            //     if(currentCount < batchedTags.length) FetchingResource();
            // }

            // await getCollectionsBy({collectionName : 'tags', indexName : 'tag_name', startRange : '1', callback : async data => {
            //     batchedTags = [...batchedTags, data];
            // }})
         
            // FetchingResource();

            const tagList = await getCollections({name : 'tags'});

            let tags = [];

            for (let index = 0; index < tagList.length; index++) {
                const tag = tagList[index];
                tags = [...tags, await TagResource.getTag({tag_id :tag.id, user_id})]
            }

            // console.log('tags local', tags)

            callback(tags);
        }

        callbackRes();


      

        const url = `/api/tags/ownandpublished`;

        const getOwned = async (page = 1) => {
            const res = await axios.get(url, {params : {page, sorting}}).catch(err => err);

            if(res?.data) {
                const list = [...res.data?.owntags?.data ?? [], ...res.data?.published?.data ?? []];
                list.forEach(t => addUpdateCollection({name : 'tags', data : TagModel.schema(t)}));

                if(!res.data?.owntags?.links?.next && !res.data?.published?.links?.next) {
                    callbackRes();
                } else {
                    const currentPage = page + 1
                    getOwned(currentPage);
                    // callbackRes();
                }
            }
        }


        getOwned();



    }
}