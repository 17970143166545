/**
 * Date formatting using momentjs
 * @namespace FormatDate
 */

import moment from 'moment-timezone' 

export default function FormatDate(date, options = {utc : false}) {
    if (!date) return ''

    const theformat = options.format ? options.format : 'MM/DD/YYYY'
    // console.log('date, format', moment.tz.guess(), moment.tz.zone(moment.tz.guess()).utcOffset())

    const timeZone = moment.tz.guess()

    const thedate = options.utc ? moment.utc(date) : moment(date).tz(timeZone)
    return thedate.isValid() ? thedate.format(theformat) : date
}