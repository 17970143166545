/**
 * Quote models
 * Object property that allow to be save in indexdb
 * @namespace QuotesModel
 */

export default class QuotesModel {
    /**
     * Destructuring object and only return decleared properties
     */
    static schema (object) {
            const  {
            id             = null,
            quote          = null,
            quote_date     = null,
            quote_notes    = null,
            quote_subtitle = null,
            author_id      = null,
            source_id      = null,
            url            = null,
            user_level     = 1,
            visibility     = null,
            created_by     = null
            }              = object;



        return {
            id,
            quote,
            quote_date,
            quote_notes,
            quote_subtitle,
            author_id,
            source_id,
            url,
            user_level,
            visibility,
            created_by
        }

    }

}