/**
 * Poem Mixins
 * @namespace PoemMixins
 */

/* Mixins */
import { cacheMixins } from './cacheMixins';


/* Plugins */
import axios from 'axios';

export const PoemMixins = {
    mixins : [cacheMixins],
    methods : {
        
        /**
         * Upload poem file
         * @param {Object} params
         * @param {Function} callback
         */
        async uploadPoemFile({params = {}, callback = () => {}} = {}) {
            const url = `/api/poem/file`;

            const res = await axios.post(url, params).catch(err => err);

            if(res?.data) {
                callback(res.data);
            }

        },
        /**
         * Update Poem
         * @param {Object} params
         * @param {Function} callback
         */
        async updatePoem({params = {}, callback = () => {}} = {}) {
            const url = `/api/poem/${params.id}`;

            const res = await axios.patch(url, params).catch(err => err);

            if(res?.data) {
                callback(res.data);
            }
        },
        /**
         * Get Poem
         * @param {Number} id
         * @param {Function} callback
         */
        async getPoem({id = null, callback = () => {}} = {}) {
            const url = `/api/poem/${id}`;

            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback : (res) => {
                    if(res) {
                        callback(res);
                    }
                }
            })


            if(res) {
                callback(res);
            }
        },
        /**
         * Combined list apis for listing poem
         * @param {Function} callback
         */
        async getCombinedPoems({params = {sorting : 'desc'}, callback = () => {}}) {
            const url  = `/api/poem/combined?${new URLSearchParams(params)}`;

            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback : (res) => {
                    if(res) {
                        callback(res);
                    }
                }
            })


            if(res) {
                callback(res);
            }
        },
        /**
         * Create poem
         * @param {Object} params
         * @param {Function} callback
         */
        async createPoem({params = {}, callback = () => {}} = {}) {
            const url = '/api/poem';
            const res = await axios.post(url, params).catch(err => err);

            if(res?.data) {
                callback(res.data);
            }
        },
        /**
         * Add mutitple tags
         * @param {Object} params 
         * @param {Function} callback
         */
        async addPoemTags({params = {}, callback = () => {}} = {}) {
            const url = `/api/poem/tag/multitags`;

            const res = await axios.post(url, params).catch(err => err);

            if(res?.data) {
                callback(res.data);
            }
            
        },
        /**
         * Remove all poem tags
         * @param {Object} params
         * @param {Callback} callback
         */
        async removePoemTags({params = {}, callback = () => {}} = {}) {
            const url = `/api/poem/tag/removetags/${params.poem_id}`;
            const res = await axios.delete(url).catch(err => err);
            if(res?.data) {
                callback(res.data);
            }
        },
        /**
         * Delete Poem
         * @param {Number} id
         * @param {Function} callback
         */
        async deletePoem({id, callback= () => {}} = {}) {
            const url = `/api/poem/${id}`
            const res = await axios.delete(url).catch(err => err);

            if(res?.data) {
                callback(res.data);
            }
        }
    }
}