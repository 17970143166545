/**
 * Click away directive
 * @namespace clickAway
 */

const clickAway = {
    mounted(el, binding) {
        setTimeout(() => {
            // Make sure expression provided is a function
            // console.log(el, binding)
            let isShow = false;

            /* Get trigger button */
            const triggerButton = document.getElementById(`${binding.value.id}`)

            /* Check if el exist from that click element */
            const clickListener = (e) => {
            

                console.log(e.target, 'triggerButton', triggerButton)
                console.log(el.contains(e.target), 'triggerButton1', el)

                /* Prevent if no trigger */
                if(!triggerButton) return

                /* Check if click el contain in the target */
                if(triggerButton.contains(e.target)) return

                /* Prevent if el contain the target */
                if(el.contains(e.target)) return

                /* Prevent if no callback */
                if(!binding.value.callback) return

                /* Unbind and rebind */
                // window.removeEventListener('click', clickListener, true);
            
                /* Return callback */
                binding.value.callback(false)
                isShow = false;

            }


            /* Add click listner */
            window.addEventListener('click', clickListener, true)
            console.log(binding.value.id, 'triiger', triggerButton)
            if(triggerButton) {
                triggerButton.addEventListener('click', (e) => {
                    e.preventDefault();

                    isShow = !isShow;
                    binding.value.triggerCallback(isShow)
                })
            }

        }, 100);

    }
}

export default clickAway;