/**
 * Navigation guard checking for sesssion
 */
/* Helpers */
import Helper from '@/helpers/helpers'
import TopBar from '@/helpers/TopBar';

 export default async function navguard ({to, next, from, store}) {
    console.log('navguard', to)

     /* Change top bar color before entering page */
    if(to.name == 'login') {
        TopBar.setTopBarColor({color : '#f64a3a'});
    } else {
        /* Check if dark mode or light */
        let settings = localStorage.getItem('settings')
        settings = settings ? JSON.parse(settings) : null;

        if(settings) {
            if(settings.viewMode == 'dark') {
                if(to.meta.page == 'home') {
                    TopBar.setTopBarColor();
                } else {
                    TopBar.setTopBarColor({color : '#303339'});
                }
            } else {
                if(to.name != 'login') {
                    TopBar.setTopBarColor({color : '#fafafa'});
                }
            }
        } else {
            if(to.name != 'login') {
                TopBar.setTopBarColor({color : '#fafafa'});
            }
        }
    }

    /* Cancel previous request */
   try {
       if(from.name != 'login' && from.name) {
            const abortList = [...store.getters.abortRequest.filter(a => a.name != to.name)];

            console.log(to.name, 'store.abortRequest', abortList);

            abortList.forEach(item => {
                try {
                    item.request.abort('Cancel page changed');
                } catch (error) {
                    console.log('error', error)
                }
            })

            store.commit('updateAbortRequest', []);
       }
   } catch (error) {
        console.log('error', error)
   }


   

  
    // Helper.axoisglobalconfig();
    /* Save storage state route from */
    store.commit('updateRouteFrom', from)

    console.log("Helper.getCookie('quoteapptoken')", Helper.getCookie('quoteapptoken'))

    // check if has session if none redirect to login
    if(to.name != 'login') {
        if(!Helper.getCookie('quoteapptoken')) {
            next({name : 'login'})
        }
    } else if(to.name == 'login') {
        if(!Helper.getCookie('quoteapptoken')) {
            next()
        } else {
            next({name : 'home'})
        }
    }

      /* check if public router */
      if(to.meta?.roles?.includes('public')) {
        next();

        return;
    }



     /* View as */
     let viewAsAdmin = sessionStorage.getItem('qpIsAdmin')
     /* Parse */
     viewAsAdmin = viewAsAdmin ? viewAsAdmin === 'true' ? true : false : false
     console.log(store, viewAsAdmin, 'store', to)
 
     /* check if accessing route alloweed */


     /* Admin allowed */
     if(viewAsAdmin) {
        // if(!to.meta.roles.includes('admin')) next({name : 'home'})
        next()
    } else {
        /* None admin allowed */
        if(to.meta.roles.includes('admin')) {
            next({name : 'home'})
        } else {
            next();
        }
        // if(!to.meta.roles.includes('user')) next({name : 'home'})
    }
 }