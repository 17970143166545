/**
 * Source Resource
 * @namespace SourceResource
 */

/* Utils */
import {getCollection, getCollections} from '@/manie-utils/idb/localbase';

export default class SourceResource {
    /**
     * Return Source Object
     * @param {Number} source_id
     * @param {Number} user_id
     * 
     */
    static async getSource({source_id, user_id} = {}) {
        if(!source_id) return null;

        const source     = await getCollection({name : 'sources', filter : {id : parseInt(source_id)}});
        if(!source) return null;

        /* Container */
        let container = null;
        if(source.container_id)  container = await getCollection({name : 'containers', filter : {id : parseInt(source.container_id)}}) ?? null;
        

        /* check if has published snippets */
        const snippets = await getCollections({name : 'snippets', filter : {key : 'source_id', value : parseInt(source_id)}})
        const admin_lock = snippets.find(s => s.admin_lock) ? true : false;

        /* Check if used by the user */
        let is_used = false
        if(user_id) is_used = snippets.find(s => s.created_by == user_id || s.created_by?.id == user_id) ? true : false;

        /* Merged properties */
        const additionalProperties  =  {
            containers : [container].filter(c => c),
            admin_lock,
            is_used,
            testing : 'asdfasdfasdfasdf'
        }

        // console.log(source.container_id, source.source_name, 'additionalProperties', {...source, ...additionalProperties})


        return {...source, ...additionalProperties}
    }
}