// plugins
import _ from 'lodash';

const contentEditor = {
    mounted(el, binding) {
        /* Set attributes */
        el.setAttribute('contenteditable', binding.value.readonly ? !binding.value.readonly : true);
        el.setAttribute('placeholder', binding.value.placeholder ? binding.value.placeholder : '');

        /* Add inline tools */
        let   clicking              = false;
        const toolWrapper           = document.createElement('div');
              toolWrapper.className = 'inlineToolWrapper'

        /* Create buttons */
        const boldBtn                = document.createElement('button');
              boldBtn.className      = 'tool tool-bold';
              boldBtn.innerText      = 'B';
        const italicBtn              = document.createElement('button');
              italicBtn.className    = 'tool tool-italic';
              italicBtn.innerText      = 'I';
        const underLineBtn           = document.createElement('button');
              underLineBtn.className = 'tool tool-underline';
              underLineBtn.innerText      = 'U';

        /* Button action class */
        const addButtonActiveClass = () => {
            if(document?.queryCommandState('bold') ) {
                boldBtn.classList.add('active') 
            } else {
                boldBtn.classList.remove('active') 
            }
            if(document?.queryCommandState('italic') ) {
                italicBtn.classList.add('active')
            } else {
                italicBtn.classList.remove('active') 
            }

            if(document?.queryCommandState('underline')) {
                underLineBtn.classList.add('active')
            } else {
                underLineBtn.classList.remove('active') 
            }
        }

              /* Add button functions  */
              boldBtn.addEventListener('click', () => {
                document.execCommand('bold');
                addButtonActiveClass();
                clicking = true;
              });

              italicBtn.addEventListener('click', () => {
                document.execCommand('italic');
                addButtonActiveClass();
                clicking = true;
              });
              
              underLineBtn.addEventListener('click', () => {
                document.execCommand('underline');
                addButtonActiveClass();
                clicking = true;
              });

              /* Add to tool wrapper */
              toolWrapper.appendChild(boldBtn);
              toolWrapper.appendChild(italicBtn);
              toolWrapper.appendChild(underLineBtn);

        /* Add selection listner */

        el.addEventListener('mouseup', _.debounce(async function (e) {
            const selection = await document?.getSelection() ?? await window?.getSelection() ?? null;
            
            /* Prevent if tool is disabled */
            if(!binding.value?.inlineTool) return;

            if(clicking) {
                clicking = false; // reset after checking 
                return
            } // prevent if clicking from tool
            if(!selection) return; // prevent if null
            
            
            if(selection?.type == 'Range') {

                let top  = (e.pageY + 15)+'px';
                let left = (e.pageX)+'px';

                /* Check what style already in the selection */
               addButtonActiveClass()

                /* if forward selection  */
                if(selection?.baseOffset < selection?.extentOffset) {
                    const range   = selection?.getRangeAt(0);
                    const offsetX = range?.startOffset + range?.endOffset + (74/2);  // 74 is the width of the tool wrapper
                          left    = (e.pageX - offsetX)+'px';

                          console.log('is this called')
                }

                /* Add position */
                toolWrapper.style.position = 'absolute';
                toolWrapper.style.top      = top;
                toolWrapper.style.left     = left;

                document.body.appendChild(toolWrapper)
            } else {
                const tool = document.querySelector('.inlineToolWrapper');
                if(tool) tool.remove();
            }

        }, 300))

        /* hide tool if clicked outside of the content editable */
        window.addEventListener('click', (e) => {
            console.log('click e', e);
            const tool = document.querySelector('.inlineToolWrapper');

            /* Check if button is clicked then get parent of the event and check if it contain the inline tool */
            if(e.target.tagName == 'BUTTON') {
                const parentElement = e.target?.parentElement;

                if(parentElement.contains(tool)) return; // prevent tool from hiding
            }

            if(!e.target?.contains(el)) {
                if(tool) tool.remove();
            }
        })
        


        /* Set initial text content */
        console.log('initial content', binding.value.initialContent)
        if(binding.value.initialContent) {
            el.innerHTML = binding.value.initialContent;
        }

        /* Get new text content */
       
        const getContent = _.throttle(function(e) {
            let content =  e.target.innerHTML;
            const isEmpty = e.target.innerText.trim() == '' ? true : false;

            /* Empty return empty string don't returm html */
            content = isEmpty ? "" : content;

            console.log(isEmpty, 'what is e in content editable', content);

            
            binding.value.callback(content);
        }, 100)
        


        /* Add event listner  */
        el.addEventListener('input', (e) => {
            getContent(e);
        })

        /* On Enter  */
        el.addEventListener('keydown', e => {
            console.log(e.code == 'Enter' && binding.value.onEnter && typeof binding.value.onEnter == 'function', 'keydown ', e)
            if(e.code == 'Enter' && binding.value.onEnter && typeof binding.value.onEnter == 'function') {
                e.preventDefault();
                el.innerText = '';
                binding.value.onEnter()
            }

            if(e.code == 'Backspace' && binding.value.onBackspace && typeof binding.value.onBackspace == 'function') {
                binding.value.onBackspace();
            }
        })
        
        /* Reset Formating */
        el.addEventListener("paste", function(e) {
            e.preventDefault();
            var text = e.clipboardData.getData("text/plain");
            document.execCommand("insertHTML", false, text);
        });
          

    }
}

export default contentEditor;