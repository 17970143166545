/**
 * Source models
 * Object property that allow to be save in indexdb
 * @namespace ContainerModel
 */

export default class ContainerModel {
    /**
     * Destructuring object and only return decleared properties
     */
    static schema (object) {
        const  {
            id           = null,
            sc_date      = null,
            sc_date_type = null,
            sc_name      = null,
            sc_thumbnail = null,
            created_by   = null,
            sc_date_circa = null
            }            = object;



        return {
            id,
            sc_date,
            sc_date_type,
            sc_name,
            sc_thumbnail,
            sc_date_circa : sc_date_circa ? 1 : 0,
            created_by : created_by?.id || created_by
        }

    }

}