/**
 * Get compilation name from array
 * @namespace CompilationName
 */

import _ from 'lodash';

export default function CompilationName(compilation, {withComma} = {}) {
    if (!compilation) return ''

    // const compi = compilation;
    const compi = _.head(compilation);
    // console.log('compilation name', compi);
    
    return compi ? `${compi.sc_name}${withComma == false ? '' : ','}` : '';
}