// plugins
import SwipeListener from 'swipe-listener';

const swipelistener = {
    mounted(el, binding) {
        // Make sure expression provided is a function
        if(binding.value) {
            SwipeListener(el);

            el.addEventListener('swipe', function (e) {
                if(typeof binding.value.callback == 'function') {
                    /* Prevent if window size is more than 1200 */
                    // if(!e.detail.touch) return
                    if(window.innerWidth > 1200) return;
                    
                    /* run callback */
                    const options = binding.value.options ? binding.value.options : {}
                    binding.value.callback(e.detail, binding.value.id, options)
                } else {
                    console.warn('Callback is not a function')
                }
            });

        } else {
            console.warn('Value is required')
        }

    }
}

export default swipelistener;