<template>
    <div 
        :key="reRenderCard"
        ref="itemCard"
        :class="[{'hidden' : hideThis}]"
        class="quotecontent ">

        <div class="quoteheader">
            <div class="grid grid-cols-2">
                <div class="">
                    <quote-icon class="!w-5" icon="quote" v-if="item.s_category == 'quote'" />
                    <quote-icon class="!w-5" icon="book" v-if="item.s_category == 'story'" />
                    <quote-icon class="!w-5 !h-6 !flex text-violet" icon="poem" v-if="item.s_category == 'poem'" />
                    <quote-icon class="!w-5 !h-6 !flex text-green" icon="song" v-if="item.s_category == 'song'" />
                    <quote-icon class="!w-5 !h-6 !flex text-orange-1" icon="scripture" v-if="item.s_category == 'scripture'" />
                </div>

                <div class="h-5">
                    <div class="quoteaction">
                        <a 
                            v-if="!noFavToggle && !user?.is_admin"
                            href="" 
                            @click.prevent="toggleActiveClass" 
                            :class="['fav ', {'active' : lIsFav}]">

                            <span v-if="lIsFav" class="isFav">
                                <quote-icon class="!w-5" icon="heart-fill"></quote-icon>
                            </span>

                            <span v-else class="notFav">
                                <quote-icon class="!w-6 -mt-0.5" icon="heart"></quote-icon>
                            </span>
                        </a>

                        <a 
                            v-if="!noCopy"
                            @click.prevent="copyAllToClipboard($refs.itemCard)"
                            href="" 
                            class="dup">
                            <quote-icon 
                                class="!w-5"
                                v-if="copiedContent" 
                                icon="check-circle">
                            </quote-icon>

                            <quote-icon class="!w-5" v-else icon="subtract"></quote-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        

        <div 
            :class="['quoteBodyWrap']">
            
            <div  
                @click="viewQuote"
                v-paragraph-format
                ref="snippetBody"
                :id="cardId"
                :class="['qoutebody', {'isEllipsis' : isEllipsis}]">
                {{item.s_content ?? ''}} 
            </div>
            <a 
                class="showLessBtn"
                @click.prevent="() => toggleSeemore(cardId)"
                href="">show less</a>
            
            <a 
                class="seeMoreBtn"
                href="" 
                @click.prevent="() => toggleSeemore(cardId)">see more</a>
        </div>


        <div class="quotefooter">
            <div class="">

                <div >
                    <div class="quoteauthor w-full">
                        <div class="authorpic">
                            <template v-if="item.author && item.author.author_img">
                                <img :src="awsURL+item.author.author_img" alt="">
                            </template>
                            <template v-else>
                                <quote-icon icon="person-circle" />
                            </template>
                        </div>

                        <div class="authorinfo flex-1">
                            <div 
                                @click="viewAuthor"
                                class="authorname"> {{item.author ? item.author.first_name : ''}} {{item.author ? item.author.last_name : ''}}</div>
                            

                                <div class="grid grid-cols-12">
                                    <div class="col-span-11">
                                        <div class="quotemeta">
                                            <template v-if="item.source">

                                                <a 
                                                    @click.prevent="viewSource"
                                                    href="">
                                                    {{compilationName(item.source.containers)}} {{item.source.source_name}} {{ subtitle}}{{getQuoteStoryPage(item) }} 
                                                    {{formatDate(sourceDate, {format : dateFormat})}} 
                                                    {{item.s_date_circa ? 'cir.' : ''}}
                                                </a>
                                                
                                                <div 
                                                    v-if="showSourceNote"
                                                    v-html="item?.s_subtitle_notes"
                                                    class="sourceNote">
                                                
                                                </div>
                                            </template>
                                        </div>
                                    </div>

                                    <div class="col-span-1">
                                        <div class="quoteaction ">
                                            <a 
                                                v-if="hasSourceNote"
                                                @click.prevent="showSourceNote = !showSourceNote"
                                                href="" 
                                                :class="['sourceNoteBtn ml-auto', {'active' : showSourceNote}]">
                                                <quote-icon class="!w-5" icon="note" />
                                            </a>
                                        </div>
                                    </div>
                                    
                                   
                                </div>

                                 <div >
                                    <div class="quotetags">
                                        <span 
                                            @click="viewTag(tag)"
                                            v-for="tag in item.tags" 
                                            :key="tag.id+'tag'" 
                                            :class="['tag', {'activeTag' : tag?.currentTag}]">
                                            {{tag.tag_name}}
                                        </span>
                                    </div>

                                    <div 
                                        v-if="showNote == `${item.id}${categoryType}`"
                                        v-html="noteContent"
                                        class="notesWrap">
                                    </div>
                                </div>

                                <div >
                                    <div 
                                        class="quoteaction h-full items-end">
                                        <a 
                                            v-if="hasNote(item)"
                                            @click.prevent="toggleNote(item)"
                                            href="" 
                                            :class="['quoteactionitem', `${item.id}${categoryType}`, {'active' : showNote == `${item.id}${categoryType}` }]">
                                            <quote-icon class="!w-5" icon="note" />
                                        </a>
                                        <a 
                                            v-if="hasLink"
                                            :href="getSnippetUrl(this.item?.s_url)"
                                            target="_blank" 
                                            :class="['quoteactionitem']">
                                            <quote-icon class="!w-5" icon="link" />
                                        </a>

                                        <a 
                                            v-if="item.attachment"
                                            @click.prevent="showAttachment(item)"
                                            href="" 
                                            :class="['quoteactionitem']">
                                            <quote-icon class="!w-5" icon="play-file" />
                                        </a>
                                    </div>
                                </div>
                            
                        </div>
                    </div>
                </div>

            

                
            </div>
        </div>


    <player 
        :playerID="playerID" 
        v-model:url="attachmentUrl"/>
    </div>
</template>

<script>

/* Mixins */
import { utilsMixins } from '@/mixins/utilsMixins';
import {tagsMixins} from '@/mixins/tagsMixins';

/* Plugins */
// import _ from 'lodash';

/* Filters */
import filters from '@/filters';
const {compilationName, formatDate, getQuoteStoryPage} = filters;

/* Utils */
import CombinedSnipetsApis from '@/manie-utils/idb/apis/combinedSnipetsListApis';
import StringParse from '@/manie-utils/stringParsing';

/* Helpers */
import Helper from '@/helpers/helpers';

/* components */
import player from '@/components/player';

export default {
    name  : 'quoteStoryCard',
    mixins: [utilsMixins, tagsMixins],
    props : {
        item : {
            type   : Object,
            default: () => {}
        },
        noFavToggle : {
            type   : Boolean,
            default: () => false
        },
        noCopy : {
            type   : Boolean,
            default: () => false
        }
    },
    components : {
        'player': player,
    },
    data() {
        return {
            showNote      : null,
            awsURL        : process.env.VUE_APP_AWS_URL,
            attachmentUrl : null,
            showSourceNote: false,
            reRenderCard  : Math.random(),
            lIsFav        : false,
            hideThis      : false,
            isEllipsis    : false,
            cardId        : Helper.makeString(5)
        }
    },
    watch : {
        /* Watch item for changes */
        item :{ 
            handler(newItem, oldItem) {
                console.log('changes item', newItem, oldItem);
                this.reRender();
            },
            deep : true
        }
    },
    computed : {
        
        /**
         * Note content
         */
        noteContent() {
            return this.item?.s_notes ?? ''
        },
        /**
         * Category type
         */
        categoryType() {
            if(this.item.story) return 'story';
            if(this.item.quote) return 'quote';
            if(this.item.poem_content) return 'poem';

            return ''
        },
        /**
         * Check if has link
         * 
         */
        hasLink() {
            if(this.item.url) return true;
            if(this.item.poem_url) return true;
            if(this.item.song_url) return true;
            if(this.item.s_url) return true;

            return false;
        },
        /* Subtitle */
        subtitle() {
            return this.item?.s_subtitle ?? '';
        },
        /* Check if has source note */
        hasSourceNote() {
            return this.item?.s_subtitle_notes ? true : false;
        },
        /* Source date */
        sourceDate() {
            return this.item?.s_date ?? ''
        },
        /* source date format */
        dateFormat() {
            const type = this.item?.s_date_type ?? 'full';
            
            if(type == 'year') return 'YYYY';
            if(type == 'monthyear') return 'MMM. YYYY';

            return 'MMM. D, YYYY';
        },  
        /* Generate Player id */
        playerID () {
            return  Helper.makeString(5);
        },
        /* Loggedin user */
        user() {
            return this.$store.state.user;
        }
    },
    methods : {
       
        /**
         * Filters
         */
        compilationName,
        formatDate,
        getQuoteStoryPage,
        /**
         * Check if snippet content has elipsis
         * 
         */
        hasElipsis() {
            const ele = document.getElementById(this.cardId);
            console.log('has ellisis', ele)
            if(!ele) return;
            this.isEllipsis =  StringParse.hasElipsis(ele);
        },
        /**
         * Return snippet url with protocol
         * @param {String} url
         */
        getSnippetUrl(url) {
            if(!url) return '';

            if(!url.includes('http')) return `https://${url}`;


            return url;
        },
        /**
         * Toggle active class
         * @param {Object} e event
         */
        toggleActiveClass(e) {
            let parentEl = e.target?.farthestViewportElement?.parentElement ?? e.target.parentElement;
            console.log('toggle class ', e, parentEl)

            this.lIsFav = !this.lIsFav;

            if(Array.from(parentEl.classList).includes('fav')) {
                parentEl.classList.toggle('active');
            } else {
                parentEl = parentEl.parentElement;

                if(Array.from(parentEl.classList).includes('fav')) {
                    parentEl.classList.toggle('active');
                }
            }

            if(this.lIsFav) CombinedSnipetsApis.addSnippetToFavorite({snippet_id : this.item.id, user_id : this.user?.id});
            if(!this.lIsFav) {
                CombinedSnipetsApis.removeFavSnippet({snippet_id : this.item.id, user_id : this.user?.id});

                this.$emit('deletedFav', this.item.id)

            }
            

            
        },
        /* Open link */
        openLink() {
            const url = this.item?.s_url ?? '';
            window.open(url);
        },
        /* View quote or edit  */
        viewQuote() {
            let   routeName = this.$route.meta.viewType == 'personal' ? 'quoteview' : 'libraryQuoteview';
            let type      = '';

            if(this.item?.quote) type        = 'quote';
            if(this.item?.story) type        = 'story';
            if(this.item?.poem_content) type = 'poem';
            if(this.item?.song_content) type = 'song';

            

            routeName = this.iOwned(this.item?.created_by) ? this.$route.meta.viewType == 'personal' ? 'editquote' : 'libraryEditquote' : routeName;

            console.log(this.iOwned(this.item?.created_by) && this.$route.meta.viewType == 'personal', routeName)

            this.$router.push({name : routeName, params : {id : this.item.id}, query : {story : this.item.story ? true : false, forApproval: this.item?.isApproval ?? false, type}});
        },
        /* View author public or personal */
        viewAuthor() {
            if(this.$route.meta?.viewType == 'personal' ) {
                this.$router.push({name : 'authorprofile', params: {id : this.item?.author?.id}});
                return;
            }
            this.$router.push({name : 'libraryAuthorProfile', params: {id : this.item?.author?.id}});
        },  
        /* Redirect to source */
        viewSource(){
            if(this.$route.meta?.viewType == 'personal' ) {
                this.$router.push({name : 'sourceview', params: {id : this.item?.source?.id}, query : {story : this.item.story ? true : false}});
                return;
            }
            this.$router.push({name : 'librarySourceview', params: {id : this.item?.source?.id}, query : {story : this.item.story ? true : false}});
        },
        /* Rerender card */
        reRender() {
            this.lIsFav = this.item?.favorited_by?.find(f => f.user_id == this.user?.id) ? true : false;
            this.reRenderCard = Math.random();
        },
        /* Copy quote / story content and it's meta */
        copyAllToClipboard(element) {
            // let string = `${item.story ? item.story: item.quote} ${item?.author.first_name} ${item?.author.last_name} ${item?.source?.containers.map(sc => sc.sc_name).join('')} ${item?.source.source_name} ${item?.story_subtitle ?? ''} ${item?.quote_subtitle ?? ''} ${item?.page_start ?? ''} ${item?.page_end ?? ''} ${item?.quote_date ?? ''} ${item?.story_date ?? ''} ${item?.tags.map(t => t.tag_name).join(' ')}`;
            const elClone = element.cloneNode(true);                       // clone a copy for modifying
            const tags    = elClone.querySelector('.quotetags');
            const as       = elClone.querySelectorAll('.quoteBodyWrap a');
            if(tags) tags.remove(); // remove tags for copying text
            if(as) as.forEach(a => a.remove()); // remove see more and less button
            

            document.body.appendChild(elClone);
            const string = elClone.innerText;
            elClone.remove(); //remove after copying the text

            this.copyClipboard({
                string
            })
        },
        /* View tag */
        viewTag(tag) {
            /* Add tracker */
            this.tagClickTracking({params : tag})

            if(this.$route.meta.viewType == 'personal') {
                this.$router.push({name : 'tagview', params : {id : tag.id}});
            } else {
                this.$router.push({name : 'libraryTagview', params : {id : tag.id}});
            }
        },
        /* Show attachment */
        showAttachment(item) {
            const url                = item?.attachment?.sf_url ?? '';
                  this.attachmentUrl = `${process.env.VUE_APP_AWS_URL}${url}`;
            // this.$bvModal.show(this.playerID);            
        }, 
    },
    beforeMount() {
        /* Check if favorited */
        this.lIsFav = this.item?.favorited_by?.find(f => f.user_id == this.user?.id) ? true : false;
    },
    mounted() {
        /* Check for elipsis */
        this.hasElipsis();
    }
}
</script>