/* Plugins */
import axios from 'axios'

/* Helper  */
import Helper from '@/helpers/helpers';

/* mixins */
import { cacheMixins } from './cacheMixins';

export const userMixins = {
    mixins : [cacheMixins],
   data() {
    return {
        awsUrl : process.env.VUE_APP_AWS_URL
    }
   },
    methods : {
        /* Restore achieved user */
        async restoreArchievedUser({params  ={}, callback = ()=> {}} = {}) {
            const url = `/api/user/restorearchived`;
            const res = await axios.post(url, params).catch(err => console.log(err));

            if(res && res.data) {
                callback(res.data);
            }
            
        },
        /* Get user archieved list */
        async getArchievedUsers({params = {}, callback =() => {}} = {}) {
            const url = `/api/user/listarchived`
            const res = await axios.get(url, {params}).catch(err => console.log(err));

            if(res && res.data) {

                callback(res.data);
            }
        },
        /* Update user level  */
        async updateUserLevel({params, callback = () => {}} = {}) {
            const url = `/api/user/admin/useraccess/${params.user_id}`
            const res = await axios.patch(url, params).catch(err => console.log(err))

            if(res && res.data) {
                callback(res.data)
            }
        },
        /* Delete User */
        async deleteUser({params, callback = () => {}} = {}) {
            const url = `/api/user/${params.id}`
            const res = await axios.delete(url).catch(err => console.log(err))

            if(res && res.data) {
                callback();
            }
        },
        /* Change password */
        async changePassword({params = {}, callback = () => {}} = {}) {
            // const url = `/api/reset-password/${params.token}`
            const url = `/api/user/changepw`
            const res = await axios.patch(url, params).catch(err => console.log(err))

            if(res && res.data) {
                 callback(res.data)
            }
        },
        /* Admin user file upload */
        async adminUserFileUpload({params = {}, callback = () => {}} = {}) {
            const url = `/api/user/file/admin`
            const res = await axios.post(url, params).catch(err => console.log(err))

            if(res && res.data) {
                callback(res.data)
            }
        },
        /* Upload user file */
        async uploadUserFile({params = {}, callback = () => {}} = {}) {
            const url = `/api/user/thumbnail`

            const newParams = new FormData()
            newParams.append('file', params.file)

            const res = await axios.post(url, newParams).catch(err => console.log(err))

            if(res && res.data) {
                callback(res.data)
            }
            
        },
        /* Get current user */
        async getUser() {
            const url = `/api/user`
            // const res = await axios.get(url).catch(err => console.log(err))

       

            const errorCallback = () => {
                 /* if failed to get current user logout from the app */
                 if(!this.$route.name != 'login') {
                    Helper.deleteCookie('quoteapptoken')
                    sessionStorage.removeItem('qpIsAdmin')
                    this.$router.push({name : 'login'})
                }
            }

            /* Check if has token if none redirect to login page */
            if(!Helper.getCookie('quoteapptoken')) {
                errorCallback();

                return ;
            }

            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback :(res) => {
                    /* re-fetching */
                    if(res) {
                        this.$store.commit('updateUser', res.data);
                        
                        if(res.data.is_admin) {
                            sessionStorage.setItem('qpIsAdmin', true)
                        }
                    } 
                },
                errorCallback
            })


            console.log('log in get user', res);


            if(res) {
                this.$store.commit('updateUser', res.data);
                
                if(res.data.is_admin) {
                    sessionStorage.setItem('qpIsAdmin', true)
                }

            } else {
               errorCallback();
            }
           
        },
        /* Update user profile */
        async updateUser({params = {}} = {}) {
            const url  = `/api/user/${params.id}`
            await axios.patch(url, params).catch(err => console.log(err))
        },
        /* Get user list */
        async getUserList({callback = () => {}} = {}) {
            const url = `/api/admin/user/list`
            const res = await axios.get(url).catch(err => console.log(err))

            if(res && res.data) {
                callback(res.data)
            }
        },
        /* Register user */
        async registerUser({params = {}, callback = () => {}, errorCallback  = () => {}} = {}) {
            const url  = `/api/register`
            const res = await axios.post(url, params).catch(err => errorCallback(err));
            if(res && res.data) {
                callback(res.data)
            } 
        },
        /* Get user by id */
        async getUserById({params = {}, callback = () => {}} = {}) {
            const url = `/api/user/${params.id}`
            const res = await axios.get(url).catch(err => console.log(err))

            if(res && res.data) {
                callback(res.data)
            }
        },
       /* Search user */
       async searchUser({params = {}, callback = () => {}} = {}) {
            const url = `/api/admin/user/search`
            const res = await axios.post(url, params).catch(err => console.log(err))

            if(res && res.data) {
                callback(res.data)
            }
       }
    }
}