/**
 * Convert number into corresponding letter
 * @param {Number} number 
 * @namespace NumberToLeeter
 */

export default function NumberToLeeter(number) {

    if(!number) return 'A';

    return (number + 9).toString(36).toUpperCase();

}