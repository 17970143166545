/**
 * Source models
 * Object property that allow to be save in indexdb
 * @namespace SourceModel
 */

export default class SourceModel {
    /**
     * Destructuring object and only return decleared properties
     */
    static schema (object) {
        const  {
            id               = null,
            source_name      = null,
            created_by       = null,
            container_id     = null,
            source_thumbnail = null,
            source_views     = null
            }                = object;



        return {
            id,
            source_name,
            created_by : created_by?.id || created_by,
            container_id,
            source_thumbnail,
            source_views
        }

    }

}