import axios from 'axios';

/**
 * Helper class is a collection of re-usable function 
 * @namespace Helper
 */
export default class Helper {

    /**
     * Check json if parsable or not
     * @memberof Helper
     * @function isjson
     * @param {String} string JSON in string format
     */
    static isjson(string) {
        try {
            if(isNaN(string)) {
                JSON.parse(string);
            } else {
                return false
            }
        } catch (e) {
            return false;
        }

        return true

    }

    /**
     * Axios global configuration this will set the default base url and token 
     * @memberof Helper
     * @function axoisglobalconfig
     */
    static axoisglobalconfig() {
        /**
         * Set default base url
         */
        axios.defaults.baseURL = process.env.VUE_APP_APIBASE;

        /**
         * Set authorization token
         * @param {String} token
         */

        const token = this.getCookie('quoteapptoken');

        if(token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

       
        
    }

    /**
     * Axios request interceptor
     * Intercept any request and do something with the request before sending it
     * @memberof Helper
     * @function interceptRequest
     * @param {Function} callback function that will run after intercepting
     */

    static async interceptRequest({callback = () => {}} = {}) {
        // // Add a request interceptor
        // await axios.interceptors.request.use(function (config) {
        //     // Do something before request is sent
        //     callback(config);
            
        //     return config;
        // }, function (error) {
        //     // Do something with request error
        //     return Promise.reject(error);
        // });

        // Add a response interceptor
        axios.interceptors.response.use(async function (config) {
            // Do something before request is sent
            const controller = new AbortController();

            callback({controller});

            controller.abort();


            return {
                ...config,
                signal: controller.signal
            }


        }, function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error);
        });
    }

    /**
     * Adding Cookie
     * @memberof Helper
     * @function setCookie
     * @param {String} cname cookies name
     * @param {String} cvalue cookies value
     * @param {Number} exdays expriation number of days
     */
    static  setCookie(cname, cvalue, exdays = 1) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    /**
     * Getting a cookie
     * @memberof Helper
     * @function getCookie
     * @param {String} cname cookie name
     * @returns {String} cookie data
     */
    static getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');

        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    /**
     * Delete all cookies
     * @memberof Helper
     * @function deleteAllCookies
     */
    static deleteAllCookies() {
        var cookies = document.cookie.split(";");
    
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }

    /**
     * Deleting specific cookie
     * @memberof Helper
     * @function deleteCookie
     * @param {String} cname cookie name 
     */
    static deleteCookie(cname) {
        document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }

    /**
     * Serialize Function
     * Used in JSON Stringify
     * @memberof Helper
     * @function serialize
     * @param {*} key
     * @param {Function} value 
     * @returns string
     */
    static serialize(key, value) {
		if (typeof value === 'function') {
			return value.toString();
		}
		return value;
	}
    
	/**
     * Deserialize Function
     * @memberof Helper
     * @function deserialize
     * @param {*} key 
     * @param {function} value 
     * @returns function
     */
	static deserialize(key, value) {
		if (value && typeof value === "string" && value.substr(0, 8) == "function") {
			var startBody = value.indexOf('{') + 1;
			var endBody   = value.lastIndexOf('}');
			var startArgs = value.indexOf('(') + 1;
			var endArgs = value.indexOf(')');
	
			return new Function(value.substring(startArgs, endArgs), value.substring(startBody, endBody));
		}
		return value;
	} 

    /**
     * Get device name
     * @memberof Helper
     * @function getDeviceName
     */
    static getDeviceName () {
        let deviceName = '';
        
        // Device type
        const isMobile = {
            Android: function() {
                return navigator.userAgent.match(/Android/i);
            },
            Datalogic: function() {
                return navigator.userAgent.match(/DL-AXIS/i);
            },
            Bluebird: function() {
                return navigator.userAgent.match(/EF500/i);
            },
            Honeywell: function() {
                return navigator.userAgent.match(/CT50/i);
            },
            Zebra: function() {
                return navigator.userAgent.match(/TC70|TC55/i);
            },
            BlackBerry: function() {
                return navigator.userAgent.match(/BlackBerry/i);
            },
            iOS: function() {
                return navigator.userAgent.match(/iPhone|iPad|iPod|Macintosh/i);
            },
            Windows: function() {
                return navigator.userAgent.match(/IEMobile/i);
            },
            any: function() {
                return (isMobile.Datalogic() || isMobile.Bluebird() || isMobile.Honeywell() || isMobile.Zebra() || isMobile.BlackBerry() || isMobile.Android() || isMobile.iOS() || isMobile.Windows());
            }
        };


        if (isMobile.Datalogic())
            deviceName = 'Datalogic';
        else if (isMobile.Bluebird())
            deviceName = 'Bluebird';
        else if (isMobile.Honeywell())
            deviceName = 'Honeywell';
        else if (isMobile.Zebra())
            deviceName = 'Zebra';
        else if (isMobile.BlackBerry())
            deviceName = 'BlackBerry';
        else if (isMobile.iOS())
            deviceName = 'iOS';
        else if ((deviceName == '') && (isMobile.Android()))
            deviceName = 'Android';
        else if ((deviceName == '') && (isMobile.Windows()))
            deviceName = 'Windows';

        
        // Browser name
        let browsername = '';

        const browser = {
            chrome : () => {
                return navigator.userAgent.match(/Chrome/i)
            },
            firefox : () => {
                return navigator.userAgent.match(/Firefox/i)
            },
            safari : () => {
                return navigator.userAgent.match(/Safari/i)
            },
            ie : () => {
                return navigator.userAgent.match(/MSIE|Trident/i)
            }
        }

         if(browser.safari) {
            browsername = 'Safari'
        } else if(browser.firefox) {
            browsername = 'Firefox'
        } else if(browser.ie) {
            browsername = "Internet Explorer"
        } else if(browser.chrome) {
            browsername = 'Chrome'
        } 

        return `${deviceName} ${browsername}`;
    }
    
     /** 
      * Generate random string 
      * @memberof Helper
      * @function makeString
      * @param {Number} length How many charters/letter will be generated 
      */
     static makeString(length) {
        let result           = '';
        const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
            for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
       return result;
    }

    /**
     * Generate device uuid 
     * this will generate unique uuid for this device and save it to localStorage for permanent identity
     * @memberof Helper
     * @param {Number} userId //user id of the loggedinuser
     * */

    static generateUUID({userId = null} = {}) {
        const deviceUUID = localStorage.getItem('deviceUUID')
        if(deviceUUID && deviceUUID.indexOf(`_${userId}`) >= 0) return; // preven from generating new uuid

        const newUUID = self.crypto.randomUUID() + `_${userId}`;
        localStorage.setItem('deviceUUID', newUUID);
    }
    
}