/**
 * Compose the quote / story page
 * @param {Object} data 
 * @namespace PageNumber
 */

export default function PageNumber (data) {

    if(!data) return '';

    const startPage       = data.s_page_start ?? '';
    const endPage         = data.s_page_end ?? '';
    const hasCreatedDate  = data.s_date ? ', ' : '';
    const hasMultiplePage = endPage == startPage ? 'pg' : 'pp'

    if(!startPage) return hasCreatedDate;
    if(!endPage) return hasCreatedDate;

    const page = startPage == endPage ? `${startPage+hasCreatedDate}` : `${startPage}-${endPage}${hasCreatedDate}`;

    return `, ${hasMultiplePage} ${page}`;

}