/**
 * Source APis
 * @namespace SourceListApi
 */

/* Utils */
import { addUpdateCollection, deleteCollection, getCollections, getCollectionsBy } from "../localbase";

/* Models */
import SourceModel from "../models/sourcesModels";
import ContainerModel from "../models/containerModel";

/* Resource */
import SourceResource from "../resources/sourceResource";
import ContainerResource from '../resources/containerResource';

/* Plugins */
import axios from 'axios';
import _ from 'lodash';

export default class SourceListApi {

    /**
     * Get popular sources
     * @param {Function} callback
     */
    static getPopularSources({callback = () => {} } = {}) {

        /* Local records */
        const localRecords = async () => {
            let sources = []

            /* Get local records all sources */
            let list = await getCollections({name : 'sources'})

            /* Add snippets count */
            for (let index = 0; index < list.length; index++) {
                const source = list[index];
                const snippets = await getCollections({name : 'snippets', filter : {key : 'source_id', value : parseInt(source.id)}})
                const sourceViews = snippets?.length + source.source_views

                list = [...list].map(l => {
                    if(l.id == source.id) return {...l, source_views : sourceViews}

                        return l
                })
            }
            /* Sort by Descending */
            list = [...list].sort((a, b) => b.source_views - a.source_views)

            /* Filter only >= 5 view will only proceed */
            list = [...list].filter(l => l.source_views >= 5)
            
            /* Get top 20 */
            list = [...list].slice(0, 20)

            /* Get sources source */
            for (let index = 0; index < list.length; index++) {
                const source = list[index];

                sources = [...sources, await SourceResource.getSource({source_id : parseInt(source.id)})]

            }

            /* Sort by Descending */
            sources = [...sources].sort((a, b) => b.source_views - a.source_views)

            callback(sources)
        }        

        localRecords()

        /* Background sync  */
        const bgSync = async () => {
            const url = `/api/source/popular`
            const res = await axios.get(url).catch(err => err)

            if(res?.data) {
                const list = res?.data?.data || []

                this.addSourcesToLocalDB(list)

                localRecords()

            }
        }

        bgSync()

    }

    /**
     * Sync deleted containers
     * 
     */
    static async syncDeletedContainers() {
        const url = `/api/source/container/listarchived`;
        const res  = await axios.get(url).catch(err => err);

        if(res?.data) {
            const list = res?.data?.data;

            list.forEach(l => this.deleteContainer({container_id : l.id}))
        }
    }
    
    /**
     * Remove Container from localdb
     * @param {Number} container_id
     */
    static async deleteContainer({container_id} = {}) {
        await deleteCollection({name : 'containers', filter : {id : parseInt(container_id)}});
    }

    /**
     * Delete source
     * @param {Number} source_id
     * @param {Function} callback
     */
    static async deleteSource({source_id, callback = () => {}} = {}) {
        await deleteCollection({name : 'sources', filter : {id : parseInt(source_id)}});

        callback();
    }

    /**
     * Sync deleted sources
     */
    static async syncDeletedSources() {
        const url = `/api/source/listarchived`;
        const res = await axios.get(url).catch(err => err);

        if(res?.data) {
            const list = res.data.data;

            list.forEach(l => deleteCollection({name : 'sources', filter: {id : l.id}}));
        }
    }

    /**
     * Get container
     * @param {Number} container_id
     * @param {Function} callback
     */
    static async getContainer({container_id, callback =() => {}}) {
      
        const callbackRes = async () => {
            const container = await ContainerResource.getContainer({container_id : parseInt(container_id)});
            callback(container);
        }

        callbackRes();

        const url = `/api/source/container/${container_id}`;
        const res = await axios.get(url).catch(err => err);

        if(res?.data) {
            this.addContainersToLocalDB([res.data.data]);
            
            callbackRes();
        }
    }

    /**
     * Get Containers
     * @param {Function} callback
     * @param {Number} user_id
     */
    static async getContainers({user_id = null, callback = () => {}} = {}) {
        const callbackRes = async () => {
            console.log(getCollections);

            // await getCollectionsBy({collectionName : 'containers', indexName : 'sc_name', startRange : '1', endRange : 'Z', callback : async  data => {
            //     const list = data;
    
            //     let containers = [];
        
            //     for (let index = 0; index < list.length; index++) {
            //         const container = list[index];
        
            //         containers = [...containers, await ContainerResource.getContainer({container_id : container.id, user_id})]
                    
            //     }
        
            //     callback(containers);
            // }})
            
            const list = await getCollections({name : 'containers'});
    
            let containers = [];
    
            for (let index = 0; index < list.length; index++) {
                const container = list[index];
    
                containers = [...containers, await ContainerResource.getContainer({container_id : container.id, user_id})]
                
            }
    
            callback(containers);
        }

        callbackRes();

    }

    /**
     * Add source to localdb
     * @param {Array} sources
     */
    static addSourcesToLocalDB(sources) {
        sources.filter((s, i, a) => a.findIndex(sf => sf.id == s.id) == i) // remove duplicate
        .map(s => { 
            /* Get container id where this source belong */
            const [container] = s.containers;
            
            if(container) s.container_id = container.id;

            return s;
        }).forEach(s => addUpdateCollection({name : 'sources', data : SourceModel.schema(s)})); 
    }

    /**
     * Add containers to localdb
     * @param {Array} containers
     */
    static addContainersToLocalDB(containers) {
        containers
        .filter((c, i, a) => a.findIndex(cf => cf.id == c.id) == i) // remove duplicate
        .forEach(c => addUpdateCollection({name : 'containers', data : ContainerModel.schema(c)}));
    }

    /**
     * Get source 
     * @param {Number} source_id
     * @param {Function} callback
     */

    static async getSource({source_id, callback = () => {}} = {}) {
        const source = await SourceResource.getSource({source_id : parseInt(source_id)});

        callback(source);

        /* Call api for view count */
        const url = `/api/source/${source_id}`
        axios.get(url).catch(err => console.log('error', err))
    }

    /**
     * Get source list 
     * @param {Function} callback
     * @param {Number} user_id 
     */

    static async getSources({user_id = null, callback = () => {}} = {}) {
        const callbackRes = async () => {
            console.log(getCollectionsBy)
            
            // let currentCount = 0;
            // let batchedSources  = [];
            // let fetching     = false;

            // const FetchingResource = async () => {
            //     if(fetching) return;
            //     fetching = true;
            //     const list = [...batchedSources[currentCount]];
            //     currentCount++
                
            //     let sources = [];
            //     for (let index = 0; index < list.length; index++) {
            //         const source = list[index];

            //         sources = [...sources, await SourceResource.getSource({source_id : source.id, user_id})];
                    
            //     }

            //     callback(sources);
            //     fetching = false;
                
            //     /* Return next batch */
            //     if(currentCount < batchedSources.length) FetchingResource();
            // }
            

            // await getCollectionsBy({collectionName : "sources", indexName : "source_name", startRange : "1", endRange : 'Z',  callback : async data => {
            //    batchedSources = [...batchedSources, data]
            // }})

            // FetchingResource();

            const list = await getCollections({name : "sources"});

            let sources = [];

            for (let index = 0; index < list.length; index++) {
                const source = list[index];

                sources = [...sources, await SourceResource.getSource({source_id : source.id, user_id})];
                
            }

            callback(sources);
        }

        callbackRes();

        let page  = 1;



        const syncall = async () => {

            const url = `/api/source/combined`;
            const res = await axios.get(url, {params : {sorting : 'alpha', page}}).catch(err => err);
    
            if(res?.data) {
                const sources = [...res.data?.published?.data, ...res.data?.yourown?.data];
                const ownedContainers = _.flatten([...sources?.map(s => s.containers)]);
                const publishedContainers = [...res.data?.containerwithpublished?.data, ...res.data?.yourownscontainer?.data];
            
                this.addSourcesToLocalDB(sources);
                this.addContainersToLocalDB([...publishedContainers, ...ownedContainers]);


                if(!res.data.containerwithpublished?.links?.next && !res.data.published?.links?.next && !res.data.yourown?.links?.next && !res.data.yourownscontainer?.links?.next) {
                    callbackRes();
                } else {
                    page++;
                    syncall();
                }
    
    
            }
        }

        syncall();

    }
}