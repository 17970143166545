/**
 * Song Mixins
 * @namespace SongMixins
 */

/* Plugins */
import axios from 'axios';

export const SongMixins  = {
    methods : {
        /**
         * Get Song
         * @param {Number} id
         * @param {Function} callback
         */
        async getSong({id = null, callback = () => {}} = {}) {
            const url  = `/api/song/${id}`;
            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback : (res) => {
                    if(res) {
                        callback(res);
                    }
                }
            })


            if(res) {
                callback(res);
            }
        },
        /** Get combined song apis
         * @param {Function} callback
         * @param {Object} params
         */
        async getCombinedSongs({params = {}, callback = () => {}} = {}) {
            const url = `/api/song/combined?${new URLSearchParams(params)}`;
            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback : (res) => {
                    if(res) {
                        callback(res);
                    }
                }
            })


            if(res) {
                callback(res);
            }
        },
        /**
         * Create song
         * @param {Object} params
         * @param {Function} callback
         */
        async createSong({params = {}, callback = () => {}} = {}) {
            const url = `/api/song`
            const res = await axios.post(url, params).catch(err => err);

            if(res?.data) {
                callback(res.data);
            }
        },
        /**
         * Upload song file
         * @param {Object} params
         * @param {Function} callback
         */
        async uploadSongFile({params = {}, callback = () => {}} = {}) {
            const url = `/api/song/file`
            const res = await axios.post(url, params).catch(err=>err);

            if(res?.data) {
                callback(res?.data);
            }
        },
        /**
         * Add song tags
         * @param {Object} params
         * @param {Function} callback
         */
        async addSongTags({params = {}, callback = () => {}} = {}) {
            const url = `api/song/tag/multitags`;
            const res = await axios.post(url, params).catch(err => err);

            if(res?.data) {
                callback(res?.data);
            }
        }
    }
}