/* Plugins */
import axios from 'axios';
import { cacheMixins } from './cacheMixins';

export const tagsMixins = {
    mixins : [cacheMixins],
    methods : {
        /**
         * Add multiple tag to a quote in 1 api
         * @param {Array} tags Tag id's 
         * @param {Number} quote_id 
         * @param {Function} callback
         */
        async addQuoteTags({tags = [], quote_id = null, callback = () => {}} ={}) {
            const url = `/api/quote/tag/addtags`;
            const res = await axios.post(url, {tags_arr : tags, quote_id}).catch(err => console.log(err));

            if(res?.data) {
                callback(res.data);
            }
        },
        /* Delete tags cache  */
        async deleteTagCache({includesUrls = [], includesOnly = false} = []) {
            let urls = [...includesUrls, 
                '/api/tags/yourown',
                '/api/tags/withpublished'
            ]

            if(includesOnly) urls = [...includesUrls];

            this.deleteCache(urls)
        },
        /* Check if has published quote from the tag */
        hasPublishedTag(tag) {
            if(tag?.quotes.find(q => q.publish_status)) return true; // has published quote
            if(tag?.stories.find(t => t.publish_status)) return true; // has published story

            return false;
        },
        /* Published tags */
        async publishedTags({params = {}, callback = () => {}} = {}) {
            const url = `/api/tags/withpublished?${new URLSearchParams(params)}`;
            // const res = await axios.get(url, {params}).catch(err => console.log(err));

            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback : res => {
                    if(res) {
                        const returnData = [...res.data?.data ?? res?.data ?? [], ...res.data?.tags_quotes ?? [], ...res.data?.tags_stories ?? []].filter((t,i,a) => {
                            return a.findIndex(at => at.id == t.id) == i;
                        })
        
        
                        console.log('returnData', returnData)
        
                        callback({data : returnData});
                    }
                }
            })

            if(res) {
                const returnData = [...res.data?.data ?? res?.data ?? [], ...res.data?.tags_quotes ?? [], ...res.data?.tags_stories ?? []].filter((t,i,a) => {
                    return a.findIndex(at => at.id == t.id) == i;
                })


                console.log('returnData', returnData)

                callback({data : returnData});
            }

        },
        /* Click tracking for tags */
        async tagClickTracking({params = {}} = {}) {
            const url = `/api/tags/counter/${params.id}`;

             await axios.post(url).catch(err => console.log(err));
        },
        /* Get popular tags by stories */
        async getPopularTagsByStories({callback = () => {}} = {}) {
            const params = {xtimes : 5};
            const url = `/api/tags/popular/onstory?${new URLSearchParams(params)}`;
            // const res = await axios.get(url, {params : {xtimes : 5}}).catch(err => console.log(err));
            
            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback : res => {
                    if(res) {
                        callback(res);
                    }
                }
            })

            if(res) {
                callback(res);
            }
        },
        /* Get popular tags by quotes */
        async getPopularTagsByQuotes({callback =() => {}} = {}) {
            const params = {xtimes : 5};
            const url = `/api/tags/popular/onquote?${new URLSearchParams(params)}`;
            // const res = await axios.get(url, {params : {xtimes : 5}}).catch(err => console.log(err));
            
            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback : res => {
                    if(res) {
                        callback(res);
                    }
                }
            })

            if(res) {
                callback(res);
            }
        },
        /* Get tag quotes */
        async getTagQuotes({params = {}, callback =() => {}} = {}) {
            const url =`/api/tags/quotes?${new URLSearchParams(params)}`;
            // const res = await axios.get(url, {params}).catch(err => console.log(err));

            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback : res => {
                    if(res) {
                        callback(res);
                    }
                }
            })

            if(res) {
                callback(res)
            }
        },
        /* Get tag stories */
        async getTagStories({params, callback =() => {}} = {}) {
            const url = `/api/tags/stories?${new URLSearchParams(params)}`
            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback : res => {
                    if(res) {
                        callback(res);
                    }
                }
            })

            if(res) {
                callback(res)
            }
        },
        /* Get user own tags */
        async getOwnTags({page = 1, callback = () => {}} = {})  {
            const params = {page}
            const url = `/api/tags/yourown?${new URLSearchParams(params)}`
            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback : res => {
                    if(res) {
                        callback(res);
                    }
                }
            })

            if(res) {
                callback(res)
            }
        },
        /* Get tags */
        async getTags({page = 1, callback = () => {}} = {})  {
            const url = `/api/tags`
            const res = await axios.get(url, {params : {page}}).catch(err => console.log(err))

            if(res && res.data) {
                if(callback) {
                    callback(res.data)
                }
            }
        },
        /* New tag */
        async newTag({params = {}, callback = () => {}} = {}) {
            const url = `/api/tags`
            const res = await axios.post(url, params).catch(err => console.log(err))

            /* Delete tag cache */
            this.deleteTagCache();

            if(res && res.data) {
                if(callback) {
                    callback(res.data)
                }
            }
        },
        /* Get tag */
        async getTag({tagID = null, callback = () => {}} = {}) {
            const url = `/api/tags/${tagID}`
            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback : res => {
                    if(res) {
                        callback(res);
                    }
                }
            })

            if(res) {
                callback(res)
            }
        },
        /* Update tag */
        async updateTag({params = null, callback = () => {}} = {}) {
            const url = `/api/tags/${params.id}`
            const res = await axios.patch(url, params).catch(err => console.log(err))

            /* Delete tag cache */
            this.deleteTagCache({includesUrls : [url]});

            if(res && res.data) {
                if(callback) {
                    callback(res.data)
                }
            }
        },
        /* Update tag */
        async deleteTag({params = null, callback = () => {}} = {}) {
            const url = `/api/tags/${params.id}`
            const res = await axios.delete(url).catch(err => console.log(err))
            

            /* Delete tag cache */
            this.deleteTagCache({includesUrls : [url]});

            if(res && res.data) {
                if(callback) {
                    callback(res.data)
                }
            }
        },
        /* Search tag */
        async searchTag({searchString = '', callback = () => {}} = {}) {
            const url = `/api/tags/search`
            const params = {
                searchtext : searchString
            }

            const res = await axios.post(url, params).catch(err => console.log(err))

            if(res && res.data){
                callback(res.data)
            }
        }
    }   

}