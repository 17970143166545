<template>
<div class="publicApprovalWrap">

    <div class="approvalBody">

        <div class="container">

            <div class="quotelist">
                <template v-if="isLoading">
                    <div 
                        v-for="n in 5"
                        :key="n"
                        class="quoteitem isLoading">

                    </div>
                </template>

                <template v-else>
                    <div 
                        v-for="(item) in mergedQuoteStory" 
                        :key="item.id+`${item.story ? 'story' : 'quote'}`" 
                        :class="['quoteitem', {'activequote' : item.id+`${item.story ? 'story' : 'quote'}` == activeaction}]">

                        <div v-if="processingHighlightLocal" class="isLoading h-full w-full absolute top-0 left-0 z-10"></div>


                        <quote-story-card 
                            :noFavToggle = "true"
                            :noCopy      = "true"
                            :item        = "item"
                            >
                        </quote-story-card>
                    </div>

                </template>

            </div>
        </div>
    </div>
    <player 
        :url="attachmentUrl"/>
</div>
</template>

<script>
/* Mixins */
import {
    quotesMixins
} from '@/mixins/quotesMixins'
import {
    storyMixins
} from '@/mixins/storyMixins';

import { loaderMixins } from '@/mixins/loaderMixins';
import { utilsMixins } from '@/mixins/utilsMixins';
import quoteStoryCard from '@/components/quoteStoryCard';
import { adminApprovalMixins } from '@/mixins/adminApprovalMixins';

/* Plugins */
import _ from 'lodash';

/* Utils */
import CombinedSnipetsApis from '@/manie-utils/idb/apis/combinedSnipetsListApis';

/* Components */
import player from '@/components/player';

export default {
    mixins: [quotesMixins, storyMixins, loaderMixins, utilsMixins, adminApprovalMixins],
    props : {
        processingHighlightLocal : {
            type : Boolean,
            default : () => false
        },
        filterBy : {
            type   : Object,
            default: () => {}
        },
        showSearch : {
            type   : Boolean,
            default: () => false
        },
        searchStr : {
            type : String,
            default : () => ""
        }
    },
    components : {
        'player'          : player,
        'quote-story-card': quoteStoryCard
    },
    data() {
        return {
            awsURL              : process.env.VUE_APP_AWS_URL,
            activeaction        : null,
            currQuotePage       : null,
            quoteList           : [],
            snippetList         : [],
            showNewQuoteOpt     : false,
            storyList           : [],
            currStoryPage       : null,
            showFilterPop       : false,
            copiedContent       : null,
            showNote            : null,
            attachmentUrl       : null,
            combinedApprovalList: {}
        }
    },
    watch: {
        /* Watch route and make calls */
        $route: {
            handler() {
                /* Re-call if route change */
                this.resetList()
                this.getStoriesAndQuote()
            },
            deep: true
        }
    },
    computed: {
        /* Merge quote and story*/
        mergedQuoteStory() {
            // const stories = this.storyListl

            // let list = [...this.quoteList, ...stories]

            // const stories = this.combinedApprovalList?.stories ?? [];
            // const quotes  = this.combinedApprovalList?.quotes ?? []

            let list = [...this.snippetList];

            /* Modify and add property */
            list = list.map(l => {
                /* Assign author property */
                const [author] = l.authors ? l.authors : []
                l.author = author ?? l?.author_id ?? l?.author ?? null; 
            
                return l
            })

            /* Return empty if no filter by */
            if (this.filterBy.type.length == 0) return []

            /* Filter by quotes*/
            if (!this.filterBy.type.includes('story')) list = [...list.filter(l => l.quote)];

            /* Filter by stories */
            if (!this.filterBy.type.includes('quote')) list = [...list.filter(l => l.story)];


            /* Search filter */
            list = this.searchStr == '' ? list : this.searchFilter(list);

            /* Expose source properties */
            list = [...list.map(l => {
                /* Change source_id to source property */
                l.source = l?.source ?? l?.source_id;

                /* Change author_id to author property */
                l.author = l?.author ?? l?.author_id;

                /* Expose created date */
                l.source_date_created = l?.s_date;

                return l
            })]

            // /* Sort by latest */
            list = [...this.quoteSorting({data : list, sorting : this.filterBy.sortBy, })]
          
            /* Remove approved quote and story */
            list = list.filter(l => !l.publish_status)

            /* Remove rejected quote and story  */
            list = list.filter(l => l.visibility);

            /*  Check if has file attachment  */
            list = list.map(l => {

                if(l.files && l.files.length > 0) {
                    l.attachment = _.head(l.files.sort((a, b) => b.id - a.id));
                } else {
                    l.attachment = null; // default
                }

                return l
            })

            /* Add approval plug */
            list = list.map(l => {
                l.isApproval = true;

                return l;
            })

            /* Default */
            return list
        }
    },
    methods: {
        /**
         * Get combined approval list of quote and story
         */
        async getCombinedApproval() {
            const url = `/api/story/combineforapproval`
            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback : (res) => {
                    if(res) {
                        this.combinedApprovalList = res;

                         setTimeout(() => {
                        /* Disable laoder */
                        this.disableLoading({callback : () => {

                            /* check for text overflow */
                            this.textOverFlow('.quoteitem .qoutebody');
                        }});
                    }, 100);
                    }
                }
            })


            if(res) {
                this.combinedApprovalList = res;
            }

            setTimeout(() => {
                /* Disable laoder */
                this.disableLoading({callback : () => {

                /* check for text overflow */
                this.textOverFlow('.quoteitem .qoutebody');
            }});
          }, 100);
        },
        /* Rject quote / story */
        rejectRequestFromList(item){
            this.rejectPublish({params : item, callback : () => {
                /* Reject story request */
                if(item.story) {
                    this.storyList = [...this.storyList.filter(s => s.id != item.id && item.story)];

                    return;
                }
                /* Quote reject request */
                this.quoteList = [...this.quoteList.filter(q => q.id != item.id && q.quote)];
            }})
        },
         /* Show attachment */
         showAttachment(item) {
            const url                = item.story ? item.attachment.storyfile_url : item.attachment.quotefile_url
                  this.attachmentUrl = `${process.env.VUE_APP_AWS_URL}${url}`;
            this.$bvModal.show('playerPop');            
        },  
       
        /* Check if has note or none */
        hasNote(item) {
            /* Story */
            if(item.story) {
                return item.story_notes ? true : false
            }

            /* Quote  */
            if(item.quote) {
                return item.quote_notes ? true : false
            }
        },
        /* Hide show note */
        toggleNote(item) {
            const showNote = item.id+`${item.story ? 'story' : 'quote'}`
            this.showNote = showNote == this.showNote ? null : showNote
        },
        /* Copy to clipboard */
        copyClipboard(item) {
            const content = item.quote ? item.quote : item.story
            console.log('content', content)

            // Copy the text inside the text field
            navigator.clipboard.writeText(content);

            this.copiedContent = item.id+`${item.story ? 'story' : 'quote'}`

            setTimeout(() => {
                this.copiedContent = null
            }, 2000);

        },
        /* Delete story / quote */
        deleteItem(item) {

            /* Remove from the list and hide modal */
            this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
                /* Remove quote */
                if(modalId == 'deleteQuote' && bvEvent.type == 'hide') {
                    this.quoteList = [...this.quoteList.filter(q => q.id != item.id)]

                    return
                }
                /* Remove story */
                if(modalId == 'deleteStory' && bvEvent.type == 'hide') {
                    this.storyList = [...this.storyList.filter(s => s.id != item.id)]
                }
            })


            /* Delete quote */
            if(item.quote) {

                /* Assign item id to confirmation modal */
                const confirmation = this.$refs.deleteQuote

                if(!confirmation) return
                confirmation.quoteID = item.id    
                
                
                this.$bvModal.show('deleteQuote')
                
                return
            }

            /* Delete Story */
            if(item.story) {
                const confirmatiion = this.$refs.deleteStory
                /* Assign story id to delete confirmation */
                if(!confirmatiion) return
                confirmatiion.storyID = item.id

                this.$bvModal.show('deleteStory')
            }
        },
        /* Resets list */
        resetList() {
            this.currQuotePage = null
            this.quoteList = []
            this.currStoryPage = null
            this.storyList = []
        },
        /* Search filter */
        searchFilter(list) {
            return list.filter(l => {
                const searchBase = `${l?.s_content ?? ''} ${l.author ? `${l.author.first_name} ${l.author.last_name}` : ''} ${l.source ? l.source.source_name : ''}`.toLowerCase();

                return searchBase.match(this.searchStr.toLowerCase())
            })
        },
        /* Toggle fav  */
        toggleFav(item) {
            /* Story favorite */
            if(item.story) {
                /* Add / remove story to favorite */
                if(item.favorited_by && item.favorited_by.length > 0) {
                    this.removeFavoriteStory({
                        params : {
                            story_id : item.id,
                        },
                        callback : () => {
                            /* Emptied out  favorited_by to remove marking*/
                            this.storyList = [...this.storyList.map(s => {
                                if(s.id == item.id) {
                                    s.favorited_by = []
                                }

                                return s
                            })]

                            /* Update list  */
                            if(this.$route.name != 'favorites') return
                            this.storyList = [...this.storyList.filter(fs => fs.favorited_by.length > 0)]

                        }
                    })
                } else {
                    this.addFavoriteStory({
                        params : {
                            story_id : item.id,
                        },
                        callback : (data) => {
                            /* Add data to favorited_by to set the marker*/
                            this.storyList = [...this.storyList.map(s => {
                                if(s.id == item.id) {
                                    s.favorited_by = [data]
                                }

                                return s
                            })]
                        }
                    })
                }

             
                return;
            }

            /* Quote favorite */
            /* add and remove to favorite */
            if (item.favorited_by && item.favorited_by.length > 0) {

              this.removeFavorite({
                  params: {
                      quote_id: item.id
                  },
                  callback : () => {
                    /* Set favorited_by empty */
                    this.quoteList = [...this.quoteList.map(q => {

                        if(q.id == item.id) {
                            q.favorited_by = []
                        }
                            

                    return q
                    })]

                    /* Update list  */
                    if(this.$route.name != 'favorites') return
                    this.quoteList = [...this.quoteList.filter(fq => fq.favorited_by.length > 0)]
                  }
              })
            } else {
              this.addFavorite({
                    params: {
                        quote_id: item.id
                    },
                    callback : (data) => {

                        /* update quote favorited_byu  */
                        this.quoteList = [...this.quoteList.map(q => {
                            if(q.id == item.id) {
                                q.favorited_by = [...q.favorited_by, data]
                            }
                            
                            return q
                        })]
                    }
                })
            }

         
        },
        /**
         * Swipe listener
         */
        showactions(e, id, options) {
            console.log(e, id)

            if (e.directions.left) {
                const showid = `${id}${options.type}`
                this.activeaction = this.activeaction == showid ? null : showid;
                
            }

        },
        /* Get stories and quote */
        async getStoriesAndQuote({isNext = false, callback = () => {}} = {}) {
            const approvalQuotesParams = {
                params : {
                    visibility: 'visible',
                    page      : isNext && this.currQuotePage && this.currQuotePage?.links && this.currQuotePage?.links.next ? (this.currQuotePage?.meta.current_page + 1) : 1,
                    sorting   : this.filterBy.sortBy
                },
                callback  : (data) => {
                    this.currQuotePage = data;
                    this.quoteList = [...this.quoteList, ...data.data]
                }
            }

            const privateApprovalStoriesParams = {
                params : {
                    visibility: 'visible',
                    page      : isNext && this.currStoryPage && this.currStoryPage.links && this.currStoryPage.links.next ? (this.currStoryPage.meta.current_page + 1) : 1,
                    sorting   : this.filterBy.sortBy
                },
                callback: (data) => {
                    this.currStoryPage = data
                    this.storyList = [...this.storyList, ...data.data]
                }
            }


            if(isNext) {
                /* Get quotes */
                if(this.currQuotePage && this.currQuotePage?.links && this.currQuotePage?.links?.next) {
                    await this.getApprovalList(approvalQuotesParams);
                    // await this.getApprovalList(approvalQuotesParams);
                }

                /* Get stories */
                if(this.currStoryPage && this.currStoryPage.links && this.currStoryPage.links.next) {
                    await this.getApprovalStoryList(privateApprovalStoriesParams);
                }
            } else {
                /* Get quotes */
                await this.getApprovalList(approvalQuotesParams);
                // await this.getApprovalList(approvalQuotesParams);

                /* Get stories */
                await this.getApprovalStoryList(privateApprovalStoriesParams);
            }


          setTimeout(() => {
              /* Disable laoder */
            this.disableLoading({callback : () => {

                /* check for text overflow */
                this.textOverFlow('.quoteitem .qoutebody');

                /* callback */
                callback();
            }});
          }, 100);

        }
    },
    beforeMount() {
        /* Get stories and quotes */
        // this.getStoriesAndQuote({callback : () => {
        //     if(this.mergedQuoteStory.length < 10) {
        //         this.getStoriesAndQuote({isNext : true});
        //     }
        // }})

        // this.getCombinedApproval();
        CombinedSnipetsApis.getForApprovalSnippets({callback : data => {
            console.log('datadatadatadata', data)

            this.snippetList = [...data];

            this.$emit('update:totalApprovalCount', data.length);

              /* Disable laoder */
            this.disableLoading({callback : () => {

                /* check for text overflow */
                this.textOverFlow('.quoteitem .qoutebody');
            }});
        }})

        
    }
}
</script>

<style lang="scss">
@import "@/scss/_colors.scss";
@import "@/scss/_mixins.scss";

.publicApprovalWrap {
    .header {
        svg {
            width: 18px;
            height: 18px;
        }

        .gear {
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    .newQuoteOpt {
        box-shadow: 0 4px 8px $lightgray10;

        .newQuoteOptItem {
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    .filterPopWrap {
        position: absolute;
        top: 70px;
        background-color: #fff;
        width: 100%;
        padding: 30px;
        box-shadow: 0 4px 8px $lightgray10;

        legend {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 15px;
            padding-bottom: 0;
        }

        .custom-control-label {
            font-size: 15px;
        }
    }

    .approvalBody {
        padding-bottom: 120px;
        overflow: hidden auto;
        min-height: 100vh;
        height: auto;

        .quotelist {
            margin-top: 15px;
        }

        h1 {
            font-size: 20px;
            margin-bottom: 20px;
        }

        .quotemainactions {
            @include screen-up(xl) {
                display: flex !important;
            }

        }

        // .quoteitem {
        //     background-color: #fff;
        //     border-radius   : 20px;
        //     margin-bottom   : 15px;
        //     display         : flex;
        //     overflow        : hidden;
        //     position        : relative;
        //     min-height      : 208px;

        //     &.activequote {
        //         width: 130vw;
        //         flex : 0 0 100vw;
        //         left : calc(-30vw - 30px);

        //         @include screen-up(md) {
        //             left: calc(-30vw - 75px);
        //         }

        //     }

        //     .quoteicon {
        //         &.book {
        //             svg {
        //                 color: $blue2;
        //             }
        //         }
        //     }

        //     .quotecontent {
        //         padding: 15px;
        //         width: 100%;
        //     }

        //     .quotemainactions {
        //         display: flex;
        //         align-items: center;

        //         @include screen-up(xl) {
        //             display: flex !important;
        //         }

        //         a {
        //             width          : 70px;
        //             display        : flex;
        //             align-items    : center;
        //             justify-content: center;
        //             height         : 100%;

        //             .quoteicon {
        //                 svg {
        //                     width: 18px;
        //                     height: 18px;
        //                 }
        //             }

        //             &.editbtn {
        //                 background-color: $blue3;
        //                 color: $blue2 !important;
        //             }

        //             &.deletebtn {
        //                 background-color: $red2;
        //                 color: $red !important;
        //             }
        //         }
        //     }

        //     .quoteicon {
        //         &.quote {
        //             color: $orange;
        //         }

        //         svg {
        //             width: 15px;
        //             height: 15px;
        //         }
        //     }

        //     .qoutebody {
        //         font-size         : 15px;
        //         margin-bottom     : 15px;
        //         display           : -webkit-box;
        //         width             : 100%;
        //         -webkit-line-clamp: 4;
        //         -webkit-box-orient: vertical;
        //         overflow          : hidden;
        //     }

        //     .quoteheader {
        //         margin-bottom: 10px;

        //         .quoteicon {
        //             display: block;
        //         }

        //         .quoteaction {
        //             display        : flex;
        //             justify-content: flex-end;

        //             a {
        //                 margin-left: 10px;
        //                 color: darken($lightgray, 20);

        //                 &.active {
        //                     svg {
        //                         color: $orange !important;
        //                     }
        //                 }
        //             }

        //         }
        //     }

        //     .quotefooter {
        //         .quoteauthor {
        //             display: flex;

        //             .authorpic {
        //                 margin-right: 10px;
        //                 padding-top: 2px;

        //                 svg {
        //                     width: 20px;
        //                     height: 20px;
        //                     color: $black;
        //                 }

        //                 img {
        //                     width        : 20px;
        //                     height       : 20px;
        //                     border-radius: 100%;
        //                     object-fit   : cover;
        //                 }
        //             }

        //         }

        //         .authorinfo {
        //             .authorname {
        //                 font-size: 15px;
        //                 font-weight: 600;
        //             }

        //             .quotemeta,
        //             .quotetags {
        //                 padding-top: 0;
        //                 color: $lightgray8;
        //                 font-size: 14px;
        //             }

        //             .quotetags {
        //                 margin-top: 5px;
        //                 display: flex;
        //                 flex-wrap: wrap;

        //                 span {
        //                     background-color: $lightgray7;
        //                     margin-right: 5px;
        //                     margin-bottom: 5px;
        //                     padding: 3px 10px;
        //                     font-size: 12px;
        //                     font-weight: 600;
        //                     color: $lightgray9;
        //                     border-radius: 4px;
        //                     display: block;
        //                 }
        //             }
        //         }

        //         .notesWrap {
        //             color    : $lightgray8;
        //             font-size: 14px;
        //         }

        //         .quoteaction {
        //             color          : darken($color: $lightgray, $amount: 20);
        //             display        : flex;
        //             height         : 100%;
        //             justify-content: flex-end;

        //             .quoteicon {
        //                 justify-content: flex-end;
        //                 margin-bottom  : 5px;
        //             }

        //            .quoteactionitem {
        //                 display    : flex;
        //                 align-items: flex-end;
        //                 color      : $lightgray8;

        //                 &.active {
        //                     color : $orange !important;
        //                 }
        //            }

        //             svg {
        //                 color : inherit;
        //                 width : 17px;
        //                 height: 17px;
        //             }
        //         }
        //     }
        // }
    }
}
</style>
