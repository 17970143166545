/**
 * Snipets Apis
 * combined api calls from all snipets listing
 * like quote, story, poem, etc..
 * @namespace CombinedSnipetsApis
 */

/* Plugins */
import  axios from 'axios';
import _ from 'lodash';

/* Utils */
import {addUpdateCollection, getCollections, deleteCollection, getCollectionsBy} from '@/manie-utils/idb/localbase';
import StringParse from '@/manie-utils/stringParsing';
// import Helper from '@/helpers/helpers';


/* Models */
// import QuotesModel from '../models/quotesModel';
import SourceModel from '../models/sourcesModels';
import ContainerModel from '../models/containerModel';
import SnippetsModel from '../models/snippetsModel';
import AuthorModel from '../models/authorModel';
import TagModel from '../models/tagModel';
import FilesModel from '../models/filesModel';

/* Resources */
import SnippetResource from '../resources/snippetResource';
import moment from 'moment-timezone';

export default class CombinedSnipetsApis {

    /**
     * Get total snippet of the user
     * @param {Object} user
     * @param {Function} callback
     */
    static async userTotalSnippet({user = {}, callback = () => {}} = {}) {

        console.log('get user total snippet', user)

        if(!user) return;
        let published = [];
        let owned     = []

        /* Admin */
        if(user?.is_admin) {

            await getCollectionsBy({collectionName : 'snippets', indexName : 'publish_status', only : 1, limit : 10000, callback : (data) => {
                published = [...published, ...data];
            }})

            await getCollectionsBy({collectionName : 'snippets', indexName : 'created_by', only : user?.id, limit : 10000, callback : (data) => {
                owned = [...owned, ...data];
            }})

            const combined = [...published, ...owned].filter((l, i, a) => a.findIndex(lf => lf.id == l.id) == i); //remove duplicate

            callback(combined.length);
            return ;
        }

        /* Normal User */
        await getCollectionsBy({collectionName : 'snippets', indexName : 'created_by', only : user?.id, limit : 10000, callback : (data) => {
            owned = [...owned, ...data];
        }})

        const combined = [...owned].filter((l, i, a) => a.findIndex(lf => lf.id == l.id) == i); //remove duplicate
        callback(combined.length);

    }

    /**
     * Admin lock snippet
     * @param {Number} snippet_id
     */
    static async lockSnippet({snippet_id} = {}) {
        const url = `/api/snippet/lock/${snippet_id}`;
        await axios.patch(url).catch(err => err);
    }

    /**
     * Delete snippets
     * @param {Number} snippet_id
     * @param {Function} callback
     */
    static async deleteSnippet({snippet_id, callback = () => {}} = {}) {
        

        const url = `/api/snippet/${snippet_id}`;
        await axios.delete(url).catch(err => err);

        console.log('delete snippets', snippet_id)

        await deleteCollection({name : 'snippets', filter: {id : parseInt(snippet_id)}});

        callback();
    }
    
    /**
     * Sync deleted snippets
     * 
     */
    static async syncDeletedSnippets() {
        const url = `/api/snippet/listarchived`;
        const res = await axios.get(url).catch(err =>err);

        if(res?.data) {
            const list = res.data.data;
            console.log('syncDeletedSnippets', list)
            list.forEach(l => deleteCollection({name : 'snippets', filter : {id : l.id}}))
        }
    }

    /**
     * Get Tag Snippets
     * @param {Number} tag_id
     * @param {Function} callback
     */

    static async getTagSnippets({tag_id, callback = () => {}} = {}) {
        const callbackRes = async () => {
            const list = await getCollections({name : "snippet_tags", filter : {key : 'tag_id', value : parseInt(tag_id)}});

            let snippets = [];

            for (let index = 0; index < list.length; index++) {
                const tag = list[index];

                snippets = [...snippets, await SnippetResource.getSnippet(tag.snippet_id)];
                
            }

            callback(snippets);
        }

        callbackRes();
        

        const url = `/api/snippet/tag/bytag`;
        const res = await axios.get(url, {params : {tag_id}}).catch(err => err);

        if(res?.data) {
            const list = [...res?.data?.data]
            const snippets = [...list.filter((q, i, a) => a.findIndex(qf => qf.id == q.id) == i)];
            
            this.storeSnippetAndMetadasToIndexDB(snippets);

            callbackRes();
        }
    }

    /**
     * Get source snippets
     * @param {Number} source_id
     * @param {Function} callback
     */
    static async getSourceSnippets({source_id, callback = () => {}}) {

        const callbackRes = async () => {
            const list = await getCollections({name : "snippets", filter : {key : 'source_id', value : parseInt(source_id)}});


            let snippets = [];

            for (let index = 0; index < list.length; index++) {
                const snippet = list[index];
                snippets = [...snippets, await SnippetResource.getSnippet(snippet.id)]
            }

            callback(snippets)
        }

        callbackRes();


        const url  = `/api/snippet/fromsource/${source_id}`;
        const res = await axios.get(url, {params : {sorting : 'desc'}}).catch(err => err);
        if(res?.data) {
            const list = [...res?.data?.data]
            const snippets = [...list.filter((q, i, a) => a.findIndex(qf => qf.id == q.id) == i)];
            
            this.storeSnippetAndMetadasToIndexDB(snippets);

            callbackRes();
        }
    }

    /**
     * Get Authors snippets
     * @param {Number} author_id
     * @param {Function} callback
     */

    static async getAuthorSnippets({author_id, callback = () => {}} = {}) {

        const callbackRes = async () => {
            const snippetList = await getCollections({name : 'snippet_authors', filter : {key : 'author_id', value : parseInt(author_id)}});
            let snippets = []
            for (let index = 0; index < snippetList.length; index++) {
                const authorSnippet = snippetList[index];
                snippets = [...snippets, await SnippetResource.getSnippet(authorSnippet.snippet_id)];
            }

            callback(snippets);
        }

        callbackRes();
        

        const url = `/api/snippet/author/byauthor`
        const res = await axios.get(url, {params : {author_id}}).catch(err => err);

        if(res?.data) {
            const list = [...res?.data?.data]
            const snippets = [...list.filter((q, i, a) => a.findIndex(qf => qf.id == q.id) == i)];
            
            this.storeSnippetAndMetadasToIndexDB(snippets);

            callbackRes();

        }
    }

    /** 
     * Delete favorited snippet
     * @param {Number} snippet_id
     * @param {Number} user_id
     * @param {Function} callback
    */

    static async removeFavSnippet({snippet_id, user_id, callback = () => {}} = {}) {
        const url = `/api/snippet/favorite`;
        await axios.delete(url, {params : {snippet_id}}).catch(err => err);

        console.log('remove favorite', `${snippet_id}_${user_id}`)

        /* Remove from localdb */
        await deleteCollection({name : 'snippet_fav', filter: {id : `${snippet_id}_${user_id}`}})

        callback()
    }

    /**
     * Get fav snippets
     * @param {Object} params
     * @param {Function} callback
     */
    static async getFavSnippets({user_id = null, callback = () => {}}) {


        const callbackRes = async () => {
            const favList = await getCollections({name : 'snippet_fav', filter : {key : 'user_id', value : user_id}});

            if(!favList) {
                callback([])
                return;
            }

            
            let snippets = []

            for (let index = 0; index < favList.length; index++) {
                const fav = favList[index];

                snippets = [...snippets, await SnippetResource.getSnippet(fav.snippet_id)];
                
            }

            console.log(snippets, 'favList', favList)


            /* Remove undefined snippets */
            // snippets = [...snippets.filter(s => s?.authors.length > 0)];


            callback(snippets);
        }

        callbackRes();


        const url = `/api/snippet/favorite`;

        const res = await axios.get(url).catch(err => err);

        if(res?.data) {
            const list = [...res?.data?.data]
            const snippets = [...list.filter((q, i, a) => a.findIndex(qf => qf.id == q.id) == i)]; // remove duplicate
            
            this.storeSnippetAndMetadasToIndexDB(snippets);
            
            await this.cleanUpFavoritedSnippets(snippets);

            callbackRes();
        }
    }

    /**
     * Store snippets and other metadata to indexdb
     * @param {Array} snippets
     */
    static storeSnippetAndMetadasToIndexDB(snippets) {
          
             /* Add data to local db */
             this.addSnippetsToIndexDB(snippets);
 
              /* Sources */
             this.addSnippetSourcesToIndexDB(snippets);
 
             /* Authors */
             this.addSnippetAuthorsToIndexDB(snippets);
             
             /* Tags */
             this.addSnippetTagsToIndexDB(snippets);
             
 
             /*  Files */
             this.addSnippetFilesToIndexDB(snippets);

             /* Favorited */
             this.addSnippetFavToIndexDB(snippets);
    }

    /**
     * Add snippet to favorite
     * @param {Number} snippet_id
     * @param {Number} user_id
     * @param {Function} callback
     */
    static async addSnippetToFavorite({snippet_id, user_id, callback = () => {}} = {}) {
        const url = `/api/snippet/favorite`
        const res = await axios.post(url, {snippet_id}).catch(err => err);

        if(res?.data) {
            addUpdateCollection({name : 'snippet_fav', data : {id : `${snippet_id}_${user_id}`,snippet_id, user_id}})

            callback()
        }
    }

    /**
     * Publish a snippet
     * @param {Number} snippet_id
     * @param {Boolean} publish
     * @param {Function} callback
     */
    static async publishSnippet({snippet_id, publish = true, callback = () => {}} = {}) {
        const url = `/api/snippet/publish/${snippet_id}`;

        const res = await axios.patch(url, {publish}).catch(err => err);

        if(res?.data) {
            this.addSnippetsToIndexDB([res?.data?.data]);

            callback()
        }
    }

    /**
     * Get for approval snippet list
     * @param {Object} params
     * @param {Function} callback
     */

    static async getForApprovalSnippets({params = {}, callback = () => {}} = {}) {
        const callbackRes = async () => {
            // let list = await getCollections({name : 'snippets', filter : {key : 'visibility', value : 1}}) ?? [];
            let list = [];

            await getCollectionsBy({collectionName : 'snippets', only : 1, indexName : 'visibility', limit : 9999 , callback : data => list = [...list, ...data]});

            list = [...list.filter(l => l.publish_status == false)]
            
            let approvalList = []

            for (let index = 0; index < list.length; index++) {
                const snippet = list[index];
                approvalList = [...approvalList, {...await SnippetResource.getSnippet(snippet.id)}];
            }

            callback(approvalList);
        }

        callbackRes();

        const url = `/api/snippet/forapproval`;

        const res = await axios.get(url, {params}).catch(err => err);

        if(res?.data) {
            const list = [...res?.data?.data]
            const snippets = [...list.filter((q, i, a) => a.findIndex(qf => qf.id == q.id) == i)];
            this.storeSnippetAndMetadasToIndexDB(snippets)


            setTimeout(() => {
                callbackRes();
            }, 200);
        }
    }

    /**
     * Search snippet
     * @param {Object} params
     * @param {Function} callback
     */
    static async searchSnippet({params = {searchtext : ''}, callback = () => {}}) {
        const returnLocal = async () => {
            const localList = await getCollections({name : 'snippets'}) ?? [];
            const searchRes = localList.filter( s => {
                const content = s.s_content.toLowerCase();
                console.log(StringParse)

                return content.includes(params.searchtext.toLowerCase());
                // const content = StringParse.removeSpecialCharacter(s.s_content.toLowerCase());
                // console.log(content.includes(StringParse.removeSpecialCharacter(params.searchtext.toLowerCase())),'content', content)
                // console.log('searchString', StringParse.removeSpecialCharacter(params.searchtext.toLowerCase()))

                // return content.includes(StringParse.removeSpecialCharacter(params.searchtext.toLowerCase()));
            })

            let newList = []

            for (let index = 0; index < searchRes.length; index++) {
                const snippet = searchRes[index];

                newList = [...newList, {...await SnippetResource.getSnippet(snippet.id)}];
                
            }

            console.log(params.searchtext, "snipet search", searchRes)

            callback(newList);
        }

        returnLocal();

        // const url = `/api/snippet/search`
        // const res = await axios.post(url, params).catch(err => err);

        // if(res?.data) {
        //     const list = [...res?.data?.data];
            
        //     /* Remove duplicates */
        //     const snippets = [...list.filter((q, i, a) => a.findIndex(qf => qf.id == q.id) == i)];
        //     this.storeSnippetAndMetadasToIndexDB(snippets)



        //     returnLocal();
        // }
        
    }
    /**
     * Remove author
     * @param {Object} params
     * @param {Function} callback
     */
    static async removeSnippetAuthor({params = {}, callback = () => {}} = {}) {
        deleteCollection({name : 'snippet_authors', filter : {id : `${params.snippet_id}_${params.author_id}`}})

        const url = `api/snippet/author/${params.snippet_id}`;
        await axios.delete(url, {params}).catch(err => err);

        callback();
    }
    /**
     * Update snippet
     * @param {Object} params
     * @param {Function} callback
     */
    static async updateSnippet({params = {}, callback =() => {}}) {
        const url = `/api/snippet/${params.id}`
        const res = await axios.patch(url, params).catch(err => err);

        if(res?.data) {
            addUpdateCollection({name : "snippets", data : SnippetsModel.schema(res?.data?.data)});

            callback()
        }
    }   
    /**
     * Get snippet
     * @param {Number} id
     * @param {Function} callback
     * @param {Function} networkCallback
     */
    static async getSnippet({id  = null, callback = () => {}, errorCallback = () => {}, networkCallback =() => {}} = {}) {
        const url = `/api/snippet/${id}`;

        const snippet = await SnippetResource.getSnippet(parseInt(id));
        
        if(snippet) callback(snippet);

        const res = await axios.get(url).catch(err => err);

        if(res?.data) {
            const snippetRes = {...res?.data?.data};

            snippetRes.source_id = snippetRes?.source_id ?? snippetRes?.source?.id ?? null;

            // await addUpdateCollection({name : 'snippets', data : SnippetsModel.schema(snippetRes ?? {})})
            this.storeSnippetAndMetadasToIndexDB([snippetRes]);

            const snippet = await SnippetResource.getSnippet(parseInt(id));
            callback(snippet);
            networkCallback(snippet)
        } else errorCallback(res)
    }
    /**
     * upload snippet file
     * @param {Object} params
     * @param {Function} callback
     */
    static async uploadSnippetFile({params = {}, callback = () => {}} = {}) {
        const url = `/api/snippet/file`;
        const res = await axios.post(url, params).catch(err => err);

        if(res?.data) {
            addUpdateCollection({name : 'files', data : FilesModel.schema(res?.data?.data)});

            callback(res?.data);
        }
    }
    /**
     * Add snipeet authors
     * @param {Object} params
     * @param {Function} callback
     */
    static async addSnippetAuthors({params = {}, callback = () => {}}) {
        const url = `/api/snippet/author/multiauthors`
        const res = await axios.post(url, params).catch(err => err);

        if(res?.data) {
            callback(res.data)
        }
    }
    /**
     * Remove snippet tags
     * @param {Object} params
     * @param {Function} callback
     */
    static async removeSnippetTags({params = {tags_arr : []}, callback = () => {}}) {
        const url = `/api/snippet/tag/removetags/${params.snippet_id}`;
        params.tags_arr.forEach(t => {
            deleteCollection({name : 'snippet_tags', filter : {id : `${params.snippet_id}_${t}`}})
        })

        await axios.delete(url).catch(err => console.log(err));

        callback();
    }

    
    /**
     * Add snippet tags
     * @param {Object} params
     * @param {Function} callback
     */
    static async addSnippetTags({params = {}, callback = () => {}}) {
        const url = `/api/snippet/tag/multitags`
        const res = await axios.post(url, params).catch(err => err);

        if(res?.data) {
            const tags = res.data;
            tags.forEach(s => {
                addUpdateCollection({name : 'tags', data : TagModel.schema(s) })
                addUpdateCollection({name : 'snippet_tags', data : {...s.pivot, id : `${s.pivot?.snippet_id}_${s.pivot?.tag_id}`} })
            });

            callback(res.data)
        }
    }
      /**
     * Create new snippets
     * @param {Object} params
     * @param {Function} callback
     */

      static async createNewSnippet({params = {}, callback = () => {}} = {}) {
        const url = `/api/snippet`
        const res = await axios.post(url, params).catch(err => err);

        if(res?.data) {
            this.addSnippetsToIndexDB([res.data.data])

            callback(res.data);
        }
    }

    /**
     * Add snippets to indexdb
     * @param {Array} snippets
     */
    static async addSnippetsToIndexDB(snippets) {
        snippets.map(s => {
            /* Get source */
            s.source_id = s?.source?.id ?? s?.source_id?.id ?? s?.source ?? s?.source_id ?? null;
            /* Get author */
            s.author_id = s?.author?.id ?? s?.author_id?.id ?? s?.author ?? s?.author_id ?? null;
            /* Get created by */
            s.created_by = s?.creator?.id ?? null;
            /* Parse publish_status */
            s.publish_status  = s.publish_status ? 1 : 0;

            return s;
        }).forEach(s => addUpdateCollection({name : 'snippets', data : SnippetsModel.schema(s)}))
    }


    /**
     * Add snippet favorited to indexdb
     * @param {Array} snippets
     */
    static async addSnippetFavToIndexDB(snippets) {
            const favorites = _.flatten([...snippets.map(s => s.favored_by)]).map(s => s.pivot);

            favorites.forEach(s => addUpdateCollection({name : "snippet_fav", data : {id : `${s.snippet_id}_${s.user_id}`, ...s}}))
    }

    /**
     * Clean up favorite list for those deleted data from other device
     * @param {Array} snippets
     */
    static async cleanUpFavoritedSnippets(snippets) {
        if(snippets.length == 0) return;

        const favorites = _.flatten([...snippets.map(s => s.favored_by)]).map(s => s.pivot);
          /* Remove unfavorited snippets */
          if(favorites.length == 0) {
            deleteCollection({name : 'snippet_fav'});
            return;
        }

        const fav = _.head(favorites);
        const favList = await getCollections({name : 'snippet_fav', filter : {key : 'user_id', value : fav.user_id}});
        const listDiff = _.differenceBy(favList, favorites, 'snippet_id');

        // console.log('clean up fav', listDiff)


         listDiff.forEach(f => deleteCollection({name : 'snippet_fav', filter : {id : `${f.snippet_id}_${f.user_id}`}}));
    }


    /**
     * Add snippet sources and containers to indexdb
     * @param {Array} snippets
     */
    static addSnippetSourcesToIndexDB(snippets) {
         /* Remove source duplicatse */
         const sources = [...snippets.map(s => s.source ?? s.source_id)].filter(s => s);

         const listS = [...sources.filter((s, i, a) =>  a.findIndex(sf => sf.id == s.id) == i)];
        //  console.log('quote sources', listS)

         listS.map(s => {
             const container = _.head(s.containers);
             s.container_id = container?.id ?? null;
             return s;
         }).forEach(s => addUpdateCollection({name : 'sources', data : SourceModel.schema(s) }));



        /* Containers */
        const containers = _.flatten([...sources.map(s => s.containers)]);
        containers.forEach(c => addUpdateCollection({name : 'containers', data : ContainerModel.schema(c)}))


    }

    /**
     * Add snippet authors to indexdb
     * @param {Array} snippets
     * 
     */
    static async addSnippetAuthorsToIndexDB(snippets) {

        /* Authors */
        const authors = _.flatten(snippets.map(s => [...s?.author ?? [], ...s?.authors ?? []]))
        authors.forEach(async s => {
            addUpdateCollection({name : 'authors', data : AuthorModel.schema(s) })
            addUpdateCollection({name : 'snippet_authors', data : {...s.pivot, id : `${s.pivot?.snippet_id}_${s.pivot?.author_id}`} })
            /* Clean up snippet author
            Snippet should only have 1 author */
    
            const localRecord = await getCollections({name : 'snippet_authors', filter : {key : 'snippet_id', value : parseInt(s.pivot?.snippet_id) }})
    
            const diff = localRecord.filter(lr => !authors.find( as => `${as.pivot?.snippet_id}_${as.pivot?.author_id}` == `${lr.snippet_id}_${lr.author_id}`))
    
            diff.forEach(d => deleteCollection({name : "snippet_authors", filter : {id : `${d?.snippet_id}_${d?.author_id}`}}))
        });


    }


    /**
     * Add snippet tags to indexdb
     * @param {Array} Snippets
     */

    static  addSnippetTagsToIndexDB(snippets) {
           /* Tags */
           const tags = _.flatten(snippets.map(s => [...s?.tags ?? []]))
           tags.forEach( async s => {
               addUpdateCollection({name : 'tags', data : TagModel.schema(s) })
               addUpdateCollection({name : 'snippet_tags', data : {...s.pivot, id : `${s.pivot?.snippet_id}_${s.pivot?.tag_id}`} })

               
            });

            

            /* clean up up all snippet tags tag was removed in other device */
            snippets.forEach(async snippet => {
                const snippetTags = await getCollections({name : 'snippet_tags', filter : {key : 'snippet_id', value : parseInt(snippet.id) }})

                const newTags = snippet.tags || []

                const diff = snippetTags.filter(st => !newTags.find(nt => nt.id == st.tag_id))

                diff.forEach(d => deleteCollection({name : 'snippet_tags', filter : {id : d.id}}))
            })
    }

    /**
     * Add snippet files to indexdb
     * @param {Array} snippets
     */

    static addSnippetFilesToIndexDB(snippets) {
            /* Files */
            const files = _.flatten([...snippets.map(s => s.files)]);
            files.forEach(s => addUpdateCollection({name : 'files', data : FilesModel.schema(s)}))
            
    }

    /**
     * Get listing
     * @param {Function} callback
     */
    static async getList({callback = () => {}, abortCallback =() => {}} = {}) {
        let notRecord = false

        /* Return local data */
        const resCallback = async (returnEmpty = false) => {
            // let startTime = new Date();
            
            console.log('getCollectionsBy', getCollectionsBy, moment);

            // let currentCount    = 0;
            // let batchedSnippets = [];
            // let fetching        = false;

            // const FetchingSnippets = async () => {
            //     if(fetching) return;
            //           fetching = true;
            //     const list     = [...batchedSnippets[currentCount]];
            //     currentCount++


            //     const localList = list ?? [];
            //     let collections = []
            //     for (let index = 0; index < localList.length; index++) {
            //         const collection = localList[index];
            //         const snippet = await SnippetResource.getSnippet(collection.id)
    
            //         collections = [...collections, {...snippet}]
            //     }
    
            //     if(localList?.length > 0) {
            //         callback(collections);
                   
            //     } 
    
            //     if(returnEmpty && localList?.length == 0) {
            //         callback(collections)
            //     }

            //     fetching = false;
            //     /* Return next batch */
            //     if(currentCount < batchedSnippets.length) FetchingSnippets();

            //     console.log(batchedSnippets, 'snippet proccesing', currentCount)
            // }


            await getCollectionsBy({collectionName : 'snippets', indexName : 's_date', startRange : '0000-01-01', endRange : moment().add(20, 'years').format('YYYY-MM-DD'), callback : async data => {
                // batchedSnippets = [...batchedSnippets, data];

                const localList = data ?? [];
                let collections = []

                if(localList?.length == 0) notRecord = true

                for (let index = 0; index < localList.length; index++) {
                    const collection = localList[index];
                    const snippet = await SnippetResource.getSnippet(collection.id)
    
                    collections = [...collections, {...snippet}]
                }

                console.log(returnEmpty, 'collections snippets', collections, localList)
    
                if(localList?.length > 0) {
                    callback(collections);
                } 
    
                if(returnEmpty && localList?.length == 0) {
                    callback(collections)
                }
            }});

            // FetchingSnippets();

            // const localList = await getCollections({name : 'snippets'}) ?? [];
            // let collections = []
            // for (let index = 0; index < localList.length; index++) {
            //     const collection = localList[index];
            //     const snippet = await SnippetResource.getSnippet(collection.id)

            //     collections = [...collections, {...snippet}]
            // }

            // if(localList?.length > 0) {
            //     callback(collections);
            // } 

            // if(returnEmpty && localList?.length == 0) {
            //     callback(collections)
            // }
        }

        resCallback(true);

        const callNetworkSnippetsList = async (page = 1) => {

            const controller = new AbortController();
            const signal = controller.signal;

            abortCallback(controller)
           

            /* Process network response */
            const url = `/api/snippet`
            const res = await axios.get(url, {params : {page, sorting : 'desc'}}, {signal}).catch(err => err);
    
            if(res?.data) {
                const list = [...res.data?.data ?? []];
                
    
                /* Remove duplicates */
                const snippets = [...list.filter((q, i, a) => a.findIndex(qf => qf.id == q.id) == i)];
    
                this.storeSnippetAndMetadasToIndexDB(snippets);

                /* Return first set */
                if(notRecord) {
                    notRecord = false
                    /* Return updated data */
                    resCallback();
                }
                
    
              
    
                if(res?.data?.links?.next) {
                    const currentPage = res?.data?.meta.current_page + 1;
                    callNetworkSnippetsList(currentPage);
                } else {
                    /* Return updated data */
                    resCallback();
                }
            }
        }

        callNetworkSnippetsList();
        
    }

}