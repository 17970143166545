/**
 * Author Resource
 * @namespace AuthorResource
 */

/* Utils */
import {getCollection, getCollections} from '@/manie-utils/idb/localbase';

/* Resource */

export default class AuthorResource {
    /**
     * Return Author Object
     * @param {Number} author_id
     */
    static async getAuthor({author_id, user_id} = {}) {
        if(!author_id) return null;

        const author = await getCollection({name : 'authors', filter : {id : parseInt(author_id)}});
        if(!author) return null;


        /* check if has published snippets */
        const authorsSnippets = await getCollections({name : 'snippet_authors', filter : {key : 'author_id', value : parseInt(author_id)}})

        let snippets = [];

        for (let index = 0; index < authorsSnippets.length; index++) {
            const authorSnippet = authorsSnippets[index];
            snippets = [...snippets, await getCollection({name : 'snippets', filter : {id : parseInt(authorSnippet.snippet_id)}})]
        }

        /* Remove undefined snippets */
        snippets = [...snippets.filter(s => s)];

        const admin_lock = snippets.find(s => s.admin_lock) ? true : false;

        /* Check if used by the user */
        let is_used = false;
        if(user_id) is_used = snippets.find(s => s.created_by?.id == user_id || s.created_by == user_id) ? true : false
        console.log("Author resource", is_used, user_id)

        /* Merged properties */
        const additionalProperties  =  {
            admin_lock,
            is_used,
            author_birth_date_circa : author?.author_birth_date_circa ? true : false,
            author_death_date_circa : author?.author_death_date_circa ? true : false
        }


        return {...author, ...additionalProperties}
    }
}