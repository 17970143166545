/**
 * Container Resource
 * @namespace ContainerResource
 */

/* Utils */
import {getCollection, getCollectionsBy} from '@/manie-utils/idb/localbase';

/* Resources */
import SourceResource from './sourceResource';

export default class ContainerResource {
    /**
     * Return Container Object
     * @param {Number} container_id
     * @param {Number} user_id
     */
    static async getContainer({container_id, user_id} = {}) {
        
        const container = await getCollection({name : 'containers', filter : {id : container_id}});
        let sourcesList = []
        await getCollectionsBy({collectionName : 'sources', indexName : 'container_id', startRange : parseInt(container_id), endRange : parseInt(container_id), callback : data => sourcesList = [...sourcesList, ...data]})


        let sources = [];

        for (let index = 0; index < sourcesList.length; index++) {
            const source = sourcesList[index];
            sources = [...sources, await SourceResource.getSource({source_id : source.id, user_id})];
        }

        const admin_lock = sources.find(s => s.admin_lock) ? true : false;

        const is_used = sources.find(s => s.is_used) ? true : false;

        /* Merged properties */
        const additionalProperties  =  {
            sources,
            admin_lock,is_used,
            sc_date_circa : container.sc_date_circa ? true : false
        }


        return {...container, ...additionalProperties}
    }
}