import Helper from '@/helpers/helpers'
import axios from 'axios'

import { 
    getJobs, 
    deleteJob, 
    getCollection, 
    addUpdateCollection, 
    updateCollection, 
    deleteCollection,
    overridecollection,
} from '@/helpers/localbase'

export const apiCallsJob  = {
    methods : {
         /**
         * Api request's 
         * Jobs are from indexed db jobs collection
         */
         async apiCalls() {
            // console.log("JOBS")
            this.processing = true;
            
            const jobs = await getJobs();
            
            console.table(jobs)

            if(jobs && Array.isArray(jobs)) {
                for (let index = 0; index < jobs.length; index++) {
                    const job     = jobs[index];
                    const params  = job.params ? job.params : {};
                    const options = {
                        db    : {
                            getCollection, 
                            addUpdateCollection, 
                            updateCollection, 
                            deleteCollection,
                            overridecollection,
                        },
                        job     : job,
                        helper  : Helper,
                        router  : this.$router,
                        route   : this.$route,
                        store   : this.$store,
                        baseurl : process.env.VUE_APP_BASEAPI,
                        socketio: this.socketio
                    }
                    
                    /**
                     * Check if this job failed mutiple times
                     * if failed more than 5 times 
                     * remove from job list
                     * then  don't continue to process
                     */

                    const failed = this.failed.filter(f => f == job.url);

                    if(failed.length > 4) {
                        // remove from job list
                        deleteJob(job.url);

                        // remove from failed list
                        this.failed = this.failed.filter(f => f != job.url);

                        continue;
                    }
                    
                    /**
                     * Check what method is going the use on the request
                     * 
                     */
                    // console.log('is this job running')

                    switch (job.method) {


                        case 'GET':
                                await axios.get(job.url, {params : params})
                                .then((res) => {
                                    deleteJob(job.url);
                                    
                                    const callback = job.callback ? JSON.parse(job.callback, Helper.deserialize) : null;
                                    
                                    if(callback && typeof callback == 'function') {
                                        callback(res.data, options);
                                    }
                                }).catch((err) => {
                                    console.log('error job get api request', err);
                                    this.failed = [...this.failed, job.url]
                                });
                            break;
                        
                        case 'POST':
                                await axios.post(job.url, params).then((res) => {
                                    deleteJob(job.url);
                                    
                                    const callback = job.callback ? JSON.parse(job.callback, Helper.deserialize) : null;
                                    
                                    // console.log(typeof callback, 'callback', callback)
                                    
                                    if(callback && typeof callback == 'function') {
                                        callback(res.data, options);
                                    }
                                }).catch((err) => {
                                    this.failed = [...this.failed, job.url]
                                    console.log('error job post api request', err)
                                });
                            break;

                        case 'PATCH':
                                await axios.patch(job.url, params).then((res) => {
                                    deleteJob(job.url);
                                    
                                    const callback = job.callback ? JSON.parse(job.callback, Helper.deserialize) : null;
                                    
                                    if(callback && typeof callback == 'function') {
                                        callback(res.data, options);
                                    }
                                }).catch((err) => {
                                    this.failed = [...this.failed, job.url]
                                    console.log('error job post api request', err)
                                });
                            break;
                        
                        case 'PUT':
                                await axios.put(job.url, params).then((res) => {
                                    deleteJob(job.url);
                                    
                                    const callback = job.callback ? JSON.parse(job.callback, Helper.deserialize) : null;
                                    
                                    if(callback && typeof callback == 'function') {
                                        callback(res.data, options);
                                    }
                                }).catch(() => {
                                    console.log('error job put api request')
                                    this.failed = [...this.failed, job.url]
                                });
                            break;

                        case 'DELETE':
                                await axios.delete(job.url, params).then(() => {
                                    deleteJob(job.url);
                                }).catch(() => {
                                    console.log('error job delete api request')
                                    this.failed = [...this.failed, job.url]
                                });
                            break;
                    
                        default:
                            break;
                    }


                }
            }

            this.processing = false;
        }
    }
}