/**
 * Dom related classes
 * @namespace DOMParsing
 */

export default class DOMParsing {
    /**
     * Check all element is all scrolledTop
     * @param {Array} elements
     */
    static areAllElementsScrolledTop(elements) {
        return Array.from(elements).every(element => element.scrollTop === 0);
    }
      
}