/**
 * Indexed DB Configuration
 * 
 * Job db and Holes db are seprated for each managing
 */
import Helper from './helpers';
import { openDB} from 'idb';


/**
 * Indexeddb version and store names
 */

const version = 1;
const dbname  = 'QuotesDB';
const stores  = [
    {
        name   : 'quotes',
        indexes: [],
        keypath: 'id'
    },
    {
        name   : 'stories',
        indexes: [],
        keypath: 'id'
    }
]

/** 
 * @namespace IndexDB
 */

/**
 * Create indexeddb stractures
 * @memberof IndexDB
 * @function openDB
 * @param {String} dbname Name of the indexdb
 * @param {Number} version Current version
 */
const dbpromise = openDB(dbname, version, {
    upgrade(db) {
        stores.forEach(store => {
            /**
             * Check if store is existing or not 
             * if exist delete to create new update
             * create indexes
             */
            if(db.objectStoreNames.contains(store.name)) {
                console.log('delete store name')
                db.deleteObjectStore(store.name);
            }

            const objstore = db.createObjectStore(store.name, {keyPath : store.keypath});
            
            store.indexes.forEach(idx => {
                objstore.createIndex(idx, idx, { unique: false });
            })
        })
    },
});



/**
 * @memberof IndexDB
 * @param {String} storename // collection name 
 * @param {String} mode // readonly or readwrite
 */
const createtransaction = async (storename, mode) => {
    const tx =  (await dbpromise).transaction(storename, mode).objectStore(storename);

    return tx;
}

/**
 * Adding new job this will run through
* @memberof IndexDB
 * @param {String} url  api url
 * @param {String} method  request type (GET, POST, PUT, DELETE)
 * @param {String} params requrest body  
 * @param {String} callback function that will run after success call
 */
const newJob = ({
        url      = '',
        method   = 'get',
        params   = {},
        callback = function() {}
    }) => {
    /**
     * Check if job is already exist
     */ 

    addUpdateCollection({name : 'jobs', data : {
        url     : url,
        method  : method,
        params  : params,
        callback: JSON.stringify(callback, Helper.serialize)
    }})


    // holescron.collection('jobs')
    // .doc({url : url})
    // .get({ keys: true })
    // .then(job => {
    //     if(!job) {
    //         holescron.collection('jobs').add({
    //             url     : url,
    //             method  : method,
    //             params  : params,
    //             callback: JSON.stringify(callback, Helper.serialize)
    //         });
    //     }
    // })
}

/**
 * Get all jobs documents
 * @returns job collections
 */
const getJobs = () => {
    const jobs = new Promise((resolve) => {
        // holescron.collection('jobs')
        //     .get({ keys: true })
        //     .then(res => {
        //         resolve(res)
        //     })
        //     .catch(err => reject(err));

        const res = getCollections({name : 'jobs'})

        resolve(res)
    });

    return jobs;
}

/**
 * Get sigle document from job
 * @param {Object} filter @example {url : url}
 * @returns {Object}
 */
const getJob = (filter) => {
    const getjob = new Promise((resolve) => {
        const res = getCollection({name : 'jobs', filter : filter})

        resolve(res)
    })

    return getjob;
} 

/**
 * Delete a job from collection documents
 * @param {Object} filter 
 */
const deleteJob = (filter) => {
    // holescron.collection('jobs')
    //     .doc(filter)
    //     .delete()

    deleteCollection({name : 'jobs', filter : {id : filter} })
}

/**
 * Holes indexed db functions
 * @param {String} name // name of the collection to fetch
 * @param {Object} filter // (optional) property name  you want to filter and return
 * Filter properties will be {Key : <property name>, value : <value you want to filter>}
 * @returns {Array} 
 */
const getCollections = async ({name, filter = null}) => {
    

    let tx = await createtransaction(name, 'readonly');
    
    const res = await tx.getAll().catch((err) => err);

    if(!Array.isArray(res) && res.toString().indexOf('object stores was not found')) {
        return [];
    } else {

        if(filter) {
            // return res.filter(c => c[filter.keys] == filter.value);
            return res.filter(c => c[filter.key] == filter.value);
        } else {
            return res;
        }

    }


}
/**
 * 
 * @param {String} name collection name 
 * @param {Object} filter @example {id : user.id}
 * @returns {Object}
 */
const getCollection = async ({name, filter}) => {
    const tx =  await createtransaction(name, 'readonly')
    return tx.get(filter.id);

    // const collection = new Promise((resolve, reject) => {
    //     holesdb.collection(name)
    //         .doc(filter)
    //         .get({keys : true})
    //         .then(res => {
    //             resolve(res);
    //         })
    //         .catch(err => reject(err))
    // })

    // return collection
}

/**
 * Add or update collection
 * update first if the document exist
 * if faild it wil add 
 * @param {String} name 
 * @param {Object} data 
 * @returns return the updated data
 */
const addUpdateCollection = async ({name, data}) => {

    const store = await createtransaction(name, 'readwrite');

    /**
     * Add or update
     * @param {Object} data
     */
    const result = await store.put(data)

    return result

    // const collection = new Promise((resolve, reject) => {
    //     holesdb.collection(name)
    //     .doc(filter)
    //     .set(data)
    //     .then(res => {
    //         resolve(res);
    //     })
    //     .catch(() => {
    //         holesdb.collection(name)
    //             .add(data)
    //             .then(res => {
    //                 resolve(res)
    //             })
    //             .catch(err => {
    //                 reject(err);
    //             })
    //     })
    // })

    // return collection;
}

/**
 * Update document from collection
 * this same as add and update
 * were gonna change this later on
 */
const updateCollection = async ({name, data}) => {
    const result = await addUpdateCollection({name, data})
    return result;
    // const collection = new Promise((resolve, reject) => {
    //     holesdb.collection(name)
    //     .doc(filter)
    //     .set(data)
    //     .then(res => {
    //         resolve(res);
    //     })
    //     .catch((err) => {
    //         reject(err);
    //     })
    // })

    // return collection;
}

/**
 * Override collection
 */
const overridecollection = async ({name, data}) => {

    //clean documents and put new data
    const tx = await createtransaction(name, 'readwrite');
     await tx.clear();

    /**
     * Add new data
     */

    for (let index = 0; index < data.length; index++) {
        const d = data[index];

        await addUpdateCollection({name, data : d})
    }


    // return data;
    // const newpromise = new Promise((resolve, reject) => {
    //     holesdb.collection(name)
    //         .set(data)
    //         .then(res => {
    //             resolve(res)
    //         })
    //         .catch(err => {
    //             reject(err)
    //         })
    // })

    // return newpromise;
}

/**
 * Delete document from collection
 */
const deleteCollection = async ({name, filter}) => {
    const tx = await createtransaction(name, 'readwrite');
    if(filter) {
        return tx.delete(filter.id);

        // holesdb.collection(name)
        //     .doc(filter)
        //     .delete();
    } else {
        return tx.clear();
        // holesdb.collection(name).delete();
    }
}

/**
 * Clean up record
 * Match 2 records and get the difference then remove from indexed db
 * @param {Array} newrecord
 * @param {Array} oldrecord
 * @param {String} property // property to check
 * @param {String} collname // collection to clean
 */
 const cleanrecord = async (newrecord = [], oldrecord = [], property, collname) => {

    const properties = newrecord.map(n => n[property]);
    const trash      = oldrecord.filter(o => !properties.includes(o[property]));
    
    for (let index = 0; index < trash.length; index++) {
        const  t         = trash[index];
        let    filter    = {};
        filter[property] = t[property]

        deleteCollection({name : collname, filter : filter});
        
    }
}


export {
    newJob, 
    getJobs, 
    getJob, 
    deleteJob, 
    getCollections, 
    getCollection, 
    addUpdateCollection, 
    updateCollection, 
    deleteCollection,
    overridecollection,
    cleanrecord
}