/**
 * Files models
 * Object property that allow to be save in indexdb
 * @namespace FilesModel
 */

export default class FilesModel {
    /**
     * Destructuring object and only return decleared properties
     */
    static schema (object) {
        const  {
            id         = null,
            snippet_id = null,
            sf_url     = null,
            sf_name    = null,
            sf_size    = null,
            sf_ext     = null
            }          = object;



        return {
            id,
            snippet_id,
            sf_url,
            sf_name,
            sf_size,
            sf_ext,
        }

    }

}