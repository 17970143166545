/**
 * Get relative date or time using momentjs
 */

import moment from 'moment-timezone' 

export default function relativedate (date, format) {
    if (!date) return ''

    const theformat = format ? format : 'MM/DD/YYYY'

    const thedate = moment(date).tz('America/Denver')

    return thedate.calendar(null, {
        sameDay : '[Today]',
        nextDay : '[Tomorrow]',
        nextWeek: theformat,
        lastDay : '[Yesterday]',
        lastWeek: theformat,
        sameElse: theformat
    });

}