<template >

     <modal :hideCloseButton="true" :modalContentClass="`!pt-0 transition-all duration-500 ${expand ? 'fullScreen' : ''}`" modalBodyClass="overflow-clip min-h-[30dvh] h-full" :hideFooter="true" :show="url ? true : false" @close="() => ($emit('update:url', null, expand = false))">
            
            <div class="w-full flex justify-end mb-4 space-x-3">
                <button v-if="isImage(url)" @click="() => expand = !expand" class="text-gray ">
                    <quote-icon class="!w-5 !h-5 text-black dark:text-white" icon="expand" />
                </button>
                <button  @click="() => ($emit('update:url', null), expand = false)" class="text-gray ">
                    <quote-icon class="!w-5 !h-5 text-black dark:text-white" icon="x-mark" />
                </button>
            </div>

            <div  class="dark:!bg-gray-12 bg-white overflow-clip w-full h-full">
                
                <div  v-if="isImage(url)" class="zoomist-container">
                    <div class="zoomist-wrapper">
                        <div class="zoomist-image">
                            <img class="w-full h-full object-fit" :src="url" alt="">
                        </div>
                    </div>
                </div>
                
                <video 
                    class="max-h-[90vh] w-full"
                    controls
                    v-if="isVideo(url)"
                    :src="url"></video>

                <audio 
                    controls
                    v-else-if="isAudio(url)"
                    :src="url"></audio>
            </div>
            

    </modal>

</template>

<script>

/* Mixins */
import { utilsMixins } from '@/mixins/utilsMixins';

/* Plugins */
import Zoomist from 'zoomist'

export default {
    mixins: [utilsMixins],
    props : {
        url : {
            type   : String,
            default: () => ''
        },
        playerID : {
            type : String,
            default : () => null
        }
    },
    data() {
        return {
            expand : false,
            zoomist: null
        }
    },
    watch : {
        /**
         * Watch url changes and init zoomist
         */
        url(url) {
            if(url) this.initZoomist()
        }
    },
    methods : {
        /**
         * Init / destroy zoomist
         */
        initZoomist() {
            setTimeout(() => {
                if(this.isImage(this.url)) {

                    if(this.zoomist) {
                        this.zoomist.destroy()
                    }

                    /* Initialize zoom  */
                    this.zoomist = new Zoomist('.zoomist-container', {
                        // Optional parameters
                        maxScale: 4,
                        bounds: true,
                        // if you need slider
                        slider: false,
                        // if you need zoomer
                        zoomer: false
                    })
                }
            }, 1000);
        }
    },
    mounted() {
        /* Init zoomist */
        this.initZoomist()
    }
}

</script>

<style lang="scss">


.playerPopWrap {
    video, img {
        width : 100%;
        height: 100%;
    }

    img {
        object-fit: contain;
    }
}

</style>