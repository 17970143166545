
/* Helpers */
import Helper from "@/helpers/helpers";
// import { useQuery } from '@tanstack/vue-query'
/* Plugins */
import _ from 'lodash'

/** @namespace Cache */

export const cacheMixins = {
    data() {
      return  {
        requestController: []
      }
    },
    computed : {
      /* Abort requests */
      abortRequests() {
        return this.$store.state.abortRequest;
      },
      /* Cache Name */
      cacheName () {
        return localStorage.getItem('deviceUUID');
      },
      /* Logged in user */
        user () {
            return this.$store.state.user;
        },
        /* List of network first */
        networkFirst : {
          get() {
            return this.$store.state.networkFirst;
          },
          set(list) {
            this.$store.commit('updateNetworkFirst', list)
          }
        }
    },
    methods : {
       
        /** Delete cache   */      
        async deleteCache(urls = []) {
          const cache = await caches.open(this.cacheName);

          for (let index = 0; index < urls.length; index++) {
            const url = `${process.env.VUE_APP_APIBASE}${urls[index]}`;
            await cache.delete(url, {ignoreSearch : true});
        }
        },
        /* Add to network first */
        addNetworkFirst(name) {
          this.networkFirst = [...this.networkFirst, name];
        },
        /* Get if network first */
        isNetworkFirst(name) {
          return this.networkFirst.includes(name)
        },
        /* remove from  network first */
        removeFromNetworkFirst(name) {
          this.networkFirst = [...this.networkFirst.filter(n => n != name)];
        },
        /**
         *  Cache first 
         * return response from cache if has record if none return the network response then add/update cache 
         * 
         *  */
        async cacheReturn({url, networkFirst = false, method = "GET", params = {}, errorCallback = () => {}, callback =() => {}, diffrence = () => {}} = {}) {
            /* Prevent if no user */
            console.log(callback,url,  "user cache", _, networkFirst)
            // if(!this.user) return null;

            /* block request */
            const block = ['/login']

            console.log(url, 'blocking req', block.find(b => b == url))


            if(block.find(b => b == url)) return;

          const requestController = new AbortController();
          const signal            = requestController.signal;


          this.requestController = [...this.requestController, requestController]

          this.$store.commit('updateAbortRequest', [...this.abortRequests, {
            id     : Helper.makeString(5),
            page   : this.$route.name,
            request: requestController
          }])


            const theUrl = `${process.env.VUE_APP_APIBASE}${url}`;
            const token  = Helper.getCookie('quoteapptoken');
            

            let config = {
              method,
              headers : {}
            }

            if(token) {
              config.headers = {
                Authorization : `Bearer ${token}`,
                "Content-Type": "application/json",
              }
            }
            

            if(method != 'GET') {
              config.body = JSON.stringify(params)
            } else {
              config.signal = signal;
            }

            console.log(theUrl, 'cache config', config)

            try {
              const cache = await caches.open(this.cacheName);
              

              const fetchResponsePromise = fetch(theUrl, config).then(async (networkResponse) => {
                  if (networkResponse.ok) {
                    cache.put(url, networkResponse.clone());
                    
                    
                    /* Check if cache is updated if not return the updated data */
                    let cacheRes = await (await cache.match(url));
                    let netRes   = await networkResponse.clone();
                    
                    netRes   =  (await netRes);
                    cacheRes =  (await cacheRes);

                    if(cacheRes?.ok) {
                      cacheRes = await cacheRes.json();
                      netRes   = await netRes.json();
                      console.log('network cache', cacheRes, netRes);
                      if(!_.isEqual(cacheRes, netRes)) {
                        diffrence(_.difference(cacheRes, netRes))
                        callback(netRes)
                      }
                    }

                  }


                  if(!networkResponse.ok) {
                    errorCallback();
                  }

                  return networkResponse;
                }).catch(err => { return {status : false, error : err}});

           
              
              let res = (await cache.match(url)) ||  (await fetchResponsePromise);

              console.log(res, 'token', token)

              if(!token) {
                res = (await fetchResponsePromise);
              }

              const data = res.ok ? await res.json() : null;

              // console.log('resreresrser', res);

              
              return data;
            } catch (error) {
              console.log('error', error)

              throw(error)
            }
        }
    },
    
    
}