/**
 * Snippets models
 * Object property that allow to be save in indexdb
 * @namespace SnippetsModel
 */

export default class SnippetsModel {
    /**
     * Destructuring object and only return decleared properties
     */
    static schema (object) {
            const  {
            id               = null,
            s_content        = null,
            s_category       = null,
            s_notes          = null,
            s_subtitle       = null,
            s_subtitle_notes = null,
            s_page_start     = null,
            s_page_end       = null,
            s_date           = 1,
            s_date_type      = null,
            s_url            = null,
            visibility       = null,
            user_level       = null,
            published_at     = null,
            source_id        = null,
            created_by       = null,
            publish_status   = false,
            s_date_circa   = false,
            admin_lock       = false
            }                = object;



        return {
            id,
            s_content,
            s_category,
            s_notes,
            s_subtitle,
            s_subtitle_notes,
            s_page_start,
            s_page_end,
            s_date : s_date || '0000-01-01',
            s_date_type,
            visibility : visibility ? 1 : 0,
            user_level,
            published_at,
            source_id,
            s_url,
            s_date_circa,
            created_by,
            publish_status : publish_status ? 1 : 0,
            admin_lock
        }

    }

}