/**
 * Manipulating Top bar menu
 * @namespace TopBar
 */

export default class TopBar {

    /**
     * Changing top bar color
     * @param {Object} options isLight, color 
     */
    static setTopBarColor({isLight = true, color = null} = {}) {
        let isDarkMode = isLight;
        const defaultColor = isDarkMode ? '#262628' : '#fafafa';

        color = color ? color : defaultColor;

        console.log('change top bar color', color)


        let appleThemeColor = document.querySelector("meta[name=theme-color]");
        appleThemeColor.setAttribute("content", color);
    }
}