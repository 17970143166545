/* Plugins */
import axios from 'axios';

import { cacheMixins } from './cacheMixins';

/* Utils */
import StoryQuoteMixins from './quoteStoryMixins';

export const quotesMixins = { 
    mixins : [cacheMixins],
    methods: {
        /**
         * Get combined api calls 
         * @param {Object} params
         */
        async getQuotesCombined({params={}, callback = () => {}} = {}) {
            const url = `/api/quote/combined`
            const res = await axios.get(url, {params}).catch(err => console.log(err));

            if(res?.data) {
                callback(res.data);
            }
        },
        /* Total approval quote  */
        async getTotalApprovalQuote({callback = () => {}} = {}) {
            const url =`/api/quote/totalapproval`

            // const res = await axios.get(url).catch(() => null);

            // if(res && res.data) {
            //     callback(res.data);
            // }

            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback : (res) => {
                    if(res) {
                        callback(res);
                    }
                }
            })


            if(res) {
                callback(res);
            }
        },
        /* Remove quotes cache listing */
        async removeQuotesCache({includeUrls = [], includesOnly = false} = {}) {
            let urls = [...includeUrls,
                '/api/quote/published',
                '/api/story/published',
                '/api/quote/forapproval',
                '/api/quote',
                '/api/story'
            ]

            if(includesOnly) urls = [...includeUrls];

            await this.deleteCache(urls);

        },
        /* Get approval list quotes */
        async getApprovalList({params = {}, callback = () => {}} = {}) {
            const url = `/api/quote/forapproval?${new URLSearchParams(params)}`;
            // const res = await axios.get(url, {params}).catch(err => console.log(err));

            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback : res => {
                    if(res) {
                        callback(res);
                    }
                }
            })


            if(res) {
                callback(res);
            }

        },
        /** 
         * Seach quote 
         * @param {String} params search string base check api docs for all parameters docs#quote-management-POSTapi-quote-search
         * @param {Function} callback return function that contain the array of quotes
         *
         */
        async searchQuote({params, callback = () => {}} = {}) {
            const url = `/api/quote/search`
            const res = await axios.post(url, params).catch(err => console.log(err));
            
            if(res && res.data) {
                callback(res.data);
            }
        },
        /* Get all published quotes */
        async getAllPublishedQuotes({params, callback =() => {}} = {}) {
            const url = `/api/quote/published?${new URLSearchParams(params)}`
            // const res = await axios.get(url, {params}).catch(err => console.log(err))
            const networkFirst = this.isNetworkFirst('quoteList');

            const res = await this.cacheReturn({
                url, 
                networkFirst,
                method: "GET",
                callback : (res) => {
                    if(res) {
                        callback(res);
                    }
                }
            })

            if(res) {
                callback(res)
            }

             /* Remove from network first */
             if(networkFirst) {
                this.removeFromNetworkFirst('quoteList');
            }
        },
        /* Update quote access */
        async updateQuoteAccess({params, callback = () => {}} = {}) {
            const url = `/api/quote/admin/visibility/${params.id}`
            const res = await axios.patch(url, params).catch(err => console.log(err))

            if(res && res.data) {
                callback(res.data)
            }

            /* Add to network first */
            this.addNetworkFirst('quoteList');
        },
        /* Get quote  attachement */
        async getQuoteAttachement({params = {}, callback = () => {}} = {}) {
            const url = `/api/quote/file?${new URLSearchParams(params)}`
            // const res = await axios.get(url, {params}).catch(err => console.log(err))
            const res = await this.cacheReturn({
                url, 
                method: "GET",
                params,
                callback : (res) => {
                    if(res) {
                        callback(res)
                    }
                }
            })

            if(res) {
                callback(res)
            }

        },
        /* Add Quote Attachement */
        async addQuoteAttachement({params = {}, callback =() => {}} = {}) {
            const url = `/api/quote/file`
            const res = await axios.post(url, params).catch(err => console.log(err))
            
            /* Remove cache */
            this.removeQuotesCache({includeUrls : [url]})

            if(res && res.data) {
                callback(res.data)
            }
        },
        /* New quote */
        async createQuote({params = {}, callback = () => {}}= {}) {
            const url = `/api/quote`
            const res = await axios.post(url, params).catch(err => console.log(err))

            /* Remove cache */
            this.removeQuotesCache();

            if(res && res.data) {
                if(callback) {
                    callback(res.data)
                }
            }
        },
        /* Get Quotes */
        async getQuotes({params = {}, continueNetworkFirst = false, callback = () => {}} = {}) {
            const url = `/api/quote?${new URLSearchParams(params)}`
            // const res = await axios.get(url, {params}).catch(err => console.log(err))
            const networkFirst = this.isNetworkFirst('quoteList');

            const res = await this.cacheReturn({
                url, 
                networkFirst,
                method: "GET",
                callback : res => {
                    if(res) {
                        callback(res)
                    }
                }
            })

            if(res) {
                callback(res)
            }
            
            /* Remove from network first */
            if(networkFirst && !continueNetworkFirst) {
                this.removeFromNetworkFirst('quoteList');
            }
        },
        /* Add to favorite */
        async addFavorite({params = {}, callback = () => {}, errorCallback = () => {}} = {}) {
            const url = `/api/quote/favorite/${params.quote_id}`
            const res = await axios.post(url).catch(err => errorCallback(err))
            if(res && res.data) {
                callback(res.data)
            }

            /* Add to network first */
            this.addNetworkFirst('quoteList')
        },
        /* remove to favorite */
        async removeFavorite({params = {}, callback = () => {}, errorCallback = () => {}} = {}) {
            const url = `/api/quote/favorite/${params.quote_id}`
            const res = await axios.delete(url).catch(err => errorCallback(err))
            if(res && res.data) {
                callback(res.data)
            }

            /* Add to network first */
            this.addNetworkFirst('quoteList')
        },
        /* Favorite Quote list */
        async getFavorites({params = {}, callback = () => {}} = {}) {
            const url = `/api/quote/favorite/yourlist`
            const res = await axios.get(url, {params}).catch(err => console.log(err))

            if(res && res.data) {
               callback(res.data)
            }

            /* Add to network first */
            this.addNetworkFirst('quoteList')
        },
        /* Get quote */
        async getQuote({quoteID = null, callback = () => {}} = {}) {   
            const url          = `/api/quote/${quoteID}`
            const networkFirst = this.isNetworkFirst(`quote${quoteID}`);

            // const res = await axios.get(url).catch(err => console.log(err))
            const res = await this.cacheReturn({
                url, 
                networkFirst,
                method: "GET",
                callback : res => {
                    if(res) {
                        callback(res.data)
                    }
                },
                errorCallback : () => {
                    console.log('getquote error')
                    /* Remove caches */
                    this.removeQuotesCache({includeUrls : [url]})
    
                    /* Show message popout */
                    this.$bvModal.show('deletedQuote');
                }
            })

            if(res) {
                callback(res.data)
            }

            /* Add to network first */
            this.addNetworkFirst('quoteList');

            /* Remove from network first */
            if(networkFirst) {
                this.removeFromNetworkFirst(`quote${quoteID}`);
            }
        },
        /* Update quote */
        async  updateQuote({params = {}, callback = () => {}} = {}) {
            const url = `/api/quote/${params.id}`
            const res = await axios.patch(url, params).catch(err=> console.log(err))

            if(res && res.data) {
                StoryQuoteMixins.updateQuoteCollection(res.data.data)
                callback(res.data)
            }

            /* Remove cache */
            this.removeQuotesCache({includeUrls : [url]})

              /* Add to network first */
              this.addNetworkFirst('quoteList')
        },
        /* Publish quote */
        async publishQuote({params = {}, callback = () => {}} = {}) {
            const url = `/api/quote/publish/${params.id}`
            const res = await axios.patch(url).catch(err => console.log(err))

            /* Remove cache */
            this.removeQuotesCache({includeUrls : [url]})

            if(res && res.data) {
                callback(res.data)
            }

            /* Add to network first */
            this.addNetworkFirst('quoteList')
        },
        /* Unpublish Quote */
        async unPublishQuote({params = {}, callback = () => {}} = {}) {
            const url = `/api/quote/unpublish/${params.id}`
            const res = await axios.patch(url).catch(err => console.log(err));

            /* Remove cache */
            this.removeQuotesCache({includeUrls : [url]})
    
            if(res && res.data) {
                callback(res.data)
            }

            /* Add to network first */
            this.addNetworkFirst('quoteList')
            
        },
        /* Remove quote */
        async removeQuote({params = {}, callback = () => {}} = {}) {
            const url = `/api/quote/${params.id}`
            const res = await axios.delete(url).catch(err => console.log(err))

            /* Remove cache */
            this.removeQuotesCache({includeUrls : [url]})

            if(res && res.data) {
                callback(res.data)
            }

            /* Add to network first */
            this.addNetworkFirst('quoteList')
        },
        /* Add quote tag */
        async newQuoteTag({params = {}, callback = () => {}} = {}) {
            const url = `/api/quote/tag/${params.id}`
            const res = await axios.post(url, params).catch(err => console.log(err))

            if(res && res.data) {
                callback(res.data)
            }

            /* Add to network first */
            this.addNetworkFirst('quoteList')
        },
        /* Remove quote tag */
        async removeQuoteTag({params = {}, callback = () => {}} = {}) {
            const url = `/api/quote/tag/${params.id}`
            const res = await axios.delete(url, {params}).catch(err => console.log(err))

            if(res && res.data) {
                callback(res.data)
            }

            /* Add to network first */
            this.addNetworkFirst('quoteList')
        }
        
    } 
}