// plugins

const paragraphFormat = {
    mounted(el) {

        
        /* Get inner text */
        const contents = el.innerText
        
        /* Break perlines */
        const lines = contents.split('\n');
        
        /* Format lines into paragraph */
        let newFormat = [...lines].map(l => `<p>${l}</p>`).join('');
        
        /* Change divs into p tag */
        newFormat = newFormat.replace(/div>/gi, 'p>');
        
        el.innerHTML = newFormat;
        
        console.log(newFormat, 'paragraph format', el.innerHTML)

    }
}

export default paragraphFormat;