import { createStore } from 'vuex'

const newQuote = () => {
  return  {
    // quote              : '',
    author_id          : null,
    tags               : [],
    source_id          : null,
    user_level         : 1,
    visibility         : false,
    attachment         : null,
    // url                : null,
    // story              : '',
    // viewAsAdmin        : false,
    // page_start         : null,
    // page_end           : null,
    // quote_subtitle     : '',
    // quote_date         : null,
    // quote_date_type    : '',
    // story_date         : null,
    // story_date_type    : '',
    // story_subtitle     : '',
    // quote_subtitle_note: '',
    // story_subtitle_note: '',
    // poem_content       : '',
    // poem_notes         : '',
    // poem_subtitle      : '',
    // poem_subtitle_notes: '',
    // poem_page_start    : '',
    // poem_page_end      : '',
    // poem_date          : '',
    // poem_date_type     : '',
    // poem_url           : null,
    // song_content       : null,
    // song_notes         : null,
    // song_subtitle      : null,
    // song_subtitle_notes: null,
    // song_page_start    : null,
    // song_page_end      : null,
    // song_date          : null,
    // song_date_type     : null,
    // song_url           : null,
    s_content       : null,
    s_category      : null,
    s_notes         : null,
    s_subtitle      : null,
    s_subtitle_notes: null,
    s_page_start    : null,
    s_page_end      : null,
    s_date          : null,
    s_date_type     : null,
    s_url           : null,
  }
}

export default createStore({
  state: {
    abortRequest   : [],
    showApproval   : false,
    user           : null,
    newQuote       : newQuote(),
    newQuoteAddedId: null,
    routeFrom      : {},
    settings       : {
      viewMode   : 'light',
      viewAsAdmin: false
    },
    networkFirst: localStorage.getItem('networkFirst') ? JSON.parse(localStorage.getItem('networkFirst')) : [],
  },
  getters : {
    abortRequest(state) {
      return state.abortRequest;
    }
  },
  mutations: {
    /* Update newly added quote id */
    updateNewQuoteAddedId(state, payload) {
      state.newQuoteAddedId = payload;
    },
    /* Update abort request */
    updateAbortRequest(state, payload) {
      state.abortRequest = payload;
    },
    /* Update show approval state */
    updateShowApprovalState(state, payload) {
      state.showApproval = payload;
    },
    /* Update networkFirst */
    updateNetworkFirst(state, payload) {
      state.networkFirst = payload;
      localStorage.setItem('networkFirst', JSON.stringify(payload));
    },
    /* Update view as admin */
    updateViewAsAdmin(state, payload) {
      state.viewAsAdmin = payload
    },
    /* Update settings */
    updateSettings(state, payload) {
      state.settings = payload
    },
    /* Update route from  */
    updateRouteFrom(state, payload) {
      state.routeFrom = payload
    },
    /* Update user state */
    updateUser(state, payload) {
      state.user = payload
    },
    /* Update new quote state */
    updateNewQuote(state, newQuote) {
      state.newQuote = newQuote
    }
  },
  actions: {
    /* Reset quote states */
    reseteNewQuote(context) {
      const resetedNewQuote = Object.assign({}, newQuote())

      context.commit('updateNewQuote', resetedNewQuote)
    }
  },
  modules: {
  }
})
