/**
 * Snippet Resource
 * @namespace SnippetResource
 */

/* Utils */
import {getCollection, getCollectionsBy} from '@/manie-utils/idb/localbase';

/* Resources */
import SourceResource from './sourceResource';

export default class SnippetResource {
    /**
     * Return Snippet Object
     * @param {Number} id
     */
    static async getSnippet(snippet_id) {
        const snippet = await getCollection({name : 'snippets', filter : {id : snippet_id}});
        
        if(!snippet) return null;

        /* Files */
        let files = []
        await getCollectionsBy({collectionName : 'files', indexName : 'snippet_id', only : parseInt(snippet_id), limit : 9999, callback : data => files = [...files, ...data]})

        /* Authors */
        let authorsList = [];
        let authors     = []

        await getCollectionsBy({collectionName : 'snippet_authors', indexName : 'snippet_id', only : parseInt(snippet_id), limit : 9999, callback : data => authorsList = [...authorsList, ...data]})

        for (let index = 0; index < authorsList.length; index++) {
            const author = authorsList[index];
            authors = [...authors, await getCollection({name : 'authors', filter : {id : author.author_id}})];
        }

        /* Tags */
        let tagsList = [];
        let tags     = [];
        await getCollectionsBy({collectionName : 'snippet_tags', indexName : 'snippet_id', only : parseInt(snippet_id), limit : 9999, callback : data => tagsList = [...tagsList, ...data]})

        for (let index = 0; index < tagsList.length; index++) {
            const tag = tagsList[index];
            tags = [...tags, {...await getCollection({name : 'tags', filter : {id : tag.tag_id}}), ...{pivot : tag}}].filter(t => t.id);
        }

        tags.sort((a, b) => new Date(a.pivot.created_at) - new Date(b.pivot.created_at));

        /* Favorites */
        let favList = [];
        await getCollectionsBy({collectionName : 'snippet_fav', indexName : 'snippet_id', only : parseInt(snippet_id), limit : 9999, callback : data => favList = [...favList, ...data]})

        /* Remove undefined */
        authors = [...authors.filter(a => a)];
        tags    = [...tags.filter(t => t)];

        /* Source */
        const source = await SourceResource.getSource({source_id : snippet.source_id})

        /* Merged properties */
        const additionalProperties  =  {
            source,
            favorited_by : favList,
            files,
            authors,
            tags,
            s_date : snippet.s_date == '0000-01-01' ? null : snippet.s_date
        }


        return {...snippet, ...additionalProperties}
    }
}