/**
 * Story and quote Mixins
 * @namespace StoryQuoteMixins
 */

/* Helpers */
import {getCollections, overridecollection, addUpdateCollection} from '@/helpers/localbase';

/* Models */
import QuotesModel from '@/manie-utils/idb/models/quotesModel';

export default class StoryQuoteMixins {
    /**
     * Get quote and story
     */
    static async  getQuoteAndStories () {
        const quotes  = await getCollections({name : 'quotes'})
        const stories = await getCollections({name : 'stories'})

        return   [...quotes, ...stories];
    }
    /**
     * add and update quotes to localdatabase
     * @param {Array} quotes list of quotes
     */
    static async addUpdateQuoteCollections({quotes = []} = {}) {
        const quoteList = quotes.map(q => QuotesModel.schema(q))

        await overridecollection({name : 'quotes', data : quoteList});

    
    }
    /**
     * add and update stories to localdatabase
     * @param {Array} stories list of stories
     */
    static async addUpdateStoriesCollections({stories = []} = {}) {
        await overridecollection({name : 'stories', data : stories});
    }

    /**
     * Update quote collection
     * @param {Object} quote
     */

    static async updateQuoteCollection(quote) {
        await addUpdateCollection({name : 'quotes', data : quote})
    }
    
    /**
     * Update story collection
     * @param {Object} story
     */

    static async updateStoryCollection(story) {
        await addUpdateCollection({name : 'stories', data : story})
    }

}