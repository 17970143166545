/**
 * Admin mixins for approval
 * @namespace ApprovalMixins
 */

/*  */
import CombinedSnipetsApis from "@/manie-utils/idb/apis/combinedSnipetsListApis";



export const adminApprovalMixins = {
    methods : {
         /* Reject publish */
         rejectPublish({params = {}, callback = () => {}} = {}) {
            // data.visibility = false

            CombinedSnipetsApis.publishSnippet({snippet_id : params.id, publish : false, callback});

            // /* Remove visibility  */
            // const newParams = {
            //     visibility: 0,
            //     id        : params.id
            // }

            // if(params.story) {
            //     /* update story visibility */
            //     this.updateStoryAccess({params : newParams, callback});

            //     return
            // }

            // /* Update quote visibility */
            // this.updateQuote({params : newParams, callback});
        },
        /* Approve Publish */
        approvePublish({params = {}, callback = () => {}} = {}) {
            // /* Set publish */
            // data.publish_status = true
            

            CombinedSnipetsApis.publishSnippet({snippet_id : params.id, callback});

            // if(params.story) {
            //     /* Approve publish story */
            //     this.publishStory({params, callback});
                

            //     return
            // }
            // /* Approve publish quote */
            // this.publishQuote({params, callback});
        },
    }
}