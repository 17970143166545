/**
 * String parsing
 * @namespace StringParse
 */

export default class StringParse {
    /**
     * Remove special charactre from the string
     * @memberof StringParse
     * @param {String} string 
     */
    static removeSpecialCharacter(string = "") {
        return string.replace(/[^a-zA-Z0-9 ]/g, "")
    }

    /**
     * Check if  element has elipssis
     * @param {DOM} element 
     */
    static hasElipsis(element) {
        if(!element) return false;
        console.log([element], 'has ellisis 11', element.scrollHeight, element.clientHeight)
        return (element.scrollHeight > element.clientHeight);
    }
}