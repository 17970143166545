export const loaderMixins = {
    data() {
        return {
            isLoading : true
        }
    },
    methods : {
        /* Disable laoding */
        disableLoading ({callback = () => {}} = {}){
            setTimeout(() => {
                this.isLoading = false;

                callback()
            }, 100);
        }
    }
}