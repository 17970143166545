/**
 * Cron jobs 
 * Check jobs collection every 5 seconds
 * this is alternative for background sync because service worker background sync not supported in ios
 */ 

/* Jobs*/
import {apiCallsJob} from '@/jobs/apiCallsJob'
import {sessionJob} from '@/jobs/sessionJob'

export const cronJob = {
    mixins : [apiCallsJob, sessionJob],
    data() {
        return {
            processing: false,
            failed    : []
        }
    },
    computed : {
        isonline() {
            return this.$store.state.isonline;
        },
        /* Webhook */
        socketio() {
            return this.$store.state.socketio
        },
        /* Logged in user */
        user: {
            get() {
                return this.$store.state.user
            },
            set(user) {
                this.$store.commit('updateUser', user)
            }
        }
    },
    methods : {
        /**
         * Initialize Cron
         * Run everery 5 secs
         * Documentary : https://www.npmjs.com/package/vue-crontab
         */
        initCron() {
            /* Api calls job */
            this.$crontab.addJob({
                name    : 'cronjobmonitor',
                interval: {
                    seconds: '/2',
                },
                sync     : 1,
                condition: () => !this.processing ? true : false,
                job      : this.apiCalls
            });

           /* Session  job */
           this.$crontab.addJob({
                name    : 'sessionjobmonitor',
                interval: {
                    seconds: '/2',
                },
                sync     : 1,
                condition: () => this.user ? true : false,
                job      : this.checkActiveSession
            });
        },
       
    }
}