<template>
  <div 
    :class="[settings.viewMode, 'mainAppWrapper', 'dark:bg-gray-12']"
    id="app">

    <router-view v-slot="{ Component, route }">
      <transition name="fade">
        <component :is="Component" :key="route.path"/>
      </transition>
    </router-view>
    <!-- <router-view/> -->

    <footer-m v-show="showfooter" />
  </div>
</template>

<script>
/**
 * Declerations
 */
/* Components */
import footerm from '@/components/footer'

/* Helper */
import Helper from '@/helpers/helpers';

/* Mixins */
import {cronJob} from '@/mixins/crontab'
import {userMixins} from '@/mixins/userMixins'
import {settingsMixins} from '@/mixins/settingsMixins'

/* Apis */
import TagApis from '@/manie-utils/idb/apis/tagListApis';
import AuthorApis from '@/manie-utils/idb/apis/authorListApis';
import SourceListApi from '@/manie-utils/idb/apis/sourceListApis';
import CombinedSnipetsApis from '@/manie-utils/idb/apis/combinedSnipetsListApis'
import { onBeforeRouteLeave } from 'vue-router';

/* Utils */
import PullToRefresh from 'pulltorefreshjs'
import DOMparsing from '@/manie-utils/domParsing'
import _ from 'lodash'

export default {
  mixins    : [userMixins, cronJob, settingsMixins],
  setup() {
      onBeforeRouteLeave((to, from, next) => {
      console.log('to from next', to, from , next)

      next();
    })
  },
  data() {
    return {
      cleaning   : false,
      pullRefresh: null
    }
  },
  components: {
    'footer-m' : footerm
  },
  watch : {
    /* Watch route and sync deleted data */
    $route : {
      handler() {
         /* Sync dleted datas */
        this.syncDeletedDatas();

        /* Toggle pull refresh */
        this.togglePullRefresh()
      },
      deep : true
    },
    /* Watch user change */
    user : {
      handler(user){
        if(user) {
             /* Generate UUID */
            Helper.generateUUID({userId : user?.id ?? null});
        }
      },
      deep : true
    }
  },
  computed : {
    
    /***
     * Check if footer will show or not
     */
    showfooter( ){
      let show = true;

      switch (this.$route.meta.page) {
        case 'newquote':
          show = false
          break;

        case 'login':
          show = false
          break;
        
        case 'emailVerfied':
          show = false
          break;

        case 'settings':
          // show = false
          break;
        
        case 'logs':
          show = false
          break;
      
        default:
          break;

      }
      return show
    }
  },
  methods : {
    /**
     * Toggle on/off pull refresh
     */
    togglePullRefresh : _.throttle(function() {

      const elements = document.querySelectorAll('div')

      console.log(this.pullRefresh, 'DOMparsing.areAllElementsScrolledTop(elements)', DOMparsing.areAllElementsScrolledTop(elements))
      
      if(!DOMparsing.areAllElementsScrolledTop(elements)) {
        this.pullRefresh?.destroy()
        this.pullRefresh = null
      } else  if(!this.pullRefresh) {
        this.initPullRefresh()
        return;
      }


    }, 300),
    /**
     * Init Pull refresh
     */
    initPullRefresh() {
      this.pullRefresh = PullToRefresh.init({
        mainElement  : 'body',
        // distThreshold: 100,
        // distIgnore   : 200,
        onRefresh() {
          window.location.reload(true);
        }
      });
    },
    /**
     * Sync all deleted data
     * 
    */
    async syncDeletedDatas() {
      if(this.cleaning) return;
      this.cleaning = true;

      await TagApis.syncDeletedTags();
      await AuthorApis.syncDeletedAuthors();
      await SourceListApi.syncDeletedSources();
      await SourceListApi.syncDeletedContainers();
      await CombinedSnipetsApis.syncDeletedSnippets();

      this.cleaning = false;
    }
  },
  async beforeMount() {
   
     /* init Global Axios Config */
     Helper.axoisglobalconfig();

    /* Get user */
    await this.getUser()

    /* Sync dleted datas */
    this.syncDeletedDatas();
    
  },
  mounted() {
    /* Init pull refresh */
    // this.initPullRefresh();
    this.togglePullRefresh()
   
    /* Initialize cron job */
    // this.initCron()

    /* Get app settings */
    this.getSettings();
    /* Get theme color */
    // this.setTopBarColor();

    document.addEventListener('scroll', () => this.togglePullRefresh(), true);

  },

}
</script>

<style lang="scss">
/** Global styles will be flaced here */

// @import "@/scss/_colors.scss";
// @import "@/scss/_mixins.scss";
@import "@/scss/output.scss";
@import '@/scss/colors';
@import '@/scss/mixins';



body {
    overflow: hidden auto;
    font-family: 'Open Sans', sans-serif;
  
  
    &::-webkit-scrollbar {
      width : 0;
      height: 0;
    }
  
    &::-webkit-scrollbar-track {
      background-color: #21242a;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
      cursor: pointer;
    }
  
    * {
      font-family: 'Open Sans', sans-serif;
  
  
      &::-webkit-scrollbar {
        width : 0;
        height: 0;
      }
  
      &::-webkit-scrollbar-track {
        background-color: #21242a;
      }
  
      &::-webkit-scrollbar-thumb {
        background: #888;
      }
  
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
        cursor: pointer;
      }
    }
  
    .notifBadge {
        position        : absolute;
        width           : 20px;
        height          : 20px;
        border-radius   : 100%;
        background-color: $red;
        color           : white;
        top             : -10px;
        right           : -10px;
        font-size       : 11px;
        display         : flex;
        align-items     : center;
        justify-content : center;
    }
  
    mark {
      padding-inline  : 0;
      background-color: $red2;
    }
  
    .inlineToolWrapper {
      background: #fff;
      border    : 1px solid $lightgray2;
      padding   : 2px 5px;
      position  : relative;
      box-shadow: 1px 1px 5px  $lightgray3;
      z-index   : 200;
  
      &::after {
          width       : 0px;
          height      : 0px;
          border-style: solid;
          border-width: 0 8px 10px 8px;
          border-color: transparent transparent #fff transparent;
          transform   : rotate(0deg);
          content     : "";
          position    : absolute;
          top         : -10px;
          left        : 50%;
          transform   : translateX(-50%);
          filter      : drop-shadow(0px -10px 5px  $lightgray10);
          z-index     : -1;
  
      }
  
      button {
        border          : none;
        background-color: transparent;
  
        &.tool {
          &-bold {
            font-weight: bold;
          }
  
          &-italic {
            font-style: italic;
          }
  
          &-underline {
            text-decoration: underline;
          }
          
          &.active {
            color : $orange;
          }
        }
      }
    }
  
    .input-error {
      border-color : $red;
      
      &.important {
        border-color : $red !important;
      }
    }
  
    .text-left {
      text-align: left;
    }
  
    .text-red {
      color : $red;
  
      &.important {
        color : $red !important; 
      }
    }
  
    .cursor {
      &-auto {
        cursor: pointer;
      }
    }
  
    .no-bd {
      border      : none;
      border-width: 0;
  
      &.important {
        border      : none !important;
        border-width: 0 !important;
      }
    }
  
    // .justify {
    //   &-end {
    //     justify-content: flex-end;
    //   }
    // }
  
  
  
    .absolute {
      position : absolute;
    }
  
    .top-0 {
      top : 0;
    }
  
    .left-0 {
      left: 0;
    }
  
    // .break-all {
    //   word-break: break-all;
    // }
  
    // .z-10 {
    //   z-index: 10;
    // }
  
    .nowrap {
      white-space: nowrap;
    }
  
    // .flex-1 {
    //   flex : 1;
    // }
  
    // .invisible {
    //   visibility: hidden;
    // }
  
    // .px {
    //   @for $i from 0 through 200 {
    //     &-#{$i}px {
    //       padding-inline : #{$i}px;
    //     }
      
    //   }
    // }
  
    // .pl {
    //   @for $i from 0 through 200 {
    //     &-#{$i}px {
    //       padding-left : #{$i}px;
    //     }
      
    //   }
    // }
    
    // .pr {
    //   @for $i from 0 through 200 {
    //     &-#{$i}px {
    //       padding-right : #{$i}px;
    //     }
      
    //   }
    // }
  
    // .h  {
    //   @for $i from 1 through 200 {
    //     &-#{$i}px {
    //       height : #{$i}px;
    //     }
      
    //   }
    // }
    // .min-h  {
    //   @for $i from 1 through 200 {
    //     &-#{$i}px {
    //       min-height : #{$i}px;
    //     }
      
    //   }
  
    //   &.important {
    //     @for $i from 1 through 200 {
    //       &.min-h-#{$i}px {
    //         min-height : #{$i}px !important;
    //       }
        
    //     }
    //   }
    // }
  
    // .sticky {
    //   position : sticky;
  
    //   &.bottom {
    //     bottom: 0;
  
    //     @for $i from 1 through 200 {
    //       &-#{$i}px {
    //         bottom : #{$i}px;
    //       }
        
    //     }
    //   }
    // }
  
    .mx {
  
      &-calendar-content .cell {
        &.active {
          background-color: $orange;
        }
      }
                
      &-datepicker {
        margin-left: auto;
        display    : table;
        min-width  : initial;
        width      : 100%;
  
        &.present {
          width : 120px;
        }
      }
  
      &-input {
        text-align   : inherit;
        padding-inline: 0;
      }
  
      &-icon-calendar, &-icon-clear {
        display: none;
      }
    }
  
    a {
      &[disabled] {
        pointer-events: none !important;
  
        & * {
          opacity: 0.8;
        }
      }
    }
  
  
    .isLoading {
          @keyframes colors {
              from {
                  background-color: $lightgray;
              }
          
              to {
                  background-color: $lightgray2;
              }
          }
  
          background-color: $lightgray;
          animation       : colors 1s infinite;
          text-indent     : -1000px;
          border-color    : $lightgray;
  
          * {
            visibility: hidden;
          }
          
      }
  
      .isLoadingButton, .isLoadingButton:before, .isLoadingButton:after {
        border-radius      : 50%;
        width              : 2em;
        height             : 2em;
        animation-fill-mode: both;
        animation          : bblFadInOut 1.8s infinite ease-in-out;
  
        &.smallerLoad {
          animation: bblFadInOutSmall 1.8s infinite ease-in-out;
          width    : .5em;
          height   : .5em;
        }
      }
  
  
      .isLoadingButton {
          color          : inherit;
          font-size      : 7px;
          position       : relative;
          text-indent    : -9999em;
          transform      : translateZ(0);
          animation-delay: -0.16s;
          display        : flex;
          margin-inline  : auto;
          margin-top     : -25px;
          
  
          &::after, &::before {
              content : '';
              position: absolute;
              top     : 0;
              display : block;
          }
  
          &::before {
              left           : -3.5em;
              animation-delay: -0.32s;
          }
          &::after {
              left: 3.5em;
          }
          
          @keyframes bblFadInOut {
              0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
              40% { box-shadow: 0 2.5em 0 0 }
          }
  
          @keyframes bblFadInOutSmall {
              0%, 80%, 100% { box-shadow: 0 1em 0 -1.3em }
              40% { box-shadow: 0 1em 0 0 }
          }
                
      }
  
  
    .dropdown {
      .dropdown-menu {
        margin : 0;
        padding: 0;
  
        .dropdown-item {
          &:hover {
            color           : white;
            background-color: $orange;
          }
        }
      }
    }
  
  
    
    .custom-control {
      margin-bottom : 15px;
  
      .custom-control-input:checked ~ .custom-control-label::before {
        background-color: $orange;
        border-color    : $orange;
      }
  
      .custom-control-label {
        position    : relative;
        font-size   : 15px;
        padding-left: 10px;
        
        &::after, &::before {
          width : 18px;
          height: 18px;
        }
  
        &::after {
          top : 4px;
        }
      }
  
      &.custom-switch {
        .custom-control-label {
          &::before {
            width : 40px;
          }
        }
  
        .custom-control-input {
          &:checked {
           & ~ .custom-control-label {
              &::after {
                transform: translateX(20px);
              }
            }
          }
        }
      }
  
      &.custom-radio {
       .custom-control-input:checked ~ .custom-control-label {
          &::after {
            background-image: none;
          }
        }
      }
    }
  
    .header {
      background-color: #fff;
      position        : sticky;
      top             : 0;
      z-index         : 1000;
      padding-block   : 15px;
  
      // .container {
      //   padding-block : 15px;
      // }
  
      .headeraction {
        display    : flex;
        align-items: center;
        
  
        &.left {
          justify-content: flex-start;
        }
  
        &.right {
          justify-content: flex-end;
  
          a {
            margin-left: 15px;
          }
        }
  
        h1 {
          font-size    : 18px;
          margin-bottom: 0;
          margin-left  : 15px;
          font-weight  : 600;
        }
  
        a {
          color           : $black;
          background-color: $lightgray;
          border-radius   : 5px;
          display         : flex;
          align-items     : center;
          justify-content : center;
          height          : 40px;
          position        : relative;
  
          &:not(.backbtn) {
            width: 40px;
            flex : 0 0 40px;
          }
          
  
          &.active {
            background-color: lighten($orange, 35);
            color           : $orange;
          }
  
          &.addbtn {
            font-size: 25px;
          }
  
          &.delete {
            font-size   : 15px;
          }
  
          &.backbtn {
            width           : auto;
            padding-left    : 10px;
            padding-right   : 10px;
            color           : $orange;
            background-color: transparent;
  
            svg {
              // margin-right: 5px;
              font-size   : 12px;
            }
          }
        }
      }
  
      .newQuoteOpt {
        border : 1.5px solid $lightgray6;
        padding: 15px;
  
        .newQuoteOptItem {
          height         : 50px;
          display        : flex;
          align-items    : center;
          justify-content: center;
          cursor         : pointer;
          font-size      : 16px;
          font-weight    : 400;
  
          &:hover {
            color           : $orange;
            background-color: $lightgray7;
          }
  
          .b-icon, .bi {
            margin-right: 15px;
            font-size   : 22px;
          }
  
          svg {
            @extend .b-icon, .bi;
          }
        }
      }
  
    }
  
    .form-control, .mx-input {
      outline         : none ;
      border          : none  ;
      box-shadow      : none ;
      background-color: transparent;
  
      @extend .input-error;
  
      &:read-only {
        outline         : none ;
        border          : none  ;
        box-shadow      : none ;
        background-color: transparent;
      }
  
      &:focus {
        outline         : none ;
        border          : none ;
        box-shadow      : none;
        background-color: transparent;
      }
  
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color  : $lightgray5 !important;
      }
  
      &::-moz-placeholder { /* Firefox 19+ */
        color  : $lightgray5;
      }
  
      &:-ms-input-placeholder { /* IE 10+ */
        color  : $lightgray5;
      }
  
      &:-moz-placeholder { /* Firefox 18- */
        color  : $lightgray5;
      }
      
    }
  
    [placeholder]:empty::before { 
      content: attr(placeholder);
      color  : $lightgray5;
    } 
    
    [placeholder]:empty:focus::before { 
      content: ""; 
    }
  
    [contenteditable] {
        &.form-control {
          height: auto;
        }
    }
  
    [contenteditable=false] {
        opacity: 0.4;
    }
  
  
    .btn {
      &:focus {
        box-shadow: none;
        outline   : none;
      }
  
      &.btn-primary {
        padding         : 5px 15px;
        font-weight     : 300;
        background-color: $orange;
        font-size       : 12px;
        border-color    : $orange;
        min-height      : 35px;
      }
  
      &.default-btn {
        padding         : 5px 15px;
        font-weight     : 300;
        background-color: transparent;
        font-size       : 10px;
        border          : none;
        color           : $black;
        border-color    : $orange;
      }
  
      &.btn-primary-white {
        color           : $orange;
        background-color: #fff;
        border-color    : $orange;
      }
  
      &.btn-secondary-white  {
        color           : $black;
        background-color: white;
        border-color    : white;
      }
  
      &.btn-normal-primary {
        color           : $orange;
        background-color: transparent;
        border          : none;
      }
  
      &-outline-primary {
        border-color: $orange;
        color       : $orange;
  
        &:hover {
          background-color: $orange;
          color           : white;
          border-color    : white;
        }
      }
    }
  
    .modal {
  
  
  
      .modal-header {
        border-bottom : none;
  
        .close {
          font-size  : 30px;
          font-weight: 300;
          color      : $lightgray5;
          font-weight: 400;
        }
  
      }
      .modal-content {
        border       : none;
        text-align   : center;
        box-shadow   : 0 3px 6px $lightgray15;
        border-radius: 10px;
        
        .modalIcon {
          font-size      : 68px;
          display        : flex;
          justify-content: center;
          margin-bottom  : 20px;
  
          .quoteicon {
            margin-inline : auto;
  
            svg {
              width : 69px;
              height: 69px;
            }
          }
        }
        
        h1 {
          font-size  : 25px;
          font-weight: 600;
        }
  
        p {
          font-size  : 15px;
          font-weight: 400;
        }
      }
  
      .modalContent {
        margin-bottom: 60px;
      }
  
      .modalFooter{
        margin-block: 30px;
  
        .btn {
          width        : 130px;
          margin-inline: 10px;
          font-size    : 15px;
          height       : 40px;
        }
      }
    }
  
    .modal-backdrop {
      background-color: $lightgray3;
      opacity         : 0.75;
      box-shadow      : 0 3px 6px #00000029;
    }
  
    .searchFilter {
      padding-inline: 15px;
      padding-top   : 10px;
  
      .form-control {
        height : 35px;
      }
  
      .input-group {
        background-color: $lightgray11;
        border-radius   : 10px;
  
  
        &-text {
          background-color: transparent;
          border-color    : transparent;
  
          svg {
            width : 13px;
            height: 13px;
            color : $lightgray5 !important;
          }
        }
      }
    }
  
    .popList {
          position: absolute;
          z-index : 300;
          right   : 0;
          width   : 100%;
          left    : 0;
          // top     : calc(1.5em + 0.75rem + 2px);
          max-height: calc(100vh - (70 + 90) * 1px);
          overflow  : clip;
          

  
        
  
          &.bottom  {
              top  : calc(1.5em + 0.75rem + 2px);
          }
  
          &.top {
              bottom : calc(1.5em + 0.75rem + 2px);
          }
  
          .list-group {
              border : none;

  
              @include boxShadow;
  
              .list-group-item {
                  border    : none;
                  cursor    : pointer;
                  text-align: left;
  
                  a {
                    color :$black;
  
                    .bi {
                      color       : $orange;
                      margin-right: 5px;
                    }
                  }
  
                  &:hover {
                      background-color: $orange;
                      color           : white;
  
                      a, .bi {
                        color : inherit;
                      }
                  }
              }
          }
         
      }
  
      .quoteitem {
              background-color: #fff;
              border-radius   : 20px;
              margin-bottom   : 15px;
              display         : flex;
              overflow        : hidden;
              position        : relative;
              min-height      : 200px;
  
              &.activequote {
                  width: 130vw;
                  flex: 0 0 100vw;
                  left: calc(-30vw - 30px);
  
                  @include screen-up(md) {
                      left: calc(-30vw - 75px);
                  }
  
              }
  
                .partialResult {
                    position   : relative;

                    &:not(.topPosition):not(.bottomPosition) {
                      text-indent: 0 !important;
                    }
  
                    &::after, &::before {
                      content : '';
                      position: absolute;
                      width   : 100%;
                      height  : 40px;
                      left    : 0;
                      
                    }
  
                    // &::after {
                    //   top             : 0;
                    //   background-image: linear-gradient(180deg, #fff, #ffffff34);
                    // }
  
                    // &::before {
                    //   bottom          : 0;
                    //   background-image: linear-gradient(0deg, #fff, #ffffff34);
                    // }
  
                    &.topPosition {
                      &:after {
                        display: none;
                      }
                    }
  
                    &.bottomPosition {
                      &::before {
                        display: none;
                      }
                    }
                  }
  
  
              .quoteicon {
                  &.book {
                      svg {
                          color: $blue2 !important;
                      }
                  }
              }
  
  
  
              .quotecontent {
                  padding       : 15px;
                  width         : 100%;
                  font-size     : 18px;
                  display       : flex;
                  flex-direction: column;
  
                  p {
                    // font-family: 'Cormorant Garamond', serif;
                  }
              }
  
              .quotemainactions {
                  display: flex;
                  align-items: center;
  
                  @include screen-up(xl) {
                      display: flex;
                  }
  
                  a {
                      width          : 70px;
                      display        : flex;
                      align-items    : center;
                      justify-content: center;
                      height         : 100%;
  
                      .quoteicon {
                          svg {
                              width: 18px;
                              height: 18px;
                          }
                      }
  
                      &.editbtn {
                          background-color: $blue3;
                          color: $blue2;
                      }
  
                      &.deletebtn {
                          background-color: $red2;
                          color: $red;
                      }
                  }
              }
  
              .quoteicon {
                  &.quote {
                      color: $orange;
                  }
  
                  // svg {
                  //     width: 15px;
                  //     height: 15px;
                  // }
              }
  
              .quoteBodyWrap {
                  margin-bottom: 15px;
                  flex         : 1;
  
                
                  p {
                    text-indent  : 20px;
                    margin-bottom: 0;
                    white-space  : normal;
                  }
  
                  .seeMoreBtn {
                      display: none;
                      color  : $orange !important;
                  }
  
                  .showLessBtn {
                      display      : none;
                      color        : $orange !important;
                  }
  
                  .isEllipsis  {
  
                      .originContent {
                        display: none;
                      }
  
  
                      &~.seeMoreBtn {
                        display: block;
  
                      
                      }
  
                      &.seeMore {
                          &~.showLessBtn {
                              display: block;
                          }
                          &~.seeMoreBtn {
                              display: none;
                          }
  
                          .partialResult {
                            display: none;
                          }
  
                          .originContent {
                            display: block;
                          }
                          
                      }
  
                  }
                  
                  .isEllipsis:not(.seeMore)+.seeMoreBtn {
                      display: block;
                  }
  
                  
              }
  
              .qoutebody {
                  font-size  : 1.071rem;
                  width      : 100%;
                  min-height : 3.15rem;
                  white-space: pre-line;
                  cursor     : pointer;
  
                  &:not(.seeMore) {
                      display           : -webkit-box;
                      -webkit-line-clamp: 4;
                      -webkit-box-orient: vertical;
                      overflow          : hidden;
                  }
              }
  
              .quoteheader {
                  margin-bottom: 10px;
  
                  .quoteicon {
                      display: block;
                  }
  
                  .quoteaction {
                      display        : flex;
                      justify-content: flex-end;
  
                      a {
                          margin-left: 10px;
                          color      : darken($lightgray, 20);
  
                          &.active {
                              svg {
                                  color: $orange !important;
                              }
                          }
                      }
  
                  }
              }
  
              .quotefooter {
                margin-top: auto;
  
                  .quoteauthor {
                      display: flex;
  
                      .authorpic {
                          margin-right: 10px;
                          padding-top : 2px;
                          flex        : 0 0 20px;
                          width       : 20px;
                          height      : 20px;
  
                          svg {
                              width: 20px;
                              height: 20px;
                              color: $black;
                          }
  
                          img {
                              width        : 20px;
                              height       : 20px;
                              border-radius: 100%;
                              object-fit   : cover;
                          }
                      }
  
                  }
  
                  .authorinfo {
                      .authorname {
                          font-size  : 15px;
                          font-weight: 600;
                          cursor     : pointer;
                      }
  
                      .sourceNoteBtn {
                            color   : $lightgray5;
                            position: relative;
                            width   : 17px;
                            flex    : 0 0 17px;
                            display : block;
  
                            svg {
                              width : 17px;
                              height: 17px;
                            }
  
                            &.active {
                              color : $orange;
                            }
  
                            &:has(svg) {
                              margin-left: 5px;
                              top        : 2px;
                              width      : inherit;
                            }
                      }
  
                      .quotemeta,
                      .quotetags {
                          padding-top: 0;
                          color      : $lightgray8;
                          font-size  : 14px;
                          
  
                          a {
  
                            color   : inherit;
                            display : inline-block;
                            position: relative;
  
                            &.active {
                              color : $orange;
                            }
  
                            &:has(svg) {
                              margin-left: 5px;
                              top        : 2px;
                            }
                          }
                      }
  
                      .quotetags {
                          margin-top: 5px;
                          display: flex;
                          flex-wrap: wrap;
  
                          span {
                              background-color: $lightgray7;
                              margin-right    : 5px;
                              margin-bottom   : 5px;
                              padding         : 3px 10px;
                              font-size       : 12px;
                              font-weight     : 600;
                              color           : $lightgray9;
                              border-radius   : 4px;
                              display         : block;
                              cursor          : pointer;
                          }
                      }
                  }
  
                  .notesWrap {
                      color    : $lightgray8;
                      font-size: 14px;
                  }
  
                  .quoteaction {
                      color          : darken($color: $lightgray, $amount: 20);
                      display        : flex;
                      // height         : 100%;
                      justify-content: flex-end;
                      // min-height     : 110px;
  
                      .quoteicon {
                          justify-content: flex-end;
                          margin-bottom  : 5px;
                      }
  
                     .quoteactionitem {
                          display    : flex;
                          align-items: flex-end;
                          color      : $lightgray5;
                          margin-left: 10px;
                          cursor     : pointer;
  
                          &.active {
                              color : $orange !important;
                          }
                     }
  
                      svg {
                          color : inherit;
                          width : 17px;
                          height: 17px;
                      }
  
                      .link {
                        svg {
                          width   : 20px;
                          height  : 20px;
                          position: relative;
                          top     : 2px;
                        }
                      }
                  }
              }
          }
  
    .dark {
  
      & ~ .mx-datepicker-main {
        background-color: $black1;
        border-color    : $black1;
  
        .mx-calendar-content .cell {
          &:hover {
            background-color: $black2;
          }
        }
      }
  
      & ~ .inlineToolWrapper {
          background-color: $black2;
          border-color    : $black2;
          box-shadow      : 1px 1px 5px $black2;
  
          &::after {
            border-color : transparent transparent $black2 transparent;
          }
  
          button {
            color : white;
          }
      }
  
      
  
      .dateRangeSeparator {
        color : white;
      }
  
      .dropdown {
          &-toggle {
              color : white !important;
          }
  
          &-item {
            background-color: $black1;
            color           : white;
  
            &:hover {
              background-color: $black2;
            }
          }
      }
  
  
      .isLoading {
          @keyframes DarkColors {
              from {
                  background-color: $black2;
              }
          
              to {
                  background-color: $black1;
              }
          }
  
          background-color: $lightgray;
          animation       : DarkColors 1s infinite;
          border-color    : $black1;
      }
  
      .searchFilter {
        .form-control {
          color : white;
        }
      }
  
  
      & ~ div {
  
        .modal-backdrop {
          background-color: $black2;
        }
        
        .modal-content {
          background-color: $black1; 
  
          h1, p {
            color : white;
          }
  
          .modalIcon {
            .quoteicon {
              svg {
                color  : $black13 !important;
              }
            }
          }
        }
      }
  
  
      .btn {
        &.btn-light, &.btn-secondary-white {
          background-color: $black1;
          color           : white;
          border-color    : $black1;
        }
  
        &.default-btn {
          color : white;
        }
  
        &.btn-logout {
          border-color: $black10;
        }
      }
  
  
      .header {
        background-color: $black1;
  
        a, h1, small {
          color : $lightgray5;
        }
  
        a {
          background-color: $black3;
  
          &.active {
            background-color: $black6;
          }
        }
  
        .newQuoteOpt {
          border-color : $black1; 
  
          &Item {
            color : white;
  
            &:hover {
              background-color: $black7;
            }
          }
        }
      }
  
  
      .homebody, 
      .homeview, 
      .quoteeditview, 
      .authorswrapper, 
      .authorprofilewarpper,
      .authoreditprofilewarpper,
      .sourceWrapper,
      .sourceviewwrapper,
      .editsourcewrapper,
      .tagswrapper,
      .tagviewwrapper,
      .edittagwrapper,
      .librarywrapper,
      .quoteLibraryWrapp,
      .settingsmenu,
      .userprofileview,
      .userListWrap,
      .adminUserView,
      .userprofilebody,
      .compiWrap,
      .editSourceCompilationWrap
      {
        background-color: $black2;
      }
  
      .authorswrapper, 
      .authorprofilewarpper,
      .authoreditprofilewarpper,
      .sourceWrapper,
      .sourceviewwrapper,
      .editsourcewrapper,
      .tagswrapper,
      .tagviewwrapper,
      .edittagwrapper,
      .librarywrapper,
      .settingsmenu,
      .userprofileview,
      .userListWrap,
      .adminUserView,
      .userprofilebody,
      .editSourceCompilationWrap
      {
        .header {
          background-color: $black2;
  
          .right, .left {
            .headeractionitem:not(.backbtn) {
              background-color: $black3;
              position        : relative;
  
              &.active {
                background-color: $black6;
              }
            }
          }
        }
  
        h1, h2, h4, .form-control, legend {
          color : white;
        }
  
        .form-group {
          .form-group-inner {
            .form-control {
              color : white;
            }
          }
        }
  
        .custom-control {
          &-label {
            color : white;
  
            &:before {
              background-color: $black10;
              border-color    : $black10;
            }
          }
        }
  
        .authorlistinner, .sourceListInner, .taglistinner, .userlistinner {
           background-color: $black1;
  
           .authoritem, .sourceItem, .tagitem, .useritem {
            border-color: $black14;
           }
           
           .authorname, 
           .compilation, 
           .tagname, 
           .username,
           .userLevel {
              color : $lightgray3;
            }
  
        }
      }
  
      .authoreditprofilewarpper,
      .editsourcewrapper {
          .authorpic, .sourcepic {
            border-color: transparent;
  
            .editbtn {
              background-color: $black1;
              color           : $lightgray5;
            }
          }
      }
  
      .quoteeditview, .sourceeditbody {
        .form-control {
          color : white !important;
        }
        
  
        h1 {
          color : white;
        }
  
        .quotemeta .table tr  {
          td {
            color : $lightgray3;
  
            .form-control {
              color : $lightgray3;
            }
  
            &:first-child {
              color : $black12;
            }
  
            .dropdown {
                &-toggle {
                    color : white;
                }
  
                &-item {
                  background-color: $black1;
                  color           : white;
  
                  &:hover {
                    background-color: $black2;
                  }
                }
            }
          }
        }
        
        .quotetags {
          span {
            background-color: $black4;
            color           : $lightgray5;
          }
        }
  
      
        .quotecontent {
          background-color: $black1;
  
          .quotefooter {
            .authorinfo {
              .authorname {
                color : white;
              }
      
              .quotetags {
                span {
                  background-color: $black4;
                }
              }
            }
  
          }
        }
  
      }
  
      .newquote, 
      .authoreditprofilewarpper, 
      .editsourcewrapper,
      .edittagwrapper,
      .librarywrapper {
        background-color: $black2;
  
        .form-control, .dropdown-toggle {
          color       : white;
          border-color: $black10;
        }
  
        label[for=attachFile] {
          border-color: $black10;
          color       : $black12;
        }
  
        .custom-control-label::before {
          background-color: $black10;
          border-color    : $black10;
        }
  
        legend, .mx-input {
          color : white;
        }
  
        .newsources,
        .trending {
          .sourceItem {
            background-color: $black1;
            color           : $lightgray3;
          }
        }
  
        .newquoteheader {
          .newquoteheaderaction {
            &item {
              background-color: $black10;
              color           : $lightgray3;
            }
          }
        }
  
        .newQuoteFooterWrap {
          .newquotefooter {
            background-color: transparent;
  
            h3 {
              color : white;
            }
          }
        }
  
        .taglistheader, .selectedtags {
          background-color: $black2;
        }
  
        .unselectedtags {
          .tag {
            color : white;
          }
        }
  
        .selectedtags, .populartags {
          .tag, li {
            background-color: $black1;
            color           : $lightgray5;
          }
        }
  
        .sourcelist {
          .sourceitem {
            .active {
              background-color: $black11;
            }
  
            .pagesWrap {
              color : white;
            
            }
  
            .sourcename {
              color : white;
            }
  
            .sourceCT {
              color : white;
            }
          }
        }
  
        .authorlist {
          .authoritem  {
            .authorname {
              color : white;
            }
  
            &.active {
              background-color: $black11;
            }
  
            .defaultImage {
              color : white;
            }
          }
        }
      }
      
  
      .quoteitem {
        background-color: $black1;
  
         .partialResult {
                  
            // &::after {
            //   background-image: linear-gradient(180deg, $black1, #26262838);
            // }
  
            // &::before {
            //   background-image: linear-gradient(0deg, $black1, #26262838);
            // }
          }
  
        .quotemainactions {
          .editbtn, .deletebtn {
            background-color: $black2;
            width           : initial;
            padding-left    : 20px;
  
  
            .quoteicon {
              display        : flex;
              flex           : 0 0 40px;
              align-items    : center;
              justify-content: center;
              width          : 40px;
              height         : 40px;
              border-radius  : 100%;
              color          : white;
            }
          }
          
          .editbtn {
            .quoteicon {
              background-color: $blue2;
            }
          }
  
          .deletebtn {
            padding-right : 10px;
  
            .quoteicon {
              background-color: $orange1;
            }
          }
        }
  
        .quotecontent {
          color : white;
        }
  
        .quotefooter {
  
          .quotetags span {
              background-color: $black4;
              cursor          : pointer;
          }
        }
       
  
      }
  
      .searchFilter {
        .input-group {
          background-color: $black9;
        }
      }
  
      .filterPopWrap {
        background-color: $black1;
  
        legend {
          color : $black8;
        }
  
        .custom-control-label {
          color : white;
  
          &::before {
            background-color: $black4;
            border-color    : $black4;
          }
        }
      }
      
      .footerbar {
        background-color: $black1;
        border-top-color: $black1;
  
        .list-group {
          background-color: $black1;
          border-color    : $black1;
  
          &-item {
            // background-color: #fff;
            color : white;
  
            &.active, &:hover {
              background-color: $black7;
            }
          }
        }
  
        .footeraction {
  
          // &.public {
          //   .menuitem {
          //     &.active {
          //       a, .dropdown {
          //         background-color: $black15;
          //         color           : $blue;
          //       }
          //     }
          //   }
          // }
  
          .verticalDivider {
            border-color: $black4;
          }
  
          .menuitem {
            &.active {
              a, .dropdown {
                background-color: $black6;
                color           : $orange1;
              }
  
            }
            
            // &.publicfbtn.active {
            //     a, .dropdown {
            //     background-color: $black15;
            //     color           : $blue;
            //   }
  
            // }
            &.dpmenu {
              border-color : $black4;
            }
          }
        }
      }
    }
  
  }

</style>
