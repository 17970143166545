<template>
    <div class="modal" :class="[{'show' : show}, {'hide' : !show}]">
        <button @click="() => closeBackdrop()" class="fixed w-full h-full z-10 left-0 top-0 bg-gray-5 dark:bg-gray-11 opacity-75"></button>

        <div class="px-5 relative z-20 w-full">
            <div :class="[modalContentClass]" class="modal-content dark:!bg-gray-12">
                <div class="flex items-center w-full">
                    <button v-if="!hideCloseButton" @click="() => $emit('close')" class="text-gray ml-auto">
                        <quote-icon class="!w-5 !h-5 text-black dark:text-white" icon="x-mark" />
                    </button>
                </div>

                <div :class="[modalBodyClass]" class="modal-body flex flex-col items-center dark:text-white">
                    <div v-if="icon" class="modal-icon mb-5">
                        <quote-icon class="!w-24 !h-24 block text-gray" :icon="icon" />
                    </div>

                    <h1 class="text-2xl font-medium mb-2">{{title}}</h1>
                    <p class="text-sm">{{description}}</p>


                    <slot></slot>
                </div>

                <div
                    v-if="!hideFooter" 
                    :class="['w-full grid gap-4 mt-10', {'grid-cols-2' : hideCancel && hideContinue}, {'grid-cols-1' : !hideCancel || !hideContinue}]">
                    <button v-if="!hideCancel" @click="() => $emit('close')" class="!text-sm !px-3 !py-1 btn btn-primary-outline">{{cancelLabel ?? 'Cancel'}}</button>
                    <button v-if="!hideContinue" @click="() => $emit('continue')" class="!text-sm !px-3 !py-1 btn btn-primary !mt-0 !min-h-full">{{continueLabel ?? 'Continue'}}</button>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    name : 'Modal',
    props : {
        hideCancel : {
            type : Boolean,
            default : () => false
        },
        hideContinue : {
            type : Boolean,
            default : () => false
        },
        hideFooter : {
            type : Boolean,
            default : () => false
        },
        hideCloseButton : {
            type : Boolean,
            default : () => false
        },
        icon : {
            type   : String,
            default: () => null
        },
        title : {
            type : String,
            default : () => null
        },
        description : {
            type : String,
            default : () => null
        },
        cancelLabel : {
            type :  String,
            default :() => null
        },
        continueLabel : {
            type : String,
            default : () => null
        },
        show : {
            type   : Boolean,
            default: () => false
        },
        noBackdropClose : {
            type : Boolean,
            default : () => false
        },
        modalContentClass : {
            type : String,
            default : () => ''
        },
        modalBodyClass : {
            type : String,
            default : () => ''
        }
    },
    methods : {
        /**
         * Close backdrop
         */
        closeBackdrop() {
            if(this.noBackdropClose) return
            this.$emit('close')
        }
    }
}
</script>